import { Configuration } from '../configurator/configuration';
import { Functions } from '../helpers/functions';
import { DimensionsHorizontal } from './dimensionsHorizontal';
import { DimensionsVertical } from './dimensionsVertical';

export class SizeHandle {
	objectName = 'SizeHandle';
	static COLOR = '#999999';

	// Sizehandle total zijn de rasters + overhang die toegepast is.
	static TYPE_TOTAL = 'TOTAL';
	// Sizehandle rasters zijn de sizehandles van de raster (tekenen we per raster).
	static TYPE_RASTER = 'RASTER';
	// Sizehandle object is de sizehandle die we tekenen voor een geselecteerd item.
	static TYPE_OBJECT = 'OBJECT';

	startPosition = 0;
	id = -1;
	_space = 50;

	_visible = true;
	horizontal = new DimensionsHorizontal();
	vertical = new DimensionsVertical();
	order = 0;
	resizable = true;
	drawGroupIdHorizontal = null;
	drawGroupIdVertical = null;
	get visible() {
		return this._visible;
	}
	set visible(value) {
		this._visible = value;
		this.redraw();
	}
	redraw() {
		if (typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	registerEvents(events) {
		this.horizontal._onChange = events.onChangeHorizontal;
		this.vertical._onChange = events.onChangeVertical;
		this.horizontal._onChanged = events.onChangedHorizontal;
		this.vertical._onChanged = events.onChangedVertical;
		this.horizontal._movePossible = events.movePossible;
		this.vertical._movePossible = events.movePossible;
	}
	set(spansX, spansY) {
		this.clear();
		spansX.forEach((span) => {
			this.horizontal.push(span.value);
		});
		spansY.forEach((span) => {
			this.vertical.push(span.value);
		});
	}
	clear() {
		this.horizontal.clear();
		this.vertical.clear();
	}
	setByArray(dimensionsHorizontal, dimensionsVertical) {
		this.clear();
		dimensionsHorizontal.forEach((dimension) => {
			this.horizontal.push(dimension);
		});
		dimensionsVertical.forEach((dimension) => {
			this.vertical.push(dimension);
		});
	}
	// Aanroepen vanuit objecten met nieuwe versleep dimensions.
	// Update dan automatisch de drawobjecten in de correcte dimension.
	updateDrawObjects(dimensionsHorizontal, dimensionsVertical) {
		const groupObjectHorizontal = Configuration.CURRENT.canvas.drawObjects.findByDrawId(this.drawGroupIdHorizontal);
		const groupObjectVertical = Configuration.CURRENT.canvas.drawObjects.findByDrawId(this.drawGroupIdVertical);

		if (groupObjectHorizontal !== null && typeof groupObjectHorizontal !== 'undefined' && dimensionsHorizontal.length > 0) {
			this.horizontal.updateDrawObjects(groupObjectHorizontal, dimensionsHorizontal);
		}
		if (groupObjectVertical !== null && typeof groupObjectVertical !== 'undefined' && dimensionsVertical.length > 0) {
			this.vertical.updateDrawObjects(groupObjectVertical, dimensionsVertical);
		}
	}
	setReferences(parameters) {
		this._redraw = parameters.redraw;
	}
	removeReferences() {
		this._redraw = null;
	}
	get space() {
		if (this.visible === true) {
			return this._space;
		}
		return 0;
	}
	set space(value) {
		this._space = value;
	}

	constructor(id = Functions.uuidv4(), order = -1, resizable = false) {
		this.id = id;
		this.order = order;
		this.resizable = resizable;
	}
	addDrawObjects(params = {}) {
		if (this.visible === true) {
			params.resizable = this.resizable;
			params.startPosition = this.startPosition;
			params.sizeHandleType = this.id;
			let horizontalGroup = this.horizontal.addDrawObjects(params);
			let verticalGroup = this.vertical.addDrawObjects(params);

			// Opslaan van group id zodat we bij drag de groep makkelijk kunnen aanpassen.
			this.drawGroupIdHorizontal = horizontalGroup.id;
			this.drawGroupIdVertical = verticalGroup.id;
		}
	}
}
