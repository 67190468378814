import { Data } from '../data';
import { TransportDeliveryMessage } from './transportDeliveryMessage';

export class TransportDelivery extends Data {
	objectName = 'TransportDelivery';
	url = 'TransportDeliveries';

	Id = -1;
	Found=true;
	State = '';
	CollectionDate = null;
	DeliveryDate = null;
	DeliveryTimeStart = null;
	DeliveryTimeEnd = null;
	
	Ftl = false;
	FtlWeight = 0;
	FtlAmount= 0;
	TransportPriceFtl = null;
	Ldm = false;
	LdmAmount = 0;
	LdmWeight = 0;
	TransportPriceLdm = null;
	Packet=false;
	PacketLength=0;
	PacketWidth=0;
	PacketHeight=0;
	PacketWeight=0;
	PacketAmount=0;
	TransportPricePacket  = null;
	PickupPoint = '';
	Description = '';
	ProjectNumber = '';
	Messages = [];

	Address = {};

	reloadList = [
		() => {
			this.store.transportDeliveries.getTransportDeliveryPricesToDo(true, () => {
				this.nextReloadItem();
			});
		},
		() => {
			this.store.transportDeliveries.getTransportDeliveryPricesSubscribed(true, () => {
				this.nextReloadItem();
			});
		},
		() => {
			this.store.transportDeliveries.getTransportDeliveryPricesSubscribed(true, () => {
				this.nextReloadItem();
			});
		},
		() => {
			this.store.transportDeliveries.getTransportDeliveryPricesDeclined(true, () => {
				this.nextReloadItem();
			});
		},
		() => {
			this.store.transportDeliveries.getTransportDeliveryPricesCancelled(true, () => {
				this.nextReloadItem();
			});
		},
		() => {
			this.store.transportDeliveries.getTransportDeliveryPricesAssigned(true, () => {
				this.nextReloadItem();
			});
		},
		() => {
			this.store.transportDeliveries.getTransportDeliveryPricesDone(true, () => {
				this.nextReloadItem();
			});
		},
	];
	reloadItem = -1;
	fetch(json) {
		super.fetch(json);

		this.DeliveryTimeEnd = this.convertTime(this.DeliveryTimeEnd)
		this.DeliveryTimeStart = this.convertTime(this.DeliveryTimeStart)

		this.convertMessages();
	}

	convertTime(time) {
		// trim the seconds part (01:02:03 -> 01:02)
		const parts = time.toString().split(':')

		if (parts.length < 2)
		{
			return time
		}

		return parts[0] + ':' + parts[1]
	}

	convertMessages() {
		if (typeof this.Messages !== 'undefined' && this.Messages != null) {
			for (const messageId in this.Messages) {
				this.Messages[messageId] = new TransportDeliveryMessage(this.store, this.Messages[messageId], this);
				this.Messages[messageId].convertMessages();
			}
		}
	}

	getMessages() {
		super.list({ transportDeliveryId: this.Id }, true, 'message', '/Messages', (result) => {
			this.Messages = result;
			this.convertMessages();
		});
	}
	nextReloadItem() {
		this.reloadItem++;

		if (this.reloadItem <= this.reloadList.length - 1) {
			this.reloadList[this.reloadItem]();
		}
	}
	reloadReceinvingPriceLists() {
		this.reloadItem = -1;
		this.nextReloadItem();
	}

	subscribe(thenCallBack, catchCallBack) {
		var data = {
			id: this.Id,
			transportPriceFtl: this.TransportPriceFtl,
			transportPriceLdm: this.TransportPriceLdm,
			transportPricePacket: this.TransportPricePacket,
		};

		this.save(
			data,
			'/Subscribe',
			() => {
				this.reloadReceinvingPriceLists();
				if (thenCallBack != null) {
					thenCallBack();
				}
			},
			(exception) => {
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
			},
		);
	}

	decline(thenCallBack, catchCallBack) {
		var data = {
			id: this.Id,
			transportPrice: this.TransportPrice,
		};

		this.save(
			data,
			'/Decline',
			() => {
				this.reloadReceinvingPriceLists();
				if (thenCallBack != null) {
					thenCallBack();
				}
			},
			(exception) => {
				console.log(exception);
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
			},
		);
	}

	finish(thenCallBack, catchCallBack) {
		var data = {
			id: this.Id,
		};

		this.save(
			data,
			'/Finish',
			() => {
				this.reloadReceinvingPriceLists();
				if (thenCallBack != null) {
					thenCallBack();
				}
			},
			(exception) => {
				console.log(exception);
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
			},
		);
	}
	assignAccept(thenCallBack, catchCallBack) {
		var data = {
			id: this.Id,
		};

		this.save(
			data,
			'/AssignAccept',
			() => {
				this.reloadReceinvingPriceLists();
				if (thenCallBack != null) {
					thenCallBack();
				}
			},
			(exception) => {
				console.log(exception);
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
			},
		);
	}
	cancelConfirm(thenCallBack, catchCallBack) {
		var data = {
			id: this.Id,
		};

		this.save(
			data,
			'/CancelConfirm',
			() => {
				this.reloadReceinvingPriceLists();
				if (thenCallBack != null) {
					thenCallBack();
				}
			},
			(exception) => {
				console.log(exception);
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
			},
		);
	}
}
