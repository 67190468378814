import { Canvas3D } from '../draw3d/Canvas3D';
import { GussetPlateHorizontal3D } from '../draw3d/gussetplate/GussetPlateHorizontal3D';
import { GussetPlateVertical3D } from '../draw3d/gussetplate/GussetPlateVertical3D';
import { Columns } from './columns';
import { Configuration } from './configuration';
import { Profiles } from './profiles';

export class Plates {
	objectName = 'Plates';
	objectName3d = 'Plates3D';
	gussetPlates = [];

	addDrawObjects3d(canvas3d, etage, raster, posY, etageIndex) {
		let columns = Configuration.CURRENT.columns;
		let multipleFloors = Configuration.CURRENT.etages.length > 1;
		posY += etage.getHeight();
		columns.columns.forEach((column, index) => {
			let columnPosition = column.getPosition3D(column);
			let mainBeamHeight = Canvas3D.CURRENT.getBeamData(column.mainBeamId, 'height');
			let gussetPlate = Configuration.CURRENT.profiles.mainBeams.find((mainbeam) => mainbeam.oid === column.mainBeamId).gussetPlate;
			let gussetPlateDepth = Canvas3D.CURRENT.getModelDataByOid(gussetPlate.oid, 'depth');

			let paramsObject = {
				info: column,
				multipleFloors: multipleFloors,
				mainBeamHeight: mainBeamHeight,
				gussetPlate: gussetPlate,
				ralColor: Configuration.CURRENT.colors.columns.ralColor,
			};

			let rasterIndex = Configuration.CURRENT.raster.getRasterByCoordinate(raster.getSizeX(column.x - 1), Configuration.CURRENT.raster.getSizeY(column.y - 1));
			let offset = 0;
			if (!multipleFloors) {
				if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
					offset = rasterIndex.y * gussetPlateDepth;
				} else {
					offset = rasterIndex.x * gussetPlateDepth;
				}
			} else {
				offset = gussetPlateDepth;
			}

			if (column.isActive(etageIndex) && multipleFloors) {
				if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
					if (column.rowPositions.includes(Columns.POSITION_LEFT)) {
						canvas3d.addDrawObject(new GussetPlateVertical3D(columnPosition.x + columns.column._h_col, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
					} else if (column.rowPositions.includes(Columns.POSITION_RIGHT)) {
						canvas3d.addDrawObject(new GussetPlateVertical3D(columnPosition.x, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
					} else {
						canvas3d.addDrawObject(new GussetPlateVertical3D(columnPosition.x, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
						canvas3d.addDrawObject(new GussetPlateVertical3D(columnPosition.x + columns.column._h_col, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
					}
				} else {
					if (column.rowPositions.includes(Columns.POSITION_BOTTOM)) {
						canvas3d.addDrawObject(new GussetPlateHorizontal3D(columnPosition.x, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
					} else if (column.rowPositions.includes(Columns.POSITION_TOP)) {
						canvas3d.addDrawObject(new GussetPlateHorizontal3D(columnPosition.x, posY, columnPosition.z + columns.column._h_col, paramsObject), Canvas3D.TYPE_PLATE);
					} else {
						canvas3d.addDrawObject(new GussetPlateHorizontal3D(columnPosition.x, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
						canvas3d.addDrawObject(new GussetPlateHorizontal3D(columnPosition.x, posY, columnPosition.z + columns.column._h_col, paramsObject), Canvas3D.TYPE_PLATE);
					}
				}
			} else if (column.isActive(etageIndex) && !multipleFloors) {
				if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
					if (column.rowPositions.includes(Columns.POSITION_LEFT)) {
						canvas3d.addDrawObject(new GussetPlateVertical3D(columnPosition.x - gussetPlateDepth + offset, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
					} else if (column.rowPositions.includes(Columns.POSITION_RIGHT)) {
						canvas3d.addDrawObject(new GussetPlateVertical3D(columnPosition.x + columns.column._h_col + offset, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
					} else {
						canvas3d.addDrawObject(new GussetPlateVertical3D(columnPosition.x + columns.column._h_col / 2 + offset - gussetPlateDepth, posY, columnPosition.z, paramsObject), Canvas3D.TYPE_PLATE);
					}
				} else {
					if (column.rowPositions.includes(Columns.POSITION_BOTTOM)) {
						canvas3d.addDrawObject(new GussetPlateHorizontal3D(columnPosition.x, posY, columnPosition.z + columns.column._h_col + gussetPlateDepth, paramsObject), Canvas3D.TYPE_PLATE);
					} else if (column.rowPositions.includes(Columns.POSITION_TOP)) {
						canvas3d.addDrawObject(new GussetPlateHorizontal3D(columnPosition.x, posY, columnPosition.z - gussetPlateDepth, paramsObject), Canvas3D.TYPE_PLATE);
					} else {
						canvas3d.addDrawObject(new GussetPlateHorizontal3D(columnPosition.x, posY, columnPosition.z + columns.column._h_col / 2 + offset - gussetPlateDepth, paramsObject), Canvas3D.TYPE_PLATE);
					}
				}
			}
		});
	}
}
