import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { Configuration } from './configuration';
import { Columns } from './columns';
import { Bracing } from './bracing';
import { BraceColumns } from './braceColumns';

import { Errors } from './errors';
import { Store } from '../data/store';
export class Bracings {
	objectName = 'Bracings';
	objectName3d = 'Bracings3D';
	bracings = [];

	amountData = { Bracings: [] };
	static COLORS = { bracing: '#999', possible: '#6ba3ff', added: '#10821a', mouseMove: '#00286d', addedMouseMove: '#0f6d01' };

	mouseMoveActive = null;
	mousePriority = 10;
	static drawOffset = { x: 3, y: 3 };
	mouseAreaOffset = { x: 10, y: 10 };
	amountHorizontal = 0;
	amountVertical = 0;
	foundErrors = new Errors();

	// Type bracing (Zie request crossbracings).
	material = null;

	get hasErrors() {
		return this.foundErrors.length > 0;
	}
	getErrors() {
		return this.foundErrors;
	}
	constructor(onChange) {
		this._onChange = onChange;
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this.bracings.forEach((bracing) => {
			if (typeof bracing.setReferences === 'function') {
				bracing.setReferences(params);
			}
		});
	}
	removeReferences() {
		this._onChange = null;

		this.bracings.forEach((bracing) => {
			if (typeof bracing.removeReferences === 'function') {
				bracing.removeReferences();
			}
		});
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}
	onMouseDown(evt, drawObject) {
		return { stopPropagation: true };
	}
	onMouseMove(evt, drawObject) {
		if (this.findIndex(drawObject.objectParams.start, drawObject.objectParams.end) > -1) {
			drawObject.lineColor = Bracings.COLORS.addedMouseMove;
		} else {
			drawObject.lineColor = Bracings.COLORS.mouseMove;
		}

		this.mouseMoveActive = new Bracing(drawObject.objectParams.start, drawObject.objectParams.end);

		return { stopPropagation: true };
	}
	onMouseUp(evt, drawObject) {
		return { stopPropagation: true };
	}
	onMouseDrag(evt, drawObject) {
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		if (this.findIndex(drawObject.objectParams.start, drawObject.objectParams.end) > -1) {
			drawObject.lineColor = Bracings.COLORS.added;
		} else {
			drawObject.lineColor = Bracings.COLORS.possible;
		}

		this.mouseMoveActive = null;
		return { stopPropagation: true };
	}
	onClick(evt, object) {
		// geen check op aanwezigheid schoorkolom omdat hij hem ook al niet tekent. Mogelijk wel hier of in push check inbouwen
		this.togglePush(object.objectParams.start, object.objectParams.end);
		this.onChange();
		return { stopPropagation: true };
	}

	onChangeMainBeamLength(raster, delta, evt, drawObject) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject) {
		if (raster.x > -1) {
			if (raster.x === drawObject.objectParams.start.x && raster.x === drawObject.objectParams.end.x) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			} else if (raster.x === drawObject.objectParams.start.x) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
			} else if (raster.x === drawObject.objectParams.end.x) {
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			}
		} else if (raster.y > -1) {
			if (raster.y === drawObject.objectParams.start.y && raster.y === drawObject.objectParams.end.y) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			} else if (raster.y === drawObject.objectParams.start.y) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
			} else if (raster.y === drawObject.objectParams.end.y) {
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			}
		}
	}
	contains(column, direction) {
		// direction: x, y or both
		// voorkomt bij of start of end
		let find = false;
		if (typeof direction === 'undefined' || direction === null) {
			direction = 'both';
		}

		this.bracings
			.filter((b) => b.bracingActive === true)
			.forEach((bracing, index) => {
				if (bracing.contains(column) === true) {
					if (direction === 'both' || (direction === 'x' && bracing.startColumn.x === bracing.endColumn.x) || (direction === 'y' && bracing.startColumn.y === bracing.endColumn.y)) {
						find = true;
					}
				}
			});
		return find;
	}
	findIndex(startColumn, endColumn) {
		let findIndex = -1;

		this.bracings
			.filter((b) => b.bracingActive === true)
			.forEach((bracing, index) => {
				if (bracing.equals(startColumn, endColumn) === true) {
					findIndex = index;
				}
			});
		return findIndex;
	}
	togglePush(startColumn, endColumn) {
		const index = this.findIndex(startColumn, endColumn);

		if (index === -1) {
			this.push(startColumn, endColumn);
		} else {
			this.bracings.splice(index, 1);
		}
	}
	push(startColumn, endColumn) {
		const bracing = this.bracings.push(new Bracing(startColumn, endColumn));
		this.bracings[bracing - 1].setReferences({
			onChange: this.onChange.bind(this),
		});
	}
	addDrawObjects(canvas, params) {
		this.bracings.forEach((bracing, index) => {
			bracing.addDrawObjects(canvas, params);
		});
	}
	addDrawObjects3d(canvas3d, etage, raster, posY, etageIndex) {
		// Alleen tekenen wanneer materiaal van de bracing er is.
		// Voorkomt fouten in 3D tekening.
		if (this.material.crossBracingId !== null && typeof this.material.crossBracingId !== 'undefined') {
			this.bracings.forEach((bracing, index) => {
				bracing.addDrawObjects3d(canvas3d, etage, raster, posY, etageIndex, this.material.crossBracingId);
			});
		}
	}
	create3DAssets(canvas3D) {
		// Alleen assets inladen voor bracing als het materiaal is meegestuurd vanuit ERP en hier correct is opgehaald.
		// Voorkomt fouten in 3D tekening.
		if (this.material.crossBracingId !== null && typeof this.material.crossBracingId !== 'undefined') {
			canvas3D.addAsset(new ModelAsset3D('bracingstrip', this.material.crossBracingId));
		}
	}
	length() {
		return this.bracings.length;
	}
	amount() {
		return this.bracings.filter((b) => b.bracingActive === true).length;
	}
	resetAmountData() {
		this.amountData = { Bracings: [] };
	}
	pushToAmountData(object) {
		this.amountData.Bracings.push(object);
	}
	getAmount(amountObject) {
		this.resetAmountData();

		let kwadrantPosition = Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].getRasterWidthAndLength();

		// halfX en halfY zijn nodig om in 4 kwadranten te kunnen indelen
		let halfX = kwadrantPosition.startX + kwadrantPosition.width / 2;
		let halfY = kwadrantPosition.startY + kwadrantPosition.depth / 2;

		let length = 0;

		this.amountHorizontal = 0;
		this.amountVertical = 0;
		this.amountLeftTop = 0;
		this.amountRightTop = 0;
		this.amountLeftBottom = 0;
		this.amountRightBottom = 0;
		this.bracings
			.filter((b) => b.bracingActive === true)
			.forEach((bracing) => {
				let startColumn = Configuration.CURRENT.columns.getPosition(bracing.startColumn.x, bracing.startColumn.y);
				let endColumn = Configuration.CURRENT.columns.getPosition(bracing.endColumn.x, bracing.endColumn.y);

				// horizontaal tellen van bracings
				if (startColumn.y === endColumn.y) {
					if (startColumn.x < halfX && startColumn.y <= halfY) {
						// Linksboven
						this.amountLeftTop++;
					}
					if (endColumn.x > halfX && startColumn.y <= halfY) {
						// Rechtsboven
						this.amountRightTop++;
					}
					if (startColumn.x < halfX && startColumn.y >= halfY) {
						// Linksonder
						this.amountLeftBottom++;
					}
					if (endColumn.x > halfX && startColumn.y >= halfY) {
						// Rechtsonder
						this.amountRightBottom++;
					}
				} else if (startColumn.x === endColumn.x) {
					// verticaal tellen van bracings
					if (startColumn.y < halfY && startColumn.x <= halfX) {
						// Linksboven
						this.amountLeftTop++;
					}
					if (startColumn.y < halfY && startColumn.x >= halfX) {
						// Rechtsboven
						this.amountRightTop++;
					}
					if (endColumn.y > halfY && startColumn.x <= halfX) {
						// Linksonder
						this.amountLeftBottom++;
					}
					if (endColumn.y > halfY && startColumn.x >= halfX) {
						// Rechtsonder
						this.amountRightBottom++;
					}
				}

				if (bracing.startColumn.x === bracing.endColumn.x) {
					length = endColumn.y - startColumn.y;

					this.amountVertical += 1;
				} else {
					length = endColumn.x - startColumn.x;
					this.amountHorizontal += 1;
				}
				let id = -1;

				if (typeof Configuration.CURRENT.columns.column.xBracing !== 'undefined' && Configuration.CURRENT.columns.column.xBracing !== null) {
					id = Configuration.CURRENT.columns.column.xBracing;
				}
				this.pushToAmountData({
					name: 'bracing',
					length: length,
					color: Configuration.CURRENT.colors.bracing.ralColor,
					finish: Configuration.CURRENT.colors.bracing.finish,
					amountHorizontal: this.amountHorizontal,
					amountVertical: this.amountVertical,
					id: id,
				});
			});

		return this.amountData;
	}
	count() {
		return this.bracings.filter((b) => b.bracingActive).length;
	}
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let resultErrors = [];
		this.bracings.forEach((bracing) => {
			let bracingResult = bracing.collisions(boundaries, self);

			if (bracingResult.result === true) {
				collisionsDetect = true;
				bracingResult.errors.forEach((error) => {
					resultErrors.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: resultErrors, objectName: this.objectName };
	}
	calculateAmount(params) {
		this.calculate(params);
	}
	calculate(params) {
		this.bracings.forEach((bracing) => {
			bracing.calculate(params);
		});
	}
	possiblePosition(column, columnRight, activeIndex, activeEtage, braceColumnEnumRight, braceColumnEnumLeft) {
		return (
			columnRight !== null &&
			columnRight.positionOffset.y === column.positionOffset.y &&
			columnRight.isActive(activeIndex) &&
			activeEtage.braceColumns.findIndex(column, braceColumnEnumRight) === -1 &&
			activeEtage.braceColumns.findIndex(columnRight, braceColumnEnumLeft) === -1 &&
			activeEtage.portalBracings.findIndex(column, columnRight) === -1
		); // alleen kolommen die op dezelfde lijn liggen voor bracing
		// kolom rechts aanwezig en actief en geen schoorkolom rechts van de kolom of links van de rechterkolom
	}
	addPossiblePositions(canvas, params) {
		const columns = Configuration.CURRENT.columns.columns;
		const activeEtage = params.actieveEtage;

		columns.forEach((column, index) => {
			if (column.isActive(params.actieveEtageIndex) === true) {
				const columnRight = Configuration.CURRENT.columns.find(column.x + 1, column.y);
				if (this.possiblePosition(column, columnRight, params.actieveEtageIndex, activeEtage, BraceColumns.RIGHT, BraceColumns.LEFT) === true) {
					// alleen kolommen die op dezelfde lijn liggen voor bracing
					// kolom rechts aanwezig en actief en geen schoorkolom rechts van de kolom of links van de rechterkolom
					const horizontaalStart = column.getPosition();
					const horizontaalEnd = columnRight.getPosition();
					if (horizontaalEnd.startX.value - horizontaalStart.endX.value >= 2000) {
						// kleiner dan twee meter niet

						horizontaalStart.endX.offsetScaled += Bracings.drawOffset.x;
						horizontaalStart.startY.offsetScaled += Columns.COLUMN_SIZE / 2;
						horizontaalEnd.startX.offsetScaled -= Bracings.drawOffset.x;
						horizontaalEnd.startY.offsetScaled += Columns.COLUMN_SIZE / 2;
						let color = Bracings.COLORS.possible;

						if (this.findIndex(column, columnRight) > -1) {
							color = Bracings.COLORS.added;
							if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, columnRight) === true) {
								color = Bracings.COLORS.addedMouseMove;
							}
						} else if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, columnRight) === true) {
							color = Bracings.COLORS.mouseMove;
						}

						canvas.addDrawObject(
							new Line(horizontaalStart.endX, horizontaalStart.startY, horizontaalEnd.startX, horizontaalEnd.startY, new DrawValue(6, 0, true), [5, 1], color, null, null, true, this, {
								start: column,
								end: columnRight,
							}),
						);
					}
				}
				const columnBottom = Configuration.CURRENT.columns.find(column.x, column.y + 1);
				if (this.possiblePosition(column, columnBottom, params.actieveEtageIndex, activeEtage, BraceColumns.BOTTOM, BraceColumns.TOP) === true) {
					// alleen kolommen die op dezelfde lijn liggen voor bracing
					// kolom onder aanwezig en actief en geen schoorkolom onder huidige kolom of boven onderliggende kolom

					const verticaalStart = column.getPosition();
					const verticaalEnd = columnBottom.getPosition();

					if (verticaalEnd.startY.value - verticaalStart.endY.value >= 2000) {
						// kleiner dan twee meter niet
						verticaalStart.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
						verticaalStart.endY.offsetScaled += Bracings.drawOffset.y;
						verticaalEnd.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
						verticaalEnd.startY.offsetScaled -= Bracings.drawOffset.y;
						let color = Bracings.COLORS.possible;
						if (this.findIndex(column, columnBottom) > -1) {
							color = Bracings.COLORS.added;
							if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, columnBottom) === true) {
								color = Bracings.COLORS.addedMouseMove;
							}
						} else if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, columnBottom) === true) {
							color = Bracings.COLORS.mouseMove;
						}

						canvas.addDrawObject(
							new Line(verticaalStart.startX, verticaalStart.endY, verticaalEnd.startX, verticaalEnd.startY, new DrawValue(6, 0, true), [5, 1], color, null, null, true, this, {
								start: column,
								end: columnBottom,
							}),
						);
					}
				}
			}
		});
	}
	onRasterChanged(params) {
		let bracingFromStore = Store.CURRENT.crossBracings.getItemBasedOnWidth(Configuration.CURRENT.columns.column.width);
		this.material = bracingFromStore;
	}
}
