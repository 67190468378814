<template>
	<div :class="{ dialog: true, visible: visible, out: closing }">
		<div class="backdrop" @click="close()" />
		<dialog open="open">
			<header v-if="title">
				{{ title }}
				<i class="material-icons topIcon" @click="close">close</i>
			</header>
			<header v-if="!!this.$slots.header">
				<slot name="header"></slot>
				<i v-if="closeVisible === true" class="material-icons topIcon" @click="close">close</i>
			</header>
			<slot />
			<footer v-if="!!this.$slots.footer">
				<slot name="footer"></slot>
			</footer>
		</dialog>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		closeVisible: {
			type: Boolean,
			default: true,
		},
		canClose: {
			type: Boolean,
			default: false,
		},
	},
	data: () => {
		return {
			closing: false,
		};
	},
	watch: {
		visible: {
			handler() {
				if (!this.visible) {
					this.closing = true;
					setTimeout(() => {
						this.closing = false;
					}, 750);
				}
			},
		},
	},
	mounted() {
		document.body.appendChild(this.$el);
	},
	destroyed() {
		if (this.$el.parentNode !== null) {
			this.$el.parentNode.removeChild(this.$el);
		}
	},
	methods: {
		close() {
			if (this.canClose === false) {
				return;
			}
			this.$emit('cancel');
			this.$emit('update:visible', false);
			this.closing = true;
			setTimeout(() => {
				this.closing = false;
			}, 750);
		},
	},
};
</script>

<style lang="less" scoped>
.dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 1000;
	height: 100vh;
	opacity: 0;
	display: none;
	.backdrop {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
	}
	.topIcon {
		position: fixed;
		right: 10px;
		top: 0px;
	}
	dialog {
		position: fixed;
		left: 50%;
		top: 50%;
		width: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		z-index: 300;
		opacity: 0;
		min-height: 100px;
		padding: 0;
		border: 0;
		font-family: 'Lato';
		font-size: 14px;
		line-height: 20px;
		color: #555;
		overflow-y: hidden;
		max-height: 100vh;

		@media (max-width: 1400px) {
			width: 80vw;
		}

		// Small devices (landscape phones, less than 768px)
		@media (max-width: 767px) {
			width: 90vw;
		}
		@media (max-width: 575px) {
			width: 97vw;
		}
		header {
			position: relative;
			float: left;
			width: 100%;
			min-height: 50px;
			line-height: 50px;
			font-size: 18px;
			padding-left: 25px;
			font-weight: 500;
			@media (max-width: 425px) {
				font-size: 16px;
			}
			i {
				position: absolute;
				right: 10px;
				line-height: 50px;
				cursor: pointer;
			}
		}
		.content {
			position: relative;
			float: left;
			padding: 15px 10px;
			@media (max-width: 1280px) {
				padding: 5px;
			}
			width: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			max-height: 65vh;
			line-height: 25px;
			.container {
				> .row {
					> div {
						margin-bottom: 3px;
					}
				}
			}
		}
		.help {
			position: relative;
			float: left;
			padding: 20px 20px 75px 20px;
			.image {
				position: absolute;
				right: 0;
				bottom: 0;
				max-width: 250px;
				img {
					max-width: 100%;
				}
			}
		}
		.updateDim {
			position: relative;
			float: left;
			width: 100%;
			padding: 20px 20px 75px 20px;
			.ant-slider-handle {
				border: solid 2px #fcc43e !important;
			}
			.ant-slider-track {
				background-color: #fcc43e !important;
			}
			.ant-slider {
				.ant-slider-track {
					background-color: #fcc43e !important;
				}
			}
			.ant-input-number {
				width: 100%;
				&:hover {
					border: 1px solid #fcc43e;
				}
			}
		}
		footer {
			position: relative;
			float: right;
			width: 100%;
			background: #eee;
			margin-top: 10px;
			div {
				position: relative;
				float: right;
				margin: 20px 20px 20px 0;
				@media (max-width: 1200px) {
					margin: 10px 20px 10px 0;
				}
				padding: 8px 15px;
				cursor: pointer;

				&:not(.primary) {
					background: #d9dadd;
					color: #999;
				}
			}
		}
	}
	&.visible {
		display: block;
		-webkit-animation: inBG 0.5s forwards;
		-moz-animation: inBG 0.5s forwards;
		-o-animation: inBG 0.5s forwards;
		animation: inBG 0.5s forwards;
		dialog {
			-webkit-animation: dialog 0.5s forwards 0.25s;
			-moz-animation: dialog 0.5s forwards 0.25s;
			-o-animation: dialog 0.5s forwards 0.25s;
			animation: dialog 0.5s forwards 0.25s;
		}
	}
	&.out {
		display: block;
		opacity: 1;
		-webkit-animation: outBG 0.5s forwards 0.25s;
		-moz-animation: outBG 0.5s forwards 0.25s;
		-o-animation: outBG 0.5s forwards 0.25s;
		animation: outBG 0.5s forwards 0.25s;
		pointer-events: none;
		dialog {
			-webkit-animation: outDialog 0.5s forwards;
			-moz-animation: outDialog 0.5s forwards;
			-o-animation: outDialog 0.5s forwards;
			animation: outDialog 0.5s forwards;
		}
	}
}
@keyframes outBG {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes inBG {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes dialog {
	0% {
		opacity: 0;
		top: 40%;
	}
	100% {
		opacity: 1;
		top: 50%;
	}
}
@keyframes outDialog {
	0% {
		opacity: 1;
		top: 50%;
	}
	100% {
		opacity: 0;
		top: 40%;
	}
}
</style>
