import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { Store } from '../data/store';
import { Configuration } from './configuration';

import { Serializer } from './serializer';

import { PalletGate } from './palletGate';
import { RemoveRaster } from './removeRaster';
import { Mathematic } from '../helpers/mathematic';

export class PalletGates {
	objectName = 'PalletGates';
	objectName3d = 'PalletGates3D';

	// Bij palletgates heeft gebruiker de keuze voor breedte.
	// De breedte is het bord wat bruikerbaar is.
	// Aan beide kanten komen dan nog paaltjes en voetplaatjes.
	// De lengte per kant en per type palletgate staan hieronder.
	// We gebruiken dit om de drawWidth van de palletgate te bepalen.
	static additionalWidthList = [
		{ name: 'Tilt gate', amount: 175 },
		{ name: 'Self closing gate', amount: 120 },
		{ name: 'Chain gate', amount: 150 },
	];

	static COLORS = { palletGate: 'gray', possiblePosition: 'blue', possiblePositionSelected: 'green', error: 'red', lineDash: [5, 3], selected: 'blue' };
	showConfigurator = false;
	palletGateConfiguratorSettings = new PalletGate();
	palletGates = [];
	mousePriority = 10;
	mouseAreaOffset = { x: 10, y: 10 };
	powderCoated = false;
	galvanised = false;
	possiblePossitionsVisible = '';
	constructor(onChange, checkCollisions) {
		this._onChange = onChange;
		this._checkCollisions = checkCollisions;
	}

	retrieveTiltGates() {
		return this.get().filter((pg) => pg.type === 'Tilt gate');
	}

	retrievePalletGates() {
		return this.get().filter((pg) => pg.type !== 'Tilt gate');
	}

	get() {
		return this.palletGates.filter((pg) => pg.active === true);
	}
	findByHandrailPosition(coordinates) {
		return this.palletGates.filter((pg) => pg.active === true && pg.onHandrailPosition(coordinates) === true);
	}
	onClick(evt, object) {
		Configuration.CURRENT.editModus.stopEditingObject();
		this.palletGateConfiguratorSettings.newPalletGate = false;
		this.push(this.palletGateConfiguratorSettings, object.objectParams);

		Configuration.CURRENT.editModus.stopEditingObject();
		Configuration.CURRENT.select({ id: this.palletGates[this.palletGates.length - 1].id });
		this.onChange();
		Configuration.CURRENT.showToolsMenu = true;
		return { stopPropagation: true };
	}
	onMouseMove(evt, drawObject) {
		drawObject.lineColor = PalletGates.COLORS.possiblePositionSelected;
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		drawObject.lineColor = PalletGates.COLORS.possiblePosition;
		return { stopPropagation: true };
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	select(parameters) {
		this.palletGates.forEach((palletGate) => {
			palletGate.select(parameters);
		});
	}
	push(palletGateConfiguration, params) {
		const referenceParams = { onChange: this.onChange.bind(this), edit: this.edit.bind(this), remove: this.remove.bind(this), checkCollisions: this._checkCollisions };
		this.palletGates.push(new PalletGate(palletGateConfiguration, params));
		this.palletGates[this.palletGates.length - 1].setReferences(referenceParams);
	}

	setReferences(params) {
		this._onChange = params.onChange;
		this._checkCollisions = params.checkCollisions;
		this._redraw = params.redraw;
		params.edit = this.edit.bind(this);
		params.remove = this.remove.bind(this);
		this.palletGates.forEach((palletGate) => {
			palletGate.setReferences(params);
		});
	}

	removeReferences() {
		this._onChange = null;
		this._checkCollisions = null;
		this._redraw = null;
		this.palletGates.forEach((palletGate) => {
			if (typeof palletGate.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				palletGate.removeReferences();
			}
		});
	}

	afterReconstruct() {
		this.palletGateConfiguratorSettings.newPalletGate = true;
		this.showConfigurator = false; // false slaat hij niet altijd op omdat cancel niet een wijziging in de configuratie tot gevolg heeft. Maar bij reconstruct altijd configurator verbergen
	}

	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam); // voor overhang mainbeamlength zelfde als childbeamlength
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam) {}
	collisionCheck() {
		this.palletGates.forEach((palletGate, index) => {
			palletGate.collisionCheck();
		});
	}

	addDrawObjects(canvas, params) {
		let regenerate = false;
		this.get().forEach((palletGate, index) => {
			let result = palletGate.addDrawObjects(false);
			if (typeof result !== 'undefined' && result !== null) {
				if (result.regenerate === true) {
					regenerate = true;
				}
			}
		});
		return { regenerate: regenerate };
	}
	addDrawObjects3d(canvas3d, etage, raster, posY, activeEtageIndex) {
		this.get().forEach((palletGate, index) => {
			palletGate.addDrawObjects3d(canvas3d, etage, raster, posY, activeEtageIndex);
		});
	}
	create3DAssets(canvas3D) {
		this.palletGates.forEach((palletGate, index) => {
			palletGate.create3DAssets(canvas3D);
		});
	}
	get length() {
		let count = 0;
		this.palletGates.forEach((palletGate) => {
			if (palletGate.active === true) {
				count++;
			}
		});
		return count;
	}
	static isPossible(raster, position) {
		let active = true;
		if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(raster.x, raster.y)) === false) {
			active = false;
		} else {
			switch (position) {
				case Mathematic.TOP:
					if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(raster.x, raster.y - 1)) === true) {
						active = false;
					}
					break;
				case Mathematic.BOTTOM:
					if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(raster.x, raster.y + 1)) === true) {
						active = false;
					}
					break;
				case Mathematic.LEFT:
					if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(raster.x - 1, raster.y)) === true) {
						active = false;
					}
					break;
				case Mathematic.RIGHT:
					if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(raster.x + 1, raster.y)) === true) {
						active = false;
					}
					break;
			}
		}
		return active;
	}
	addPossiblePositions(canvas, params) {
		let possiblePositionFound = false;
		let left = 0;

		params.raster.spansX.getSpans().forEach((spanX, indexX) => {
			let top = 0;
			params.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (params.actieveEtage.isActiveRaster(new RemoveRaster(indexX, indexY))) {
					if (PalletGates.isPossible({ x: indexX, y: indexY }, Mathematic.TOP)) {
						possiblePositionFound = true;
						let line = new Line(
							new DrawValue(left),
							new DrawValue(top),
							new DrawValue(left + spanX.value),
							new DrawValue(top),
							new DrawValue(0, 3),
							PalletGates.COLORS.lineDash,
							PalletGates.COLORS.possiblePosition,
							null,
							null,
							true,
							this,
							{
								x: indexX,
								y: indexY,
								position: Mathematic.TOP,
							},
						);
						canvas.addDrawObject(line);
					}
					if (PalletGates.isPossible({ x: indexX, y: indexY }, Mathematic.BOTTOM)) {
						let line = new Line(
							new DrawValue(left),
							new DrawValue(top + spanY.value),
							new DrawValue(left + spanX.value),
							new DrawValue(top + spanY.value),
							new DrawValue(0, 3),
							PalletGates.COLORS.lineDash,
							PalletGates.COLORS.possiblePosition,
							null,
							null,
							true,
							this,
							{
								x: indexX,
								y: indexY,
								position: Mathematic.BOTTOM,
							},
						);
						canvas.addDrawObject(line);
					}
					if (PalletGates.isPossible({ x: indexX, y: indexY }, Mathematic.LEFT)) {
						let line = new Line(
							new DrawValue(left),
							new DrawValue(top),
							new DrawValue(left),
							new DrawValue(top + spanY.value),
							new DrawValue(0, 3),
							PalletGates.COLORS.lineDash,
							PalletGates.COLORS.possiblePosition,
							null,
							null,
							true,
							this,
							{
								x: indexX,
								y: indexY,
								position: Mathematic.LEFT,
							},
						);
						canvas.addDrawObject(line);
					}
					if (PalletGates.isPossible({ x: indexX, y: indexY }, Mathematic.RIGHT)) {
						let line = new Line(
							new DrawValue(left + spanX.value),
							new DrawValue(top),
							new DrawValue(left + spanX.value),
							new DrawValue(top + spanY.value),
							new DrawValue(0, 3),
							PalletGates.COLORS.lineDash,
							PalletGates.COLORS.possiblePosition,
							null,
							null,
							true,
							this,
							{
								x: indexX,
								y: indexY,
								position: Mathematic.RIGHT,
							},
						);
						canvas.addDrawObject(line);
					}
				}
				top += spanY.value;
			});
			left += spanX.value;
		});
		if (possiblePositionFound === false) {
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.editModus.stopEditingObject();
		}
	}
	onRasterChanged(params) {
		this.palletGates.forEach((palletGate) => {
			palletGate.onRasterChanged(params);
		});
	}
	create() {
		this.showConfigurator = false;
		this.palletGateConfiguratorSettings.oldValues = '';
		if (this.palletGateConfiguratorSettings.newPalletGate) {
			// If it's a new item, we set the editingObject so we add the possible positions.
			Configuration.CURRENT.editModus.setEditingObject('palletGates');
		} else {
			this.onChange();
		}
	}

	remove(item) {
		let foundIndex = -1;
		this.palletGates.forEach((palletGate, index) => {
			if (palletGate.id === item.id) {
				foundIndex = index;
				palletGate.removeReferences();
			}
		});
		this.palletGates.splice(foundIndex, 1);
		this.onChange();
	}
	edit(palletGateObject) {
		if (this.possiblePossitionsVisible !== '') {
			// om een of andere reden blijven hangen
			this.possiblePossitionsVisible = '';
			this.onChange();
		}

		this.showConfigurator = true;
		this.palletGateConfiguratorSettings = palletGateObject;
		this.palletGateConfiguratorSettings.newPalletGate = false;
		let serializer = new Serializer();
		this.palletGateConfiguratorSettings.oldValues = serializer.stringify(this.palletGateConfiguratorSettings);
	}
	getAmount() {
		let amount = { PalletGates: [] };
		this.get().forEach((palletGate) => {
			var foundPalletgate = amount.PalletGates.find((p) => p.id === palletGate.getAmount().id && p.optional === palletGate.getAmount().optional);
			if (typeof foundPalletgate === 'undefined') {
				amount.PalletGates.push(palletGate.getAmount());
			} else {
				var index = amount.PalletGates.findIndex((pg) => {
					return pg.id === palletGate.getAmount().id;
				});
				amount.PalletGates[index].amount++;
			}
		});

		return amount;
	}
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];
		this.palletGates.forEach((palletGate) => {
			let palletGateResult = palletGate.collisions(boundaries, self);

			if (palletGateResult.result === true) {
				collisionsDetect = true;
				palletGateResult.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult };
	}

	newPalletGate() {
		if (this.possiblePossitionsVisible !== '') {
			// om een of andere reden blijven hangen
			this.possiblePossitionsVisible = '';
			this.onChange();
		}

		this.showConfigurator = true;

		let groupList = Store.CURRENT.palletGates.getGroupList();
		let group = '';
		if (groupList.length > 0) {
			group = groupList[0].value;
		}
		let widthList = Store.CURRENT.palletGates.getWidthList(group);

		let width = 0;
		if (widthList.length > 0) {
			width = widthList[0].value;
		}

		let height = 0;
		let heightList = Store.CURRENT.palletGates.getHeightList(group);
		if (heightList.length > 0) {
			height = heightList[0].value;
		}
		this.palletGateConfiguratorSettings = new PalletGate({ newPalletGate: true, width: width, height: height, type: group, etageId: Configuration.CURRENT.etages.activeEtage().id });
		let serializer = new Serializer();
		this.palletGateConfiguratorSettings.oldValues = serializer.stringify(this.palletGateConfiguratorSettings);
	}
	cancel() {
		let serializer = new Serializer();
		this.palletGateConfiguratorSettings.update(serializer.parse(this.palletGateConfiguratorSettings.oldValues));
	}
}
