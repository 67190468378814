import { Data } from './data';
import { Store } from './store';
import * as Sentry from '@sentry/browser';
export class User extends Data {
	objectName = 'User';
	static CURRENT = null;
	validateRequest = null;
	validated = false;
	username = '';
	rights = { developer: false };
	token = { value: '', expires: '' };
	url = 'user';

	id = '';
	companies = [];
	fullName = '';
	firstName = '';
	email = '';
	function = '';
	lastName = '';
	mobile = '';
	phone = '';
	prePosition = '';
	title = 0;
	language = '';
	_callBackUrl = '';
	debugModeContruction = false;
	debugModePrice = false;
	calculateWithNotInContract = false;
	priceSpecifications = false;
	sendToHicad = false;
	priceOverview = false;
	passwordExpired = false;
	optionalPossible = false;
	multiProfilesPossible = false;
	quotationDocumentVisibleConfigurator = false;
	orderConfirmationVisibleConfigurator = false;
	portalPages = [];
	defaultPortalPage = '/login';

	// TODO: Deze setting bepalen / ophalen uit ERP, voor testen nu standaard even op true.
	imperial = true;

	constructor(force) {
		if (typeof force === 'undefined' || force === null) {
			force = false;
		}
		if (User.CURRENT === null || force === true) {
			super();
			User.CURRENT = this;
		}
		return User.CURRENT;
	}
	get showName() {
		let name = this.firstName || "";

		if (this.prePosition !== '' && this.prePosition !== null) {
			name += ' ' + this.prePosition;
		}
		if (this.lastName !== '' && this.lastName !== null) {
			name += ' ' + this.lastName;
		}

		return name;
	}
	get defaultCompany() {
		if (this.companies.length > 0) {
			return this.companies[0]; // als aanwezig dan companynummer teruggeven
		}
		return -1; // geen company aanwezig
	}
	get callBackUrl() {
		// als er geen callbackurl is opgegeven naar root gaan
		if (this._callBackUrl === '' || this._callBackUrl === null) {
			return '/';
		}
		// anders naar callbackurl
		return this._callBackUrl;
	}
	set callBackUrl(value) {
		this._callBackUrl = value;
		this.saveToLocalStorage();
	}
	loginError = false;
	fetch(json) {
		super.fetch(json);
	}
	fetchLoginData(data) {
		// logindata heeft eigen structuur die teruggezet wordt. met name door .expires kan dit niet met de standaard fetch opgelost worden

		// als er geen data is meegegeven de variabelen leeg maken
		if (typeof data === 'undefined' || data === null) {
			this.token.value = '';
			this.token.expires = '';
			this.username = '';
			this.callBackUrl = '';

			return;
		}
		if (this.store.dataAccess.tokenBasedAuthentication === true) {
			// vullen met juiste waarden
			this.token.value = data.access_token;
			this.token.expires = data['.expires'];
			this.username = data.userName;
		}

		this.callBackUrl = '';
	}
	persistentData() {
		// welke velden worden er lokaal opgeslagen al er ingelogd is.
		return { token: this.token, username: this.username, callBackUrl: this.callBackUrl };
	}

	login(username, password, thenCallBack) {
		// samenstellen van het request en de url
		const requestData = { username: username, password: password, grant_type: 'password' };
		let url = Data.server + '/Login';
		if (Store.CURRENT.dataAccess.tokenBasedAuthentication === true) {
			url = Data.server + '/token';
		}

		// versturen van het verzoek om in te loggen
		this.store.dataAccess
			.postForm(url, requestData)
			.then((loginData) => {
				if (this.store.dataAccess.tokenBasedAuthentication === false) {
					this.username = username;
				}
				// oude gegevens weggooien
				this.removeFromLocalStorage();
				Store.CURRENT.removeFromLocalStorage();

				// data opslaan in de juiste variabelen
				this.fetchLoginData(loginData);

				// persistent opslaan van bepaalde velden
				this.saveToLocalStorage();

				// terug naar de callbackurl
				this.validate((res) => {
					let lastPageLocalStorage = localStorage.getItem('lastPage');

					if (lastPageLocalStorage) {
						try {
							lastPageLocalStorage = JSON.parse(lastPageLocalStorage);
						} catch (e) {
							// Geen json dus waarschijnlijk string / oude manier van pagina opslaan.
							localStorage.removeItem('lastPage');
						}
					}

					if (typeof lastPageLocalStorage === 'object' && lastPageLocalStorage !== null) {
						// Wanneer ingelogde gebruiker hetzelfde is als laatst ingelogde gebruiker.
						// Dan terugsturen naar de pagina waar hij het laatst op aanwezig was.
						if (lastPageLocalStorage.user === this.id) {
							thenCallBack({ user: this, url: lastPageLocalStorage.url });
						}
						// Wanneer de pagina wel is gezet, maar er is geen gebruiker id geregistreerd.
						// Dan kwam de gebruiker dus op de pagina maar was hij nog niet ingelogd.
						else if (lastPageLocalStorage.user === '') {
							thenCallBack({ user: this, url: lastPageLocalStorage.url });
						}
					}

					// Wanneer nieuwe inlog of gebruiker niet overeen met waarde in localstorage,
					// Dan de defaultPage terugsturen.
					thenCallBack({ user: this, url: this.defaultPortalPage });
				}).catch((error) => {
					console.log(error);
					setTimeout(null, 9000);
				});
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				this.fetchLoginData();

				this.removeFromLocalStorage();
				this.loginError = true;
			});
	}

	logout() {
		let url = Data.server + '/Logout';
		// versturen van het verzoek om in te loggen
		this.store.dataAccess
			.get(url)
			.then((loginData) => {
				this.fetchLoginData();

				// uit localstorage halen
				this.removeFromLocalStorage();

				// Url van laatste pagina weghalen
				localStorage.removeItem('lastPage');

				Store.CURRENT.removeFromLocalStorage();
				// verplicht doorsturen naar login
				window.location.href = '/login';
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				this.fetchLoginData();

				this.removeFromLocalStorage();
				this.loginError = true;
			});
		// inloggegevens verwijderen
	}
	async validate(thenCallBack) {
		// samenstellen url
		let url = Data.baseUrl + this.url + '/validate?userName=' + this.username;
		let config = {};
		// if (Store.CURRENT.dataAccess.tokenBasedAuthentication === true) {
		config = { headers: { Authorization: 'Bearer ' + this.token.value } };
		// }

		// verzoek versturen inclusief token
		this.validateRequest = this.store.dataAccess
			.get(url, false, false, config, 'Validate')
			.then((result) => {
				// Wanneer er in ERP voor de gebruiker een language is geselecteerd, en in de configurator niet eerder door de gebruiker een andere taal is gekozen.
				if (typeof result.language === 'object' && result.language !== null && !this.store.languages.languageSetByUser) {
					this.store.languages.currentLanguage = result.language.code.toUpperCase();
				}
				this.fetch(result);

				// Callback bij login functie
				if (typeof thenCallBack === 'function') {
					thenCallBack();
				}
			})
			.catch((exception) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + exception.message);
				}
				// this.logout();
			});
		// wacht op validatierequest
		await this.validateRequest;

		this.validateRequest = null;

		this.validated = true;
		// this.store.executePreLoad();
	}

	// Nieuw wachtwoord aanvragen, dit is wanneer de gebruiker is ingelogd of vanuit de login.
	newPassword(newPassword, oldPassword, forced, callBack, catchBack) {
		let url = Data.server + 'api/ChangePassword/New-Password';
		this.store.dataAccess
			.postForm(url, { newPassword: newPassword, oldPassword: oldPassword, forced: forced })
			.then((res) => {
				if (typeof callBack === 'function') {
					callBack(res);
				}
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				if (typeof catchBack === 'function') {
					catchBack(error);
				}
			});
	}

	// Aanvraag om de mail te versturen met wachtwoord reset link.
	resetPassword(username, callBack, catchBack) {
		let url = Data.server + 'api/ChangePassword/RequestPasswordResetMail';
		this.store.dataAccess
			.post(url, { username: username })
			.then((res) => {
				if (typeof callBack === 'function') {
					callBack(res);
				}
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				if (typeof catchBack === 'function') {
					catchBack(error);
				}
			});
	}

	// Update wachtwoord met token, dit vanuit de mail verkregen word.
	checkResetId(id, newPassword, callBack, catchBack) {
		let url = Data.server + 'api/ChangePassword/ChangePasswordWithToken';
		this.store.dataAccess
			.postForm(url, { token: id, newPassword: newPassword })
			.then((res) => {
				if (typeof callBack === 'function') {
					callBack(res);
				}
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				if (typeof catchBack === 'function') {
					catchBack(error);
				}
			});
	}
}
