import { Configuration } from '../../../configurator/configuration';
import { Profiles } from '../../../configurator/profiles';
import { Stair } from '../../../configurator/stair';
import { Store } from '../../../data/store';
import { Canvas3D } from '../../Canvas3D';
import { Grating3D } from '../../deckingFinish/Grating3D';
import { LandingBeams3D } from './LandingBeams3D';
import { EndLandingHandRail3D } from './EndLandingHandRail3D';

import { LandingColumns3D } from './LandingColumns3D';

import { LandingWoodPlate3D } from './objects/LandingWoodPlate3D';
import { MidLandingHandRail3D } from './MidLandingHandRail3D';

export class Landing3D {
	objectName = 'Landing3D';
	isEndLanding = false;
	static TYPE_MIDLANDING = 0;
	static TYPE_ENDLANDING = 1;
	// eslint-disable-next-line no-useless-constructor
	constructor(x, y, params) {
		this.isEndLanding = params.endLanding;
		let data = {
			drawHeight: params.drawHeight,
			width: params.width,
			depth: params.depth,
			upComing: params.upComing,
			upComingPrev: params.upComingPrev,
			overhang: Configuration.CURRENT.overhang.size,
			stairData: params.stairData,
			raster: params.raster,
			landingData: params.landingData,
		};

		let spans = params.raster;
		let beamDataX = spans.spansX.getSpans()[params.stairData.rasters[0].x].beam.oid;
		let beamDataY = spans.spansY.getSpans()[params.stairData.rasters[0].y].beam.oid;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			data.mainBeamHeight = Canvas3D.CURRENT.getBeamData(beamDataX, 'height');
			data.childBeamDepth = Canvas3D.CURRENT.getBeamData(beamDataY, 'height');
			data.mainBeamDepth = Canvas3D.CURRENT.getBeamData(beamDataX, 'depth');
			data.childBeamHeight = Canvas3D.CURRENT.getBeamData(beamDataY, 'height');
			data.childBeamId = beamDataY;
			data.mainBeamId = beamDataX;
		} else {
			data.mainBeamHeight = Canvas3D.CURRENT.getBeamData(beamDataY, 'height');
			data.mainBeamDepth = Canvas3D.CURRENT.getBeamData(beamDataY, 'depth');
			data.childBeamDepth = Canvas3D.CURRENT.getBeamData(beamDataX, 'depth');
			data.childBeamHeight = Canvas3D.CURRENT.getBeamData(beamDataX, 'height');
			data.childBeamId = beamDataX;
			data.mainBeamId = beamDataY;
		}

		// TODO: Later oplossen dat positie gelijk overhang meeneemt.
		if (data.stairData.place !== Stair.PLACE_INFLOOR) {
			if (data.landingData.upComing === Stair.UPCOMING_LEFT) {
				x -= data.overhang;
			} else if (params.landingData.upComing === Stair.UPCOMING_TOP) {
				y -= data.overhang;
			} else if (params.landingData.upComing === Stair.UPCOMING_BOTTOM) {
				y += data.overhang;
			} else if (params.landingData.upComing === Stair.UPCOMING_RIGHT) {
				x += data.overhang;
			}
		}

		if (this.isGratingDeckingFinish(params.landingData.finish)) {
			// Toevoegen van grating
			Canvas3D.CURRENT.addDrawObject(
				new Grating3D(x, y, {
					width: data.width,
					depth: data.depth,
					drawHeight: data.drawHeight,
					stairData: data.stairData,
				}),
				Canvas3D.TYPE_FINISH,
			);
		} else {
			// Toevoegen van houtplaat
			Canvas3D.CURRENT.addDrawObject(new LandingWoodPlate3D(x, y, data), Canvas3D.TYPE_FINISH);
		}

		// Kolommen toevoegen voor de landing.
		Canvas3D.CURRENT.addDrawObject(new LandingColumns3D(x, y, data, this.isEndLanding), Canvas3D.TYPE_COLUMN);

		if (this.isEndLanding) {
			// Wanneer endLanding dan 4 kolommen, berekent in zijn eigen object.
			Canvas3D.CURRENT.addDrawObject(new EndLandingHandRail3D(x, data.drawHeight, y, data), Canvas3D.TYPE_HANDRAIL);
		} else {
			// Bij een middenlanding is de handrail op een landing op 2 plekken open. Daarom daar een los object voor gemaakt.
			Canvas3D.CURRENT.addDrawObject(new MidLandingHandRail3D(x, data.drawHeight, y, data), Canvas3D.TYPE_HANDRAIL);
		}

		data.drawHeight = data.drawHeight + (params.stairHigherthanFirstFloor ? data.landingData.height : 0);
		Canvas3D.CURRENT.addDrawObject(new LandingBeams3D(x, y, data), Canvas3D.TYPE_PROFILE);
	}

	isGratingDeckingFinish(landing) {
		let finish = Store.CURRENT.deckingFinishes.listBox.find((f) => f.id === landing);
		return finish.caption === 'Grating';
	}
}
