import { DrawValue } from '../../draw/drawValue';
import { Line } from '../../draw/line';
import { Columns } from '../columns';
import { Configuration } from '../configuration';
import { Profiles } from '../profiles';
import { Trimmings } from './trimmings';

export class Trimming {
	objectName = 'Trimming';
	startX = 0;
	startY = 0;
	endX = 0;
	endY = 0;
	position = -1;
	// Om bij te houden op welke etage de trimming hoort.
	// Dan kunnen we er in 2D rekening mee houden dat hij netjes de beams tekenent die bij etage horen.
	// Bij het 3D tekenen dan gewoon allemaal tekenen op de juiste hoogte die bij die etage hoort.
	etageId = null;
	drawObjectId = null;
	active = true;

	constructor(trimming) {
		if (typeof trimming !== 'undefined' && trimming !== null) {
			this.update(trimming);
		}
	}

	update(trimming) {
		this.startX = trimming.startX;
		this.startY = trimming.startY;
		this.endX = trimming.endX;
		this.endY = trimming.endY;
		this.position = trimming.position;
		this.etageId = trimming.etageId;

		if (typeof trimming.active === 'undefined' || trimming.active === null) {
			// niet meegegeven dan default true
			this.active = true;
		} else {
			// anders overnemen
			this.active = trimming.active;
		}
	}
	calculateAmount(params) {
		let rasterCoords = params.raster.getRasterByCoordinate(this.startX, this.startY);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (rasterCoords.x === -1) {
				rasterCoords.x = 0;
			}
			span = params.raster.spansX.get(rasterCoords.x);
		} else {
			if (rasterCoords.y === -1) {
				rasterCoords.y = 0;
			}
			span = params.raster.spansY.get(rasterCoords.y);
		}
		if (this.startX === this.endX) {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endY - this.startY), 1, span);
		} else {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endX - this.startX), 1, span);
		}
	}
	// Globale addDrawobjects van de BuildingColumnTrimming, EndlandingTrimming, holeTrimming en StairTrimming.
	// Meegeven parentObject, dit is nodig om vanuit canvas tekenen aanpassingen te doen in het juiste object. Voorbeeld hiervan: Selected bij click of drag.
	addDrawObjects(objectName, parentObject) {
		let profiles = Configuration.CURRENT.profiles;

		// mainbeams worden tegen kolommen aangetekend en kolommen staan precies op het midden. Dus helft van kolom gaat eraf. Waarvan is afhankelijk van richting hoofdbalken
		let columnShiftX = 0;
		let columnShiftY = 0;
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (this.position === Trimmings.LEFT || this.position === Trimmings.RIGHT) {
				columnShiftX = 0;
				columnShiftY = Columns.COLUMN_SIZE / 2;
			}
		} else if (this.position === Trimmings.TOP || this.position === Trimmings.BOTTOM) {
			columnShiftX = Columns.COLUMN_SIZE / 2;
			columnShiftY = 0;
		}

		let trimming = new Line(
			new DrawValue(this.startX, columnShiftX),
			new DrawValue(this.startY, columnShiftY),
			new DrawValue(this.endX, -columnShiftX),
			new DrawValue(this.endY, -columnShiftY),
			null,
			null,
			profiles.colors.mainBeam,
			null,
			null,
			false,
			parentObject,
			{ type: objectName, color: null },
		);
		trimming.colorFromParent = false;
		this.drawObjectId = trimming.id;
		return trimming;
	}
}
