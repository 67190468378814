import { Canvas } from '../draw/canvas';
import { SizeHandle } from '../draw/sizeHandle';
import { Configuration } from './configuration';
import { Profiles } from './profiles';

export class Dimensioning {
	objectName = 'Dimensioning';

	/*
    ! Properties
  */
	showDimensioningPopup = false;
	clickedDimensionIndex = -1;
	direction = Profiles.MB_HORIZONTAL;

	/* 
  ! Getters and Setters
  */
	get currentEditingValue() {
		if (this.clickedDimensionIndex !== -1) {
			return this.dimensions[this.clickedDimensionIndex];
		}
	}
	get max() {
		if (this.direction === Profiles.MB_HORIZONTAL) {
			return Configuration.CURRENT.etages.activeEtage().floor.width;
		}
		if (this.direction === Profiles.MB_VERTICAL) {
			return Configuration.CURRENT.etages.activeEtage().floor.depth;
		}
	}
	get min() {
		return 0;
	}

	/* 
  ! Functions
  */
	onClickedDimensionItem(clickedDimensionIndex, direction, dimensions, onChange) {
		// When items are partly outside floor, let user not adjust it with the component.
		// Drag is always available as an alternative.
		const totalEtageWidth = Configuration.CURRENT.etages.get(Configuration.CURRENT.etages.activeEtage().etageIndex).floor.width;
		const totalEtageDepth = Configuration.CURRENT.etages.get(Configuration.CURRENT.etages.activeEtage().etageIndex).floor.length;
		if (
			dimensions.some((dimNumber) => dimNumber < 0) ||
			(dimensions.reduce((accumulator, currentValue) => accumulator + currentValue, 0) > totalEtageWidth && direction === Profiles.MB_HORIZONTAL) ||
			(dimensions.reduce((accumulator, currentValue) => accumulator + currentValue, 0) > totalEtageDepth && direction === Profiles.MB_VERTICAL)
		) {
			this.showDimensioningPopup = false;
			return;
		}

		this.clickedDimensionIndex = clickedDimensionIndex;
		this.direction = direction;
		this.dimensions = dimensions.map(Math.abs); // Altijd als positief laten zien ookal is het kleiner dan 0.
		this.onChange = onChange;
		this.showDimensioningPopup = true;
	}
	updateValue(value) {
		const difference = value - this.dimensions[this.clickedDimensionIndex];

		this.dimensions[this.clickedDimensionIndex] = value;

		// For accesoires items:
		// - BuildingColumn
		// - Holes
		// - Stairs
		// - Rasters
		if (this.dimensions.length > 2) {
			// Calculate adjustments for adjacent elements
			if (this.clickedDimensionIndex > 0) {
				// Update the value of the previous dimension
				this.dimensions[this.clickedDimensionIndex - 1] -= difference / 2;
			}
			if (this.clickedDimensionIndex < this.dimensions.length - 1) {
				// Update the value of the next dimension
				this.dimensions[this.clickedDimensionIndex + 1] -= difference / 2;
			}
		}
		// For items where we don't draw 3 dimensions.
		// - Columns
		else if (this.dimensions.length === 2) {
			const otherIndex = this.clickedDimensionIndex === 0 ? 1 : 0;
			this.dimensions[otherIndex] -= difference;
		}

		// Refers back to the sizeHandle events.
		this.onChange(this.dimensions, this.clickedDimensionIndex);
		this.showDimensioningPopup = false;
	}
	cancel() {
		this.showDimensioningPopup = false;
	}

	afterReconstruct() {
		this.showDimensioningPopup = false;
		this.clickedDimensionIndex = -1;
		this.onChange = null;
		this.dimensions = [];
		this.direction = Profiles.MB_HORIZONTAL;
	}

	setSizeHandleObject(configurationObject, sizeHandleType = SizeHandle.TYPE_OBJECT, setParent = false, startX, startY, objectWidth, objectDepth) {
		let dimensionsVertical;
		let dimensionsHorizontal;
		// !! Voor objecten buiten de vloer nog een oplossing bedenken, voor nu dan vanuit dat object de sizehandles ophalen.
		// !! Ook voor object column waar we altijd maar twee stukken tekenden en niet de breedte van een echte kolom gaat het nog mis.
		// !! Om de sleep en aanapssingslogica werkend te houden voor nu zijn eigen posities nog bepalen in object.
		// !! Later passen we dit aan dat ook de kolom getekend word en de sizehandle events goed werken bij Column object.
		if (configurationObject.objectName === 'StairOutSide' || configurationObject.objectName === 'CageLadderOutSide' || configurationObject.objectName === 'Column') {
			dimensionsHorizontal = configurationObject.dimensionsHorizontal;
			dimensionsVertical = configurationObject.dimensionsVertical;
		}
		// !! Voor objecten in de vloer zelf berekenen in eigen logica.
		else {
			dimensionsVertical = this.calculateDimensionsVertical(startY, objectDepth);
			dimensionsHorizontal = this.calculateDimensionsHorizontal(startX, objectWidth);
		}

		// Update sizehandles.
		Canvas.CURRENT.sizeHandles.get(sizeHandleType).setByArray(dimensionsHorizontal, dimensionsVertical);

		if (setParent === true) {
			Canvas.CURRENT.sizeHandles.get(sizeHandleType).parent = configurationObject;
		}

		let events = {};

		// Horizontal
		if (typeof configurationObject.onSizeHandleChangedHorizontal === 'function') {
			events.onChangedHorizontal = configurationObject.onSizeHandleChangedHorizontal.bind(configurationObject);
		}
		if (typeof configurationObject.onSizeHandleChangeHorizontal === 'function') {
			events.onChangeHorizontal = configurationObject.onSizeHandleChangeHorizontal.bind(configurationObject);
		}

		// Vertical.
		if (typeof configurationObject.onSizeHandleChangedVertical === 'function') {
			events.onChangedVertical = configurationObject.onSizeHandleChangedVertical.bind(configurationObject);
		}

		if (typeof configurationObject.onSizeHandleChangeVertical === 'function') {
			events.onChangeVertical = configurationObject.onSizeHandleChangeVertical.bind(configurationObject);
		}

		// Mouse move event.
		if (typeof configurationObject.checkMovePossible === 'function') {
			events.movePossible = configurationObject.checkMovePossible.bind(configurationObject);
		}

		Canvas.CURRENT.sizeHandles.get(sizeHandleType).registerEvents(events);

		let startPositionVertical = Math.min(...dimensionsVertical) > 0 ? 0 : Math.min(...dimensionsVertical);
		let startPositionHorizontal = Math.min(...dimensionsHorizontal) > 0 ? 0 : Math.min(...dimensionsHorizontal);

		// Set min startDrawvalue.
		Canvas.CURRENT.sizeHandles.get(sizeHandleType).vertical.startPosition = startPositionVertical;
		Canvas.CURRENT.sizeHandles.get(sizeHandleType).horizontal.startPosition = startPositionHorizontal;
	}

	updateDrawObjects(startX, startY, objectWidth, objectDepth, sizeHandleType = SizeHandle.TYPE_OBJECT) {
		let dimensionsVertical = this.calculateDimensionsVertical(startY, objectDepth);
		let dimensionsHorizontal = this.calculateDimensionsHorizontal(startX, objectWidth);

		Canvas.CURRENT.sizeHandles.get(sizeHandleType).updateDrawObjects(dimensionsHorizontal, dimensionsVertical);
	}
	// Function to generate the sizehandle sizes.
	calculateDimensionsHorizontal(startX, objectWidth) {
		const totalFloorWidth = Configuration.CURRENT.etages.activeEtage().floor.width;
		let horizontalDimensions = [];
		//* When start is before 0, we show the amount the object is outside the floor on the left side.
		//* The second value will not be the objectwidth, but rather the amount of the object that is in the floor.
		//* The rest is from the endpoint to the end of the etage width.
		if (startX < 0) {
			horizontalDimensions[0] = startX;
			horizontalDimensions[1] = objectWidth - Math.abs(startX);
			horizontalDimensions[2] = totalFloorWidth - objectWidth + Math.abs(startX);
		}
		// * When the endpoint of the object is outside of the floor length.
		// * The second is the amount of the object that is still inside the floor.
		// * The last part is a calculation of how much the object it outside the floor.
		else if (startX + objectWidth > totalFloorWidth) {
			horizontalDimensions[0] = startX;
			horizontalDimensions[1] = totalFloorWidth - startX;
			horizontalDimensions[2] = startX + objectWidth - totalFloorWidth;
		}
		// * Normal situation where the object is inside the floor, first is the startX amount.
		// * Second is the objectWidth
		// * Last is how many is left from the endpoint to the endpoint of the floor.
		else {
			horizontalDimensions[0] = startX;
			horizontalDimensions[1] = objectWidth;
			horizontalDimensions[2] = totalFloorWidth - startX - objectWidth;
		}

		// * We don't want numbers like 800.9435, so we round.
		return horizontalDimensions.map(Math.round);
	}
	calculateDimensionsVertical(startY, objectDepth) {
		const totalFloorDepth = Configuration.CURRENT.etages.activeEtage().floor.length;
		let verticalDimensions = [];
		//* When start is before 0, we show the amount the object is outside the floor on the top side.
		//* The second value will not be the objectdepth, but rather the amount of the object that is  in the floor.
		//* The rest is from the endpoint to the end of the etage depth.
		if (startY < 0) {
			verticalDimensions[0] = startY;
			verticalDimensions[1] = objectDepth - Math.abs(startY);
			verticalDimensions[2] = totalFloorDepth - objectDepth + Math.abs(startY);
		}
		// * When the endpoint of the object is outside of the etage depth.
		// * The second is the amount of the object that is still inside the floor.
		// * The last part is a calculation of how much the object it outside the floor.
		else if (startY + objectDepth > totalFloorDepth) {
			verticalDimensions[0] = startY;
			verticalDimensions[1] = totalFloorDepth - startY;
			verticalDimensions[2] = startY + objectDepth - totalFloorDepth;
		}
		// * Normal situation where the object is inside the floor, first is the startY amount.
		// * Second is the objectDepth
		// * Last is how many is left from the endpoint to the endpoint of the floor.
		else {
			verticalDimensions[0] = startY;
			verticalDimensions[1] = objectDepth;
			verticalDimensions[2] = totalFloorDepth - startY - objectDepth;
		}

		return verticalDimensions.map(Math.round);
	}
}
