// This class represents the current configuration object we are editing.
// When adding an item (stair, handrail, cageladder) to the floor we save the name of the object in this class.
// The name is the parent of a single item, for example: Stair = Stairs, cageLadder = Cageladders, handRail = Handrails.
// This is because for some objects we can add or edit multiple instances of a single object at once.
import { Configuration } from './configuration';
import { Notification } from './notification';

export class EditModus {
	objectName = 'EditModus';
	notification = new Notification();
	editingObjectName = '';

	// To show the toolsMenu in the right top of the configurator.
	showToolsMenu = false;

	// Show bracing popover
	showBracingPopover = false;

	// Handrail settings popup visisble.
	showHandrailPopover = false;

	// Show error popup, errors in the whole configuration.
	showErrorPopup = false;

	// Usefull getter to use in vue files instead of calling 'Configuration.editModus.editingObjectName !== ""'
	get showEditModus() {
		return this.editingObjectName !== '';
	}

	// Function to save which object we are currently adding or editing it's items from.
	setEditingObject(objectName) {
		this.editingObjectName = objectName;
		this.notification.show(objectName.toLowerCase() + '.position');

		// Hide panels.
		Configuration.CURRENT.openedPanel = {};
		Configuration.CURRENT.redraw();

		switch (objectName) {
			case 'handRails':
				this.showHandrailPopover = true;
				this.showBracingPopover = false;
				break;
			case 'braceColumns':
			case 'bracings':
			case 'portalBracings':
				this.showBracingPopover = true;
				this.showHandrailPopover = false;
				break;
		}
		this.showToolsMenu = false;
		this.showErrorPopup = false;
	}

	// When stopping with the edit of an object or when the configured object has been placed by the user clicking on it's possible position.
	stopEditingObject() {
		switch (this.editingObjectName) {
			case 'columnProtectors':
				Configuration.CURRENT.columns.stoppedEditingObject();
				break;
		}
		this.resetInitialValues();
		// After stopping edit modus, also redraw, because in the draw we might draw possiblePositions.
		Configuration.CURRENT.rasterChanged();
	}

	// After configuration is loaded reset some values because we don't want to open configurations in edit modes.
	// Because this can also happen when the user exists the configuration by accident.
	afterReconstruct() {
		this.resetInitialValues();
	}

	// Rest to defaults.
	resetInitialValues() {
		this.editingObjectName = '';
		this.notification.hide();

		// Default show the toolsmenu and hide all the popover.
		this.showToolsMenu = true;

		// Hide object panels right bottom.
		this.showBracingPopover = false;
		this.showHandrailPopover = false;

		// Default show errors on the page.
		this.showErrorPopup = true;
	}

	// Handy getter for showing brace popup.
	get isEditingBracings() {
		return this.editingObjectName === 'braceColumns' || this.editingObjectName === 'bracings' || this.editingObjectName === 'portalBracings';
	}

	// ! Add, so when clicking in vue on the add functionality.
	// For items where we have a modal we first use the new function.
	// This way the modal gets filled and we don't get in the editmodus yet.
	addObject(objectName) {
		switch (objectName) {
			case 'cageLadders':
				Configuration.CURRENT.cageLadders.newCageLadder();
				break;
			case 'palletGates':
				Configuration.CURRENT.etages.activeEtage().palletGates.newPalletGate();
				break;
			case 'buildingColumns':
				Configuration.CURRENT.buildingColumns.newBuildingColumn();
				break;
			case 'holes':
				Configuration.CURRENT.etages.activeEtage().holes.newHole();
				break;
			default:
				this.setEditingObject(objectName);
				break;
		}
		// When in editmodus or when the popup for other objects shows, we don't want to show the errorpopup.
		this.showErrorPopup = false;
	}

	// ! Create object when user submits the modal/popup, for stairs, buildingColumn for example.
	// After filling in the modal / popup for the object we use the create function for each object.
	createObject(payLoad) {
		const name = payLoad.name;
		const object = payLoad.object;

		switch (name) {
			case 'cageLadders':
				Configuration.CURRENT.cageLadders.create();
				break;
			case 'palletGates':
				Configuration.CURRENT.etages.activeEtage().palletGates.create();
				break;
			case 'buildingColumns':
				Configuration.CURRENT.buildingColumns.create();
				break;
			case 'holes':
				Configuration.CURRENT.etages.activeEtage().holes.create();
				break;
			case 'stairs':
				Configuration.CURRENT.etages.activeEtage().stairs.create(object);
				break;
			default:
				console.log('Create accessoire, but name is not provided', payLoad);
				break;
		}

		// * We don't set the editModus active here because the object could also be adjusted and not newly be added.
	}

	// ! When we are editing objects which are already placed, this is always when the popup gets triggerd.
	editObject(payLoad) {
		this.showErrorPopup = false;
		this.showToolsMenu = false;
		switch (payLoad.objectName) {
			case 'StairOutSide':
			case 'StairInFloor':
				Configuration.CURRENT.etages.activeEtage().stairs.edit(payLoad);
				break;
			case 'Hole':
				Configuration.CURRENT.etages.activeEtage().holes.edit(payLoad);
				break;
			case 'CageLadder':
				Configuration.CURRENT.cageLadders.edit(payLoad);
				break;
			case 'PalletGate':
				Configuration.CURRENT.etages.activeEtage().palletGates.edit(payLoad);
				break;
			case 'BuildingColumn':
				Configuration.CURRENT.buildingColumns.edit(payLoad);
				break;
			default:
				break;
		}
	}

	// ! On cancel popup, or dismiss from the notification.
	cancelObject(objectName) {
		switch (objectName) {
			case 'cageLadders':
				Configuration.CURRENT.cageLadders.cancel();
				break;
			case 'palletGates':
				Configuration.CURRENT.etages.activeEtage().palletGates.cancel();
				break;
			case 'buildingColumns':
				Configuration.CURRENT.buildingColumns.cancel();
				break;
			case 'holes':
				Configuration.CURRENT.etages.activeEtage().holes.cancel();
				break;
			case 'stairs':
				Configuration.CURRENT.etages.activeEtage().stairs.cancel();
				break;
		}
		this.stopEditingObject();
	}
}
