import { Configuration } from '../../configurator/configuration';
import { Stair } from '../../configurator/stair';
import { Canvas3D } from '../Canvas3D';
import { Store } from '../../data/store';
import { HandRail3D } from '../handrail/handRail3D';
import { HandRails } from '../../configurator/handRails';
import { RemoveRaster } from '../../configurator/removeRaster';

export class StairInFloorHandRail3D {
	constructor(stairObject) {
		let params = {};
		// Bij infloor handrail gebruiken we dezelfde handrailitems als van de handrail normaal (Dus niet die van de stair)
		// Daarom gewoon handrailtype gebruiken (oid van de saleslocatie).
		params.handRailMaterials = Store.CURRENT.handRails.getMaterials(Configuration.CURRENT.handRailType);
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(params.handRailMaterials.post, 'width');

		let x = stairObject.stairWell.startX;
		let y = stairObject.endHeight;
		let z = stairObject.stairWell.startY;

		let stairWellWidth = stairObject.stairWell.width;
		let stairWellDepth = stairObject.stairWell.depth;

		let stairX = stairObject.stairBoundries.topLeft.x;
		let stairY = stairObject.stairBoundries.topLeft.y;
		let stairWidth = stairObject.width;

		let rasterLeft = Configuration.CURRENT.raster.getRasterByCoordinate(x, z);
		let rasterRight = Configuration.CURRENT.raster.getRasterByCoordinate(x + stairWellWidth, z);
		let rasterBottom = Configuration.CURRENT.raster.getRasterByCoordinate(x, z + stairWellDepth);
		let etage = Configuration.CURRENT.etages.getById(stairObject.etageId);
		// Als trap in de vloer voor de helft in de vloer valt dan opposite side niet tekenen.
		let drawOppositeSide = true;

		// Kijken of X en Z in een actief raster liggen, het kan voorkomen dat een StairInFloor niet geheel in de vloer staat.
		switch (stairObject.upComing) {
			case Stair.UPCOMING_RIGHT:
				// Trap linker punt ligt in een niet actief raster en wijst naar rechts.
				if (etage.isActiveRaster(new RemoveRaster(rasterLeft.x, rasterLeft.y)) === false) {
					// Dan halen we het startPunt op op basis van volgende raster.
					x = Configuration.CURRENT.raster.getSizeX(rasterLeft.x);
					// Breedte is dan niet stairwell maar het de lengte die wel in een actief raster ligt.
					stairWellWidth = stairObject.stairWell.startX + stairObject.stairWell.width - x;
					drawOppositeSide = false;
				}
				break;

			case Stair.UPCOMING_LEFT:
				// Trap rechter punt niet in een actief raster en wijst naar links.
				if (etage.isActiveRaster(new RemoveRaster(rasterRight.x, rasterRight.y)) === false) {
					// Dan terugrekenen, dus beginpunt niet veranderen maar lengte uitrekenen.
					stairWellWidth = Configuration.CURRENT.raster.getSizeX(rasterLeft.x) - stairObject.stairWell.startX;
					drawOppositeSide = false;
				}
				break;

			case Stair.UPCOMING_TOP:
				// Trap onderste punt niet in een actief raster en wijst naar boven.
				if (etage.isActiveRaster(new RemoveRaster(rasterBottom.x, rasterBottom.y)) === false) {
					// Dan terugrekenen, dus beginpunt niet veranderen maar lengte uitrekenen.
					stairWellDepth = Configuration.CURRENT.raster.getSizeY(rasterLeft.y) - z;
					drawOppositeSide = false;
				}
				break;
			case Stair.UPCOMING_BOTTOM:
				// Trap bovenste punt niet in een actief raster en wijst naar boven.
				if (etage.isActiveRaster(new RemoveRaster(rasterLeft.x, rasterLeft.y)) === false) {
					// Dan terugrekenen, dus beginpunt niet veranderen maar lengte uitrekenen.
					// Ook nieuwe Z positie uitrekenen op basis hoeveel er wel in het actieve raster ligt.
					z = Configuration.CURRENT.raster.getSizeY(rasterLeft.y);
					stairWellDepth = stairObject.stairWell.startY + stairObject.stairWell.depth - z;
					drawOppositeSide = false;
				}
				break;
		}

		this.poleColor = Configuration.CURRENT.colors.handRail.ralColor;
		this.plateColor = 7036;

		let paramPreset = {
			childFriendly: Store.CURRENT.handRails.isChildFriendly(Configuration.CURRENT.handRailType),
			handRailType: Configuration.CURRENT.handRailType,
		};

		if (stairObject.upComing === Stair.UPCOMING_LEFT || stairObject.upComing === Stair.UPCOMING_RIGHT) {
			// Bij links of rechts upcoming dan altijd boven en onder een lang horizontaal stuk.
			if (stairObject.handRails.topActive) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);
			}
			
			if (stairObject.handRails.bottomActive) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);
			}

			if (stairObject.upComing === Stair.UPCOMING_RIGHT) {
				if (drawOppositeSide && stairObject.handRails.leftActive) {
					// Bij upcoming right aan de linkerkant het lange verticale stuk.
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);
				}

				if (stairObject.handRails.rightActive) {
					// Dan aan de rechterkant klein stukje verticaal voor de trap.
					let firstLength = stairY - z + poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z, paramPreset, HandRails.RIGHT, firstLength, true), Canvas3D.TYPE_HANDRAIL);

					// En een klein stukje na de trap verticaal.
					let secondLength = z + stairWellDepth - (stairY + stairWidth) + poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z + stairWidth + firstLength - 2 * poleWidth, paramPreset, HandRails.RIGHT, secondLength, true), Canvas3D.TYPE_HANDRAIL);
				}
			} else {
				// Bij upcoming left dan aan de rechterkant een lang verticaal stuk.
				if (drawOppositeSide && stairObject.handRails.rightActive) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z, paramPreset, HandRails.RIGHT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);
				}

				if (stairObject.handRails.leftActive) {
					// Dan aan de linkerkant klein stukje verticaal voor de trap.
					let firstLength = stairY - z + poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, firstLength, true), Canvas3D.TYPE_HANDRAIL);

					// En aan de linkerkant klein stukje verticaal na de trap.
					let secondLength = z + stairWellDepth - (stairY + stairWidth) + poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWidth + firstLength - 2 * poleWidth, paramPreset, HandRails.LEFT, secondLength, true), Canvas3D.TYPE_HANDRAIL);
				}
			}
		} else if (stairObject.upComing === Stair.UPCOMING_BOTTOM || stairObject.upComing === Stair.UPCOMING_TOP) {
			// Bij top of bottom upcoming dan altijd links en rechts een lang verticaal stuk.
			if(stairObject.handRails.leftActive) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);
			}

			if(stairObject.handRails.rightActive) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z, paramPreset, HandRails.RIGHT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);
			}

			if (stairObject.upComing === Stair.UPCOMING_BOTTOM) {
				// Bij bottom upcoming hebben we dus aan de bovenkant een lang stuk horizonaal.
				if (drawOppositeSide && stairObject.handRails.topActive) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);
				}

				if (stairObject.handRails.bottomActive) {
					// Dan aan de onderkant klein stukje horizontaal voor de trap.
					let firstLength = stairX - x + poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, firstLength, true), Canvas3D.TYPE_HANDRAIL);

					// En een klein stukje na de trap horizontaal.
					let secondLength = x + stairWellWidth - (stairX + stairWidth) + 1 * poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWidth + firstLength - 2 * poleWidth, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, secondLength, true), Canvas3D.TYPE_HANDRAIL);
				}
			} else {
				// Wanneer upcoming boven is dan aan de onderkant lang horizontaal stuk.
				if (drawOppositeSide && stairObject.handRails.bottomActive) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);
				}

				if (stairObject.handRails.topActive) {
					// Dan aan de bovenkant 2 kleine stukjes horizontaal, de eerste voor de trap.
					let firstLength = stairX - x + poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, firstLength, true), Canvas3D.TYPE_HANDRAIL);

					// 2e stukje horizonaal precies na de trap.
					let secondLength = x + stairWellWidth - (stairX + stairWidth) + 1 * poleWidth;
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWidth + firstLength - 2 * poleWidth, y, z, paramPreset, HandRails.TOP, secondLength, true), Canvas3D.TYPE_HANDRAIL);
				}
			}
		}
	}
}
