import { Store } from '../data/store';
import { Mathematic } from '../helpers/mathematic';
import { Configuration } from './configuration';

export class Imperial {
	objectName = 'Imperial';
	getFloorLength() {
		if (Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === true) {
			return Mathematic.ImperialToInputString(Configuration.CURRENT.etages.floor.length + Configuration.CURRENT.overhang.size * 2);
		} else {
			return Configuration.CURRENT.etages.floor.length + Configuration.CURRENT.overhang.size * 2 + ' mm';
		}
	}
	getFloorWidth() {
		if (Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === true) {
			return Mathematic.ImperialToInputString(Configuration.CURRENT.etages.floor.width + Configuration.CURRENT.overhang.size * 2);
		} else {
			return Configuration.CURRENT.etages.floor.width + Configuration.CURRENT.overhang.size * 2 + ' mm';
		}
	}
	getTotalEtagesHeight() {
		if (Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === true) {
			return Mathematic.ImperialToInputString(Configuration.CURRENT.etages.totalHeight);
		} else {
			return Configuration.CURRENT.etages.totalHeight + ' mm';
		}
	}
	getEtageSurface(etage) {
		if (Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === true) {
			return Mathematic.GetM2ConvertedToImperial(etage.surface);
		} else {
			return etage.surface.toFixed(2).replace(/\./g, ',') + ' mm';
		}
	}
}
