import { Mathematic } from '../helpers/mathematic';
import { Data } from './data';
import { Store } from './store';

export class StairSteps extends Data {
	objectName = 'StairSteps';
	static CURRENT = null;
	url = 'StairSteps';
	distinctList = null;
	onListChanged(cacheId) {
		super.onListChanged(cacheId);
		if (cacheId === 'list') {
			this.distinctList = null;
		}
	}
	mapList(list) {
		let distinctWidth = [...new Set(list.map((x) => x.width))].sort(function (a, b) {
			return a - b;
		});

		if (this.distinctList === null) {
			this.distinctList = distinctWidth.map((width) => {
				return { value: width, caption: Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === false ? width : Mathematic.ImperialToInputString(width) };
			});
		}

		return this.distinctList;
	}
	getWidthList(force) {
		// Kijk of lijst al opgehaald is

		if (typeof this._list.list !== 'undefined' && this._list.list !== null && !force) {
			let distinctWidth = [...new Set(this._list.list.map((x) => x.width))].sort(function (a, b) {
				return a - b;
			});

			if (this.distinctList === null) {
				this.distinctList = distinctWidth.map((width) => {
					return { value: width, caption: width };
				});
			}

			return this.distinctList;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			return this.list(null, null, null, null, () => {
				this.getWidthList();
			});
		} // geen lijst of niet gevonden dan leeg object
		return [];
	}
	getWidthListBasedOnType(type) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let filteredList = this._list.list.filter((item) => item.groupName === type);

			let distinctWidth = [...new Set(filteredList.map((x) => x.width))].sort(function (a, b) {
				return a - b;
			});

			let newList = distinctWidth.map((width) => {
				return { value: width, caption: Store.CURRENT.companies.selectedCompany.measurementSystem.imperial ? Mathematic.ImperialToInputString(width) : width };
			});

			return newList;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			return this.list(null, null, null, null, () => {
				this.getWidthListBasedOnType(type);
			});
		} // geen lijst of niet gevonden dan leeg object
		return [];
	}
	getMinAngle(stepType, width) {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let possibleSteps = this._list.list.filter((item) => item.caption === stepType && item.width === width);
			if (possibleSteps.length > 0) {
				return Math.min(...possibleSteps.map((o) => o.minDegrees));
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object

		return -1;
	}
	getMaxAngle(stepType, width) {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let possibleSteps = this._list.list.filter((item) => item.groupName === stepType && item.width === width);
			if (possibleSteps.length > 0) {
				return Math.max(...possibleSteps.map((o) => o.maxDegrees));
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			// Na ophalen lijst alsnog eigen functie uitvoeren.
			this.list(null, null, null, null, () => {
				this.getMinAngle(stepType, width);
			});
		}
		// geen lijst of niet gevonden dan leeg object
		return -1;
	}
	// Check om te kijken of het huidige opgegeven angle mogelijk is bij dit artikel.
	// Zitten meerdere steps per groupname, dus kijken of groepnaam overeenkomt en breedte van de step die gekozen is en of dan de angle past.
	isAnglePossible(typeStep, stepWidth, stepAngle) {
		return this._list.list.some((step) => step.groupName === typeStep && stepWidth === step.width && stepAngle >= step.minDegrees && stepAngle <= step.maxDegrees);
	}

	// Bij verandering van type trede in het vue component, dan past de breedte lijst van tredes zich aan,
	// Dan checken we of de huidige breedte van de trap nog mag.
	// ALs dat niet mag passen we in het vue component de width van de trap en trede aan naar een optie die wel mag.
	isWidthPossible(typeStep, stepWidth, stepAngle) {
		return this._list.list.some((step) => step.groupName === typeStep && step.width === stepWidth);
	}

	// Get all steps types, only if width is present in for every item.
	getStepTypeList() {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek alle trede op met betreffende breedte
			let widthFiltered = this._list.list.filter((item) => item.groupName !== '');

			// filter unieke treden
			let uniqueSteps = [];
			widthFiltered.forEach((step) => {
				if (uniqueSteps.filter((uniqueStep) => uniqueStep.value === step.groupName).length === 0) {
					uniqueSteps.push({ caption: step.caption, value: step.groupName });
				}
			});
			// sorteer op basis van groepnaam
			uniqueSteps.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));

			return uniqueSteps;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return [];
	}
	getItem(stepType, width, angle) {
		// omdat de hele lijst met list opgehaald wordt hier geen aparte routine of gebruik van get om een item op de server op te halen.
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek betreffende trede op
			let possibleSteps = this._list.list.filter((item) => item.caption === stepType && item.width === width && item.minDegrees <= angle && item.maxDegrees >= angle);
			if (possibleSteps.length > 0) {
				// als gevonden dan teruggeven
				return possibleSteps[0];
			} else {
				// Wanneer geen trede voldoet moeten we er toch 1 gebruiken dus dan pakken we de eerste uit de lijst als deze voldoet aan het type en breedte.
				possibleSteps = this._list.list.filter((item) => item.caption === stepType && item.width === width);
				if (possibleSteps.length > 0) {
					return possibleSteps[0];
				}
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return {};
	}
	// Ophalen van alle tredes die voldoen aan
	getItemsBasedOnParameters(stepType = null, angle = null, stepWidth = null) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let allItems = this._list.list;

			// Wanneer stepType opgegeven die selecteren.
			if (stepType !== null) {
				allItems = allItems.filter((item) => item.groupName === stepType);
			}

			// Wanneer angle opgegeven alleen items pakken die mogelijk zijn met de opgegeven angle.
			if (angle !== null) {
				allItems = allItems.filter((item) => item.minDegrees <= angle && item.maxDegrees >= angle);
			}

			// Wanneer stepWidth is opgegeven alleen de item ophalen die gelijk zijn aan opgegeven breedte.
			if (stepWidth !== null) {
				allItems = allItems.filter((item) => item.width === stepWidth);
			}

			return allItems;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			return this.list(null, null, null, null, () => {
				this.getItemsBasedOnParameters(stepType, angle, stepWidth);
			});
		} // geen lijst of niet gevonden dan leeg object
		return [];
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}

		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
