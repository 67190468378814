import { Configuration } from './configuration';
import { IntermediateLanding } from './intermediateLanding';
import { Stair } from './stair';

export class IntermediateLandings {
	objectName = 'IntermediateLandings';
	static ninetyDegrees = 0;
	static oneeightyDegrees = 1;
	static straightAhead = 2;

	intermediateLandings = [];

	get length() {
		return this.get().length;
	}
	get lengthAll() {
		return this.getAll().length;
	}

	clear() {
		this.intermediateLandings = [];
	}
	sort() {
		this.intermediateLandings.sort((a, b) => b.height - a.height);
	}

	addLanding(height, width, depth, upComing, fallingProtectionStandardAdded = false, etageHeightLanding = false, etageId, landingType, finish = null, ctc = null) {
		let newLanding = new IntermediateLanding(height, width, depth, upComing, null, null, null, fallingProtectionStandardAdded, etageHeightLanding, etageId, landingType, finish, ctc);
		this.intermediateLandings.push(newLanding);
		return newLanding;
	}

	minWidthDepth(width, depth) {
		this.intermediateLandings.forEach((il) => {
			// Minimale breedte landings zetten zodat deze niet kleiner kunnen dan nodig.
			if (il.width < width) {
				il.width = width;
			}
			if (il.depth < depth) {
				il.depth = depth;
			}
		});
	}
	// Bij een crossstair dan moeten de positie in hoogte van de landings altijd gelijk zijn, dus tussenruimtes gelijk.
	// ! Uitvoeren bij: Toevoegen landing, verwijderen landing, aanpassen etage hoogte van huidige etage of eronder, switch crossstairwell aan.
	recalculateLandingHeights() {
		// Landings op etage toegevoegd moeten zo blijven.
		// Alleen ophalen van de landings die niet automatisch door etage zijn toegevoegd.
		let notEtageLandings = this.intermediateLandings.filter((iml) => iml.etageHeightLanding === false);

		// Updaten van landings die niet op etage zitten
		let landings;
		let totalHeight = 0;

		Configuration.CURRENT.etages.etages.forEach((etage, index) => {
			landings = notEtageLandings.filter((item) => item.etageId === etage.id);
			totalHeight += etage.getHeight(true);
			landings.forEach((landing, indexLanding) => {
				landing.height = Math.round(totalHeight - (etage.getHeight(true) / (landings.length + 1)) * (indexLanding + 1));
			});
		});
	}
	// Puur voor stairInFloor omdat we daar geen rekening met etages hoeven te houden.
	removeUnnecessaryLandings(minLandings) {
		if (this.intermediateLandings.length > minLandings) {
			let toRemove = [];
			let countAdded = 0;
			this.intermediateLandings.forEach((il, ilIndex) => {
				if (il.fallingProtectionStandardAdded === true && il.etageHeightLanding === false) {
					countAdded++;
				}
				if (countAdded > minLandings && il.fallingProtectionStandardAdded === true) {
					toRemove.push(ilIndex);
				}
			});
			toRemove.forEach((remove, removeIndex) => {
				this.intermediateLandings.splice(remove - removeIndex, 1);
			});
		}
	}
	get(item) {
		if (typeof item !== 'undefined' && item !== null) {
			if (item < this.intermediateLandings.length) {
				return this.intermediateLandings.filter((element) => {
					return element.active === true;
				})[item];
			}
			return null;
		}
		return this.intermediateLandings.filter((element) => {
			return element.active === true;
		});
	}
	getAll(item) {
		// geeft ook inactive mee
		if (typeof item !== 'undefined' && item !== null) {
			if (item < this.intermediateLandings.length) {
				return this.intermediateLandings[item];
			}
			return null;
		}
		return this.intermediateLandings;
	}
	// Functie word vanuit de wizard aangeroepen -> Edit-stair.vue
	// Eigen landing updaten en die erna ook aanroepen
	// Als eigen landing veranderd en die erna daardoor een ander type zou worden.
	setUpComing(item, upComing, stair) {
		this.intermediateLandings.forEach((landing, index) => {
			if (landing.id === item.id) {
				landing.setUpComing(upComing);
				let nextLanding = this.get(index + 1);
				let prevLanding = this.get(index - 1);

				if (stair.crossStairWell === false) {
					let checkUpComing = null;
					if (prevLanding !== null && typeof prevLanding !== 'undefined') {
						checkUpComing = prevLanding.upComing;
					}
					// Bij geen crossstairwell en de eerste landing na de endlanding
					// Dan moeten we vergelijken met de endlanding.
					else if (stair.endLanding.active === true) {
						checkUpComing = stair.endLanding.upComing;
					}
					// Wanneer ook geen endlanding actief is.
					// Dan vergelijk met de trap plek.
					else {
						checkUpComing = stair.upComing;
					}
					landing.setLandingType(upComing, checkUpComing, stair.place, prevLanding, stair.stepWidth);
				} else {
					landing.oneeightyDegreesLanding(stair.stepWidth);
				}

				// Wanneer we een huidige landing updaten en de volgende komt dan anders als landingtype te staan dan moeten we deze ook updaten.
				if (nextLanding !== null && typeof nextLanding !== 'undefined') {
					this.setUpComing(nextLanding, nextLanding.upComing, stair);
				}
			}
		});
	}
	// Komen alleen hier vanuit createstair submit, als landing is aangepast na save dan type updaten. of bij createminimal intermediatelandings
	// Dit is nodig, omdat als we alleen de swich crosssStair aanzetten hij nog niks met de landings doet.
	updateAllLandings(stair) {
		let checkUpComing = stair.upComing;
		this.intermediateLandings.forEach((landing, index) => {
			let prevLanding = this.get(index - 1);
			//als het een trappentoren is dan de bovenkomsten van de landings goed zetten zodat hij 180 graden bordessen krijgt
			if (stair.crossStairWell === true) {
				//iedere even landing gelijk aan de bovenkomst van de trap
				//voor even en oneven starten we met 1 en niet met 0
				if ((index + 1) % 2 == 0) {
					landing.setUpComing(stair.place === Stair.PLACE_INFLOOR ? Stair.toOppositeUpComing(stair.upComing) : stair.upComing);
				} else {
					//alle oneven landings tegenover gesteld
					landing.setUpComing(stair.place === Stair.PLACE_INFLOOR ? stair.upComing : Stair.toOppositeUpComing(stair.upComing));
				}

				// landing is 180 graden eventuele specifacties aanpassen
				landing.oneeightyDegreesLanding();
			} else {
				// als het geen trappentoren is dan laten we de gekozen bovenkomst staan en bereken we alleen het type
				if (typeof prevLanding !== null && typeof prevLanding !== 'undefined') {
					checkUpComing = prevLanding.upComing;
				}
				landing.setLandingType(landing.upComing, checkUpComing, stair.place, prevLanding, stair.stepWidth);
			}
		});

		// Als in de wizard alleen crossstair is aangezet zonder toevoeging van landings of verwijderen daarvan dan moeten we ook landings aanpassen.
		this.recalculateLandingHeights();
	}

	remove(item) {
		let toRemove = -1;
		this.intermediateLandings.forEach((landing, index) => {
			if (landing.id === item.id) toRemove = index;
		});
		if (toRemove > -1) {
			this.intermediateLandings.splice(toRemove, 1);
		}
	}
	getLast(height = null) {
		if (this.intermediateLandings.length > 0) {
			if (height === null) {
				return this.intermediateLandings[this.intermediateLandings.length - 1];
			} else {
				// Opzoeken op basis van de hoogte en dan de vorige terugeven.
				let results = this.intermediateLandings.filter((item) => item.height < height);
				return results[results.length - 1];
			}
		}

		return null;
	}
	getDepth(item, stair, angle) {
		let height = 0;
		if (this.length === 0) {
			// Wanneer geen intermediateLanding dan diepte berekenen op basis van start en eind.
			height = stair.verticalDistance();
		} else if (item === 0) {
			// Eerste item, item === 0 betekent eerste landing vanaf boven gezien.
			// Dan dus vanaf eindHeight tot aan eerstelanding height rekenen.
			// Endhoogte - landingshoogte is het hoogteverschil waarvan we diepte berekenen.
			height = stair.endHeight - this.getAll(item).height;
		} else if (item >= this.length) {
			// Bij laatste landing tot aan de grond,
			if (stair.startHeight > 0) {
				// Wanneer niet tot aan de grond.
				// Hoogte moet zijn: verschil startHoogte tot aan eerste landing.
				height = this.getAll(item - 1).height - stair.startHeight;
			} else {
				// Bij laatste landing dan is het tot aan de grond, dus dan gewoon hoogte van die landing pakken.
				height = this.getAll(item - 1).height;
			}
		} else {
			// Tussen landingen in.
			height = this.getAll(item - 1).height - this.getAll(item).height;
		}

		let depth = height / Math.tan((angle * Math.PI) / 180);
		return depth;
	}
	amountSteps(stair, maxRise) {
		let amounts = 0;
		this.get().forEach((landing, index) => {
			let height = landing.height - stair.startHeight;
			let nextLanding = this.get(index + 1);
			// Wanneer volgende landing er is dan hoogteverschil tussen die 2 is de hoogte van de stepberekening.
			if (typeof nextLanding !== 'undefined' && nextLanding !== null) {
				height = landing.height - nextLanding.height;
			} else {
				// Wanneer geen volgende landing dan tot aan de startHoogte.
				height = landing.height - stair.startHeight;
			}
			landing.amountSteps = Math.ceil(height / maxRise) + 1;
			landing.rise = height / this.amountSteps;
			amounts += landing.amountSteps;
		});
		return amounts;
	}
}
