import { Functions } from '../helpers/functions';

import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { Mathematic } from '../helpers/mathematic';
import { Store } from '../data/store';
import { PalletGateRight3D } from '../draw3d/palletgates/PalletGateRight3D';
import { PalletGateLeft3D } from '../draw3d/palletgates/PalletGateLeft3D';
import { PalletGateTop3D } from '../draw3d/palletgates/PalletGateTop3D';
import { PalletGateBottom3D } from '../draw3d/palletgates/PalletGateBottom3D';

import { Canvas3D } from '../draw3d/Canvas3D';

import { Configuration } from './configuration';
import { PalletGates } from './palletGates';
import { Errors } from './errors';
import { CustomError } from './CustomError';
import { RemoveRaster } from './removeRaster';
import { Asset3D } from '../draw3d/assets/Asset3D';
import { SizeHandle } from '../draw/sizeHandle';
import { Canvas } from '../draw/canvas';

export class PalletGate {
	objectName = 'PalletGate';
	mousePriority = 10;
	active = true;
	selected = false;
	id = '';
	drawObjectId = null;
	_optional = false;
	etageId = null;
	_width = 0;
	_height = 0;
	_drawWidth = 0;
	_type = 0;
	rasters = [];

	get optional() {
		return this._optional;
	}

	set optional(value) {
		if (this._optional !== value) {
			this._optional = value;
			this.onChange();
		}
	}

	get width() {
		return this._width;
	}
	set width(value) {
		if (this._width !== value) {
			this._width = value;
			this.calculate();
		}
	}

	// We gebruiken drawWidth in het tekenen en boundaries.
	get drawWidth() {
		return this._drawWidth;
	}
	set drawWidth(value) {
		this._drawWidth = value;
	}

	get height() {
		return this._height;
	}
	set height(value) {
		if (this._height !== value) {
			this._height = value;
			this.calculate();
		}
	}

	get type() {
		return this._type;
	}
	set type(value) {
		if (this._type !== value) {
			this._type = value;
			this.calculate();
		}
	}
	raster = [];
	newPalletGate = true;
	_palletGate = { id: -1, caption: '', name: '', value: '', width: '', depth: '', height: '' };
	get palletGate() {
		this.palletGate = Store.CURRENT.palletGates.find(this.type, this.width, this.height);
		return this._palletGate;
	}
	set palletGate(value) {
		this._palletGate = value;
	}
	drawObject = '';
	errors = new Errors();
	get hasErrors() {
		return this.errors.length > 0;
	}
	getErrors() {
		return this.errors;
	}
	boundaries = [];
	contextMenu = [
		{ icon: 'edit', action: this.edit.bind(this), active: true },
		{ icon: 'delete', action: this.remove.bind(this), active: true },
	];
	select(parameters) {
		if (this.id === parameters.id) {
			this.selected = !this.selected;
		} else {
			// Wanneer nieuwe object niet dit object is dan is het een deselect.
			this.selected = false;
		}
	}
	getContextMenu() {
		return this.contextMenu;
	}

	onMouseMove(evt, drawObject) {
		Canvas.CURRENT.canvas.style.cursor = 'move';
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		Canvas.CURRENT.canvas.style.cursor = 'default';
		return { stopPropagation: true };
	}
	edit() {
		Configuration.CURRENT.contextMenu.hide();

		if (typeof this._edit === 'function') {
			this._edit(this);
		}
	}
	remove() {
		Configuration.CURRENT.contextMenu.hide();
		if (typeof this._remove === 'function') {
			this._remove(this);
		}
	}
	onChangeMainBeamLength(raster, delta, evt, drawObject) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject) {}
	collisions(boundaries, self) {
		if (this.id === self.id || this.boundaries.length === 0 || this.active === false) {
			// zichzelf dus geen collision
			return { result: false, errors: [] };
		}

		let overlap = false;
		const boundarySelf = this.boundaries[0];
		boundaries.forEach((boundary) => {
			if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, boundarySelf.topLeft, boundarySelf.bottomRight) === true) {
				overlap = true;
			}
		});

		if (overlap === true) {
			return { result: true, errors: [new CustomError(window.Vue.$translate('collision.palletGate'), Errors.ERRORTYPE.collision, this.objectName)] };
		}

		return { result: false, text: [], objectName: this.objectName };
	}

	getEdgePosition() {
		let startX, startY, endX, endY;

		// EIgenlijk wil ik naar dit:
		startX = this.boundaries[0].topLeft.x;
		startY = this.boundaries[0].topLeft.y;
		endX = this.boundaries[0].topRight.x;
		endY = this.boundaries[0].bottomLeft.y;

		return { startX: startX, startY: startY, endX: endX, endY: endY };
	}

	onHandrailPosition(handRailPosition) {
		if (this.boundaries.length === 0 || handRailPosition === null) {
			return false;
		}
		let palletGatePosition = this.getEdgePosition();

		switch (this.position) {
			case Mathematic.TOP:
				return (
					// Eerste check om te kijken of hij wel op deze lijn ligt in de directie van de HR.
					palletGatePosition.startY === handRailPosition.startY.value &&
					// Check 2D -> Daar moet de interupt meegerekend worden, ook als het eindpunt in het volgende raster ligt.
					((palletGatePosition.startX >= handRailPosition.startX.value && palletGatePosition.startX <= handRailPosition.endX.value) ||
						// Deze check is voor 3D, daar is het alleen belangrijk dat hij in de hele range valt op X
						(palletGatePosition.endX <= handRailPosition.endX.value && palletGatePosition.endX >= handRailPosition.startX.value))
				);
			case Mathematic.BOTTOM:
				return (
					// Eerste check om te kijken of hij wel op deze lijn ligt in de directie van de HR.
					palletGatePosition.endY === handRailPosition.endY.value &&
					// Check 2D -> Daar moet de interupt meegerekend worden, ook als het eindpunt in het volgende raster ligt.
					((palletGatePosition.startX >= handRailPosition.startX.value && palletGatePosition.startX <= handRailPosition.endX.value) ||
						// Deze check is voor 3D, daar is het alleen belangrijk dat hij in de hele range valt op X
						(palletGatePosition.endX <= handRailPosition.endX.value && palletGatePosition.endX >= handRailPosition.startX.value))
				);
			case Mathematic.LEFT:
				return (
					// Eerste check om te kijken of hij wel op deze lijn ligt in de directie van de HR.
					palletGatePosition.startX === handRailPosition.startX.value &&
					// Dan kan het dus zijn dat de eind na het eindpunt van de handrail positie ligt, in dat geval wil je ook de collision detecteren.
					((palletGatePosition.startY >= handRailPosition.startY.value && palletGatePosition.startY <= handRailPosition.endY.value) ||
						// Deze check is voor 3D, daar is het alleen belangrijk dat hij in de hele range valt op Y
						(palletGatePosition.endY <= handRailPosition.endY.value && palletGatePosition.endY >= handRailPosition.startY.value))
				);

			case Mathematic.RIGHT:
				return (
					// Eerste check om te kijken of hij wel op deze lijn ligt in de directie van de HR.
					palletGatePosition.endX === handRailPosition.endX.value &&
					// Check 2D -> Dan kan het dus zijn dat de eind na het eindpunt van de handrail positie ligt, in dat geval wil je ook de collision detecteren.
					((palletGatePosition.startY >= handRailPosition.startY.value && palletGatePosition.startY <= handRailPosition.endY.value) ||
						// Deze check is voor 3D, daar is het alleen belangrijk dat hij in de hele range valt op Y
						(palletGatePosition.endY <= handRailPosition.endY.value && palletGatePosition.endY >= handRailPosition.startY.value))
				);
		}
		return false;
	}
	// Functie die in this.rasters opslaat in welke rasters de PG valt.
	insertUniqueRaster(raster) {
		if (raster.x > -1 && raster.y > -1) {
			const rasterFound = this.rasters.find((rst) => rst.x === raster.x && rst.y === raster.y);
			if (typeof rasterFound === 'undefined') {
				this.rasters.push(raster);
			}
		}
	}
	updateRasters() {
		this.rasters = [];
		// Bij updaten rasters moeten we wel goeie raster updaten.
		// Om dat te doen moeten we overhang weer terug rekenen.
		let startX = this.startX;
		let startY = this.startY;
		let width = this.drawWidth;
		let depth = this.depth;
		let overhang = Configuration.CURRENT.overhang.size;

		switch (this.position) {
			case Mathematic.TOP:
				startY += 5;
				startY += overhang;
				width -= 5;

				// LEFTTOP EN RIGHTTOP
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + width, startY));
				break;
			case Mathematic.LEFT:
				startX += 5;
				startX += overhang;
				width = this.depth;
				depth = this.drawWidth - 5;

				// LEFTTOP en LEFTBOTTOM;
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY + depth));
				break;
			case Mathematic.RIGHT:
				startX -= 5; // 5 mm eraf om goed raster te kunnen selecteren
				startX -= overhang;
				width = this.depth;
				depth = this.drawWidth - 5;

				// RIGHTTOP EN RIGHTBOTTOM
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + width, startY));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + width, startY + depth));
				break;
			case Mathematic.BOTTOM:
				startY -= 5; // 5 mm eraf om goed raster te kunnen selecteren
				startY -= overhang;
				width -= 5;

				// LEFTBOTTOM EN RIGHTBOTTOM
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY + depth));
				this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(startX + width, startY + depth));
				break;
		}
	}
	calculate() {
		let heightList = Store.CURRENT.palletGates.getHeightList(this.type);
		if (heightList.length === 1) {
			// maar 1 optie dan geen keuze wordt vast ingesteld. private variabele setten om lus te voorkomen
			this._height = heightList[0].value;
		}

		// Bijwerken van extraWidth
		let extraWidth = PalletGates.additionalWidthList.filter((item) => item.name === this.type);
		if (extraWidth.length === 1) {
			this.drawWidth = this.width + extraWidth[0].amount * 2;
		}
		let width = this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM ? this.drawWidth : this.depth;
		let depth = this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM ? this.depth : this.drawWidth;

		this.boundaries = [
			{
				topLeft: { x: this.startX, y: this.startY },
				topRight: { x: this.startX + width, y: this.startY },
				bottomLeft: { x: this.startX, y: this.startY + depth },
				bottomRight: { x: this.startX + width, y: this.startY + depth },
			},
		];
	}
	onRasterChanged(params) {
		// Bij raster change dan kan het zijn dat hij positie moet updaten.
		// Bijvoorbeeld breedte aanpassing raster etc.
		// Of aanpassing overhang.
		this.updatePosition();
		this.updateRasters();
		this.active = this.checkRastersActive();
		// Updaten van boundaries
		this.calculate();
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._checkCollisions = params.checkCollisions;
		this._edit = params.edit;
		this._remove = params.remove;
		params.rasters = this.rasters;
	}
	removeReferences() {
		this._onChange = null;
		this._checkCollisions = null;
		this._edit = null;
		this._remove = null;
	}
	onChange(status) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	setCollisionDrawObject(drawObjects, collisions) {
		if (collisions === true) {
			drawObjects.lineColor = PalletGates.COLORS.error;
		} else {
			drawObjects.lineColor = drawObjects.objectParams.color;
		}
	}
	getAmount() {
		return {
			configuratorId: this.id,
			id: Store.CURRENT.palletGates.find(this.type, this.width, this.height).id,
			powderCoated: this.powderCoated,
			galvanised: this.galvanised,
			optional: this.optional,
			amount: 1,
		};
	}
	collisionCheck() {
		let collisionCheck = this.checkCollisions(this.boundaries, this);

		const topFloor = Configuration.CURRENT.etages.activeEtageIndex === Configuration.CURRENT.etages.length - 1;
		if (typeof collisionCheck !== 'undefined' && collisionCheck !== null && collisionCheck.result === true) {
			this.errors.clear(Errors.ERRORTYPE.collision);
			collisionCheck.errors.forEach((error) => {
				if ((error.objectName !== 'Column' || topFloor === false) && error.objectName !== 'BraceColumn') {
					this.errors.add(error);
				}
			});
		} else {
			this.errors.clear(Errors.ERRORTYPE.collision);
		}

		return collisionCheck;
	}
	checkCollisions(boundaries, self) {
		if (typeof this._checkCollisions === 'function') {
			return this._checkCollisions(boundaries, self);
		}
	}
	onMouseDrag(evt, drawObject) {
		// Bewaren van minmax vorig drawObject.
		let oldObject = Canvas.CURRENT.drawObjects.findByDrawId(this.drawObjectId);
		let minMaxCoordinates = oldObject.minMax();
		// Bij slepen zichzelf weghalen en opnieuw toevoegen.

		let moveX = evt.delta.x / Canvas.CURRENT.scaleFactor; // verplaatsing links-rechts (links - rechts +)
		let moveY = evt.delta.y / Canvas.CURRENT.scaleFactor; // verplaatsing onder-boven (boven - onder +)

		let minY = 0;
		let minX = 0;
		let maxX = Configuration.CURRENT.raster.spansX.getSize();
		let maxY = Configuration.CURRENT.raster.spansY.getSize();

		// ? Eventueel verplaatsen naar raster.js omdat hole dezelfde logica nodig heeft.
		// min en max berekenen op basis van raster ernaast actief etc.
		if (this.rasters.length > 0) {
			let xRaster = this.rasters[0].x;
			let yRaster = this.rasters[0].y;
			switch (this.position) {
				case Mathematic.TOP:
					let findRasterLeft = -1;

					if (xRaster > 0) {
						let activeFound = false;
						for (let i = xRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster - 1)) === true) {
								findRasterLeft = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterLeft = i;
								activeFound = true;
							}
						}

						let maxRasterX = Configuration.CURRENT.raster.spansX.length;
						let findRasterRight = maxRasterX;

						activeFound = false;

						for (let i = xRaster + 1; i <= maxRasterX && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster - 1)) === true) {
								findRasterRight = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterRight = i;
								activeFound = true;
							}
						}

						minX = Configuration.CURRENT.raster.spansX.getSize(findRasterLeft);
						maxX = Configuration.CURRENT.raster.spansX.getSize(findRasterRight - 1);
					}

					break;
				case Mathematic.BOTTOM:
					findRasterLeft = -1;

					if (xRaster > 0) {
						let activeFound = false;
						for (let i = xRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster + 1)) === true) {
								findRasterLeft = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterLeft = i;
								activeFound = true;
							}
						}
					}

					let maxRasterX = Configuration.CURRENT.raster.spansX.length;
					let findRasterRight = maxRasterX;
					if (xRaster < maxRasterX) {
						let activeFound = false;

						for (let i = xRaster + 1; i <= maxRasterX && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster + 1)) === true) {
								findRasterRight = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterRight = i;
								activeFound = true;
							}
						}
					}
					minX = Configuration.CURRENT.raster.spansX.getSize(findRasterLeft);
					maxX = Configuration.CURRENT.raster.spansX.getSize(findRasterRight - 1);

					break;
				case Mathematic.LEFT:
					let findRasterTop = -1;

					if (yRaster > 0) {
						let activeFound = false;
						for (let i = yRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster - 1, i)) === true) {
								findRasterTop = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterTop = i;
								activeFound = true;
							}
						}
					}

					let maxRasterY = Configuration.CURRENT.raster.spansY.length;
					let findRasterBottom = maxRasterY;
					if (yRaster < maxRasterY) {
						let activeFound = false;

						for (let i = yRaster + 1; i <= maxRasterY && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster - 1, i)) === true) {
								findRasterBottom = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterBottom = i;
								activeFound = true;
							}
						}
					}
					minY = Configuration.CURRENT.raster.spansY.getSize(findRasterTop);
					maxY = Configuration.CURRENT.raster.spansY.getSize(findRasterBottom - 1);

					break;
				case Mathematic.RIGHT:
					findRasterTop = -1;

					if (yRaster > 0) {
						let activeFound = false;
						for (let i = yRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster + 1, i)) === true) {
								findRasterTop = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterTop = i;
								activeFound = true;
							}
						}
					}

					maxRasterY = Configuration.CURRENT.raster.spansY.length;
					findRasterBottom = maxRasterY;
					if (yRaster < maxRasterY) {
						let activeFound = false;

						for (let i = yRaster + 1; i <= maxRasterY && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster + 1, i)) === true) {
								findRasterBottom = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterBottom = i;
								activeFound = true;
							}
						}
					}
					minY = Configuration.CURRENT.raster.spansY.getSize(findRasterTop);
					maxY = Configuration.CURRENT.raster.spansY.getSize(findRasterBottom - 1);

					break;
			}
		}

		if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			if (minMaxCoordinates.min.y + moveY < minY) {
				// als x kleiner dan 0 dan tot aan 0
				moveY = minY - minMaxCoordinates.min.y;
				evt.delta.y = moveY * Canvas.CURRENT.scaleFactor;
			}

			if (minMaxCoordinates.max.y + moveY > maxY) {
				// als x groter dan totale lengte dan tot aan totale lengte
				moveY = maxY - minMaxCoordinates.max.y;
				evt.delta.y = moveY * Canvas.CURRENT.scaleFactor;
			}
			this.startY += moveY;
		} else if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			if (minMaxCoordinates.min.x + moveX < minX) {
				// als x kleiner dan 0 dan tot aan 0
				moveX = minX - minMaxCoordinates.min.x;
				evt.delta.x = moveX * Canvas.CURRENT.scaleFactor;
			}

			if (minMaxCoordinates.max.x + moveX > maxX) {
				// als x groter dan totale lengte dan tot aan totale lengte
				moveX = maxX - minMaxCoordinates.max.x;
				evt.delta.x = moveX * Canvas.CURRENT.scaleFactor;
			}
			this.startX += moveX;
		}

		// Remov drawObject and add it again.
		Canvas.CURRENT.drawObjects.clearByDrawId(this.drawObjectId);
		this.addDrawObjects(true);

		return { stopPropagation: true };
	}
	onMouseUp(evt, drawObject) {
		this.updateRasters();
		this.calculate();
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseDown(evt, drawObject) {
		return { stopPropagation: true };
	}
	onClick(evt, drawObject) {
		Configuration.CURRENT.select({ id: drawObject.object.id });
		return { stopPropagation: true, object: this };
	}
	update(newPalletGateConfiguration) {
		this.newPalletGate = newPalletGateConfiguration.newPalletGate;
		this.width = newPalletGateConfiguration.width;
		this.height = newPalletGateConfiguration.height;
		this.depth = 1200;
		this.type = newPalletGateConfiguration.type;
		this.optional = newPalletGateConfiguration.optional;
		this.etageId = newPalletGateConfiguration.etageId;

		// 1x uitvoeren van calculate om goed te berekenen.
		this.calculate();
	}
	constructor(newPalletGateConfiguration, raster) {
		this.id = Functions.uuidv4();
		if (typeof newPalletGateConfiguration !== 'undefined' && newPalletGateConfiguration !== null) {
			// Bij nieuwe PG waardes zetten. Gebeurd wanneer popup word geopend om een nieuwe te maken.
			this.update(newPalletGateConfiguration);
		}
		if (typeof raster !== 'undefined' && raster !== null) {
			// Na kiezen plek / raster positie bepalen op geklikte raster.
			this.setPositionNewPalletGate(raster);
			this.calculate();
		}
	}
	afterReconstruct() {
		this.contextMenu[0].action = this.edit.bind(this);
		this.contextMenu[1].action = this.remove.bind(this);
	}

	// Bij nieuwe palletGate positie setten, in het midden van gekozen raster.
	setPositionNewPalletGate(raster) {
		// Opslaan waar hij staat in het raster en opslaan welk raster hij zit.
		this.position = raster.position;
		this.insertUniqueRaster({
			x: raster.x,
			y: raster.y,
		});

		let width;
		let depth;

		if (this.position === Mathematic.BOTTOM || this.position === Mathematic.TOP) {
			width = this.drawWidth;
			depth = this.depth;
		} else {
			width = this.depth;
			depth = this.drawWidth;
		}

		const rasterStartX = Configuration.CURRENT.raster.getSizeX(raster.x - 1);
		const rasterStartY = Configuration.CURRENT.raster.getSizeY(raster.y - 1);
		const lengthRasterX = Configuration.CURRENT.raster.spansX.get(raster.x).value;
		const lengthRasterY = Configuration.CURRENT.raster.spansY.get(raster.y).value;
		let overhang = Configuration.CURRENT.overhang.size;

		if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			this.startX = rasterStartX + Math.floor((lengthRasterX - width) / 2);

			// Bij bovenkant van raster startpunt raster
			if (this.position === Mathematic.TOP) {
				this.startY = rasterStartY;
				this.startY -= overhang;
			}
			// Bij onderkant raster, startraster + diepte van het raster min eigen diepte.
			if (this.position === Mathematic.BOTTOM) {
				this.startY = rasterStartY + lengthRasterY - this.depth;
				this.startY += overhang;
			}
		} else if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			this.startY = rasterStartY + Math.floor((lengthRasterY - depth) / 2);
			// Bij linkerkant van raster startPositie begin raster.
			if (this.position === Mathematic.LEFT) {
				this.startX = rasterStartX;
				this.startX -= overhang;
			}

			// Bij rechterkant raster dan startpunt eindraster min eigen diepte.
			if (this.position === Mathematic.RIGHT) {
				this.startX = rasterStartX + lengthRasterX - this.depth;
				this.startX += overhang;
			}
		}
	}

	// Update positie na rasterChanged op basis van eigen opgeslagen raster.
	updatePosition() {
		let raster = this.getCorrectRaster();
		if (raster === null || typeof raster === 'undefined') {
			return;
		}

		const rasterStartX = Configuration.CURRENT.raster.getSizeX(raster.x - 1);
		const rasterEndX = Configuration.CURRENT.raster.getSizeX(raster.x);
		const rasterStartY = Configuration.CURRENT.raster.getSizeY(raster.y - 1);
		const rasterEndY = Configuration.CURRENT.raster.getSizeY(raster.y);
		const totalLengthRasterX = Configuration.CURRENT.raster.getSizeX();
		const totalLengthRasterY = Configuration.CURRENT.raster.getSizeY();

		let overhang = Configuration.CURRENT.overhang.size;

		// Wanneer PG startX + breedte meer is dan totale vloer breedte.
		// Dan begin punt is eindpunt rasters - eigen breedte.
		// OF
		// Wanneer gekozen raster kleiner is dan de PG width,
		// Dan updaten we naar maximale - eigen breedte
		// Dan moet dat ook in 1 raster vallen, anders gaat hij opschuiven als er een raster kleiner is dan zijn breedte waar hij invalt.
		if (this.startX + this.drawWidth > totalLengthRasterX || (totalLengthRasterX < rasterStartX + this.drawWidth && this.rasters.length === 1)) {
			this.startX = totalLengthRasterX - this.drawWidth;
		}
		// Wanneer PG startY + diepte groter is dan de totale vloer diepte.
		// OF
		// Wanneer gekozen raster kleiner is dan de PG width,
		// Dan updaten we naar maximale - eigen breedte
		// Dan moet dat ook in 1 raster vallen, anders gaat hij opschuiven als er een raster kleiner is dan zijn breedte waar hij invalt.
		if (this.startY + this.drawWidth > totalLengthRasterY || (totalLengthRasterY < rasterStartY + this.drawWidth && this.rasters.length === 1)) {
			this.startY = totalLengthRasterY - this.drawWidth;
		}

		// Bij normale rasterchanged of verandering van overhang dan updaten naar nieuwe positie als het een top of bottom is.
		if (this.position === Mathematic.TOP) {
			this.startY = rasterStartY;
		}
		if (this.position === Mathematic.BOTTOM) {
			this.startY = rasterEndY - this.depth;
		}
		if (this.position === Mathematic.LEFT) {
			this.startX = rasterStartX;
		}
		if (this.position === Mathematic.RIGHT) {
			this.startX = rasterEndX - this.depth;
		}

		// Overhang bij positie updaten.
		// Bij updaten van rasters halen we die er dan juist weer af om goed de rasters te updaten.
		switch (this.position) {
			case Mathematic.TOP:
				this.startY -= overhang;
				break;
			case Mathematic.BOTTOM:
				this.startY += overhang;
				break;
			case Mathematic.LEFT:
				this.startX -= overhang;
				break;
			case Mathematic.RIGHT:
				this.startX += overhang;
				break;
		}
	}
	addDrawObjects3d(canvas3d, etage, raster, posY, activeEtageIndex) {
		let etageHeight = etage.getHeight(true);
		let assetName = this.type.toLowerCase().replaceAll(' ', '_') + '_' + 'w' + this.width + '_' + 'h' + this.height;

		let ralColor = Configuration.CURRENT.colors.palletGates.ralColor;
		// Ralkleur van tiltgate is appart in te stellen, dus als het om een tiltgate gaat dan die kleur ophalen vanuit colors.
		if (this.type.toLowerCase().replaceAll(' ', '_') === 'tilt_gate') {
			ralColor = Configuration.CURRENT.colors.tiltGates.ralColor;
		}

		let paramsObject = {
			yPosition: posY + etageHeight,
			info: this,
			assetName: assetName,
			ralColor: ralColor,
		};

		switch (this.position) {
			case Mathematic.TOP:
				Canvas3D.CURRENT.addDrawObject(new PalletGateTop3D(this.startX, this.startY, paramsObject), Canvas3D.TYPE_PALLETGATE);
				break;
			case Mathematic.BOTTOM:
				Canvas3D.CURRENT.addDrawObject(new PalletGateBottom3D(this.startX, this.startY, paramsObject), Canvas3D.TYPE_PALLETGATE);
				break;
			case Mathematic.LEFT:
				Canvas3D.CURRENT.addDrawObject(new PalletGateLeft3D(this.startX, this.startY, paramsObject), Canvas3D.TYPE_PALLETGATE);
				break;
			case Mathematic.RIGHT:
				Canvas3D.CURRENT.addDrawObject(new PalletGateRight3D(this.startX, this.startY, paramsObject), Canvas3D.TYPE_PALLETGATE);
				break;
		}
	}
	create3DAssets(canvas3D) {
		let assetName = this.type.toLowerCase().replaceAll(' ', '_') + '_' + 'w' + this.width + '_' + 'h' + this.height;
		let oid = Store.CURRENT.palletGates.find(this.type, this.width, this.height).palletgateId;
		Canvas3D.CURRENT.addAsset(new Asset3D(assetName, oid));
	}
	addDrawObjects(drag = false) {
		const color = this.hasErrors === true ? PalletGates.COLORS.error : this.selected === true ? PalletGates.COLORS.selected : PalletGates.COLORS.palletGate;
		let width = null;
		let depth = null;

		width = new DrawValue(this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM ? this.drawWidth : this.depth);
		depth = new DrawValue(this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM ? this.depth : this.drawWidth);

		if (this.selected) {
			if (drag) {
				Configuration.CURRENT.dimensioning.updateDrawObjects(this.startX, this.startY, width.value, depth.value, SizeHandle.TYPE_OBJECT);
			} else {
				Configuration.CURRENT.dimensioning.setSizeHandleObject(this, SizeHandle.TYPE_OBJECT, false, this.startX, this.startY, width.value, depth.value);
			}
		}

		let palletGate = new Rectangle(
			new DrawValue(this.startX),
			new DrawValue(this.startY),
			width,
			depth,
			color,
			'transparent',
			null,
			true,
			this,
			{
				type: 'palletgate',
			},
			false,
			true,
		);
		this.drawObjectId = palletGate.id;
		Canvas.CURRENT.addDrawObject(palletGate);

		// Teruggeven naar mouse drag.
		return palletGate;
	}
	getCorrectRaster() {
		// in deze functie pakken we op basis van de positie van de PG het raster wat nodig is om te checken.
		// Bij top is dit het raster met de kleinste Y.
		// Bij bottom is dit het raster met de grootste Y.
		// Bij left is dit het raster met de kleinste X.
		// Bij right is dit het raster met de grootste X.
		let correctRaster = null;

		// Bij eerste keer nieuwe PG dan kan het zijn dat rasters nog niet berekend zijn.
		if (this.rasters !== null && typeof this.rasters !== 'undefined') {
			// Wanneer hele rij verwijderd wordt, voorkomen dat er een exception voorkomt
			if (this.rasters.length === 0) return null;

			switch (this.position) {
				case Mathematic.TOP:
					correctRaster = this.rasters.reduce(function (prev, current) {
						return prev.y < current.y ? prev : current;
					});
					break;
				case Mathematic.BOTTOM:
					correctRaster = this.rasters.reduce(function (prev, current) {
						return prev.y > current.y ? prev : current;
					});
					break;
				case Mathematic.LEFT:
					correctRaster = this.rasters.reduce(function (prev, current) {
						return prev.x < current.x ? prev : current;
					});
					break;
				case Mathematic.RIGHT:
					correctRaster = this.rasters.reduce(function (prev, current) {
						return prev.x > current.x ? prev : current;
					});
					break;
			}
		}
		return correctRaster;
	}
	checkRastersActive() {
		// Vanaf bepaald moment is etageId toegevoegd aan PG.
		// Als dat niet bestaat omdat het een oudere configuratie is dan houden we nog even de oude etage aan wat activeEtageIndex is.
		let etage;
		if (this.etageId !== null && typeof this.etageId !== 'undefined') {
			etage = Configuration.CURRENT.etages.getById(this.etageId);
		} else {
			// Als we geen etageId hebben dan kan het zijn dat dit een oude configuratie is en etageId niet beschikbaar is, om errors te voorkomen dan even huidige etage gebruiken.
			// Updaten van het etageId gebeurd wel zodra er dan een rasterchanged is.
			etage = Configuration.CURRENT.etages.activeEtage();
		}

		// Het kan voorkomen dat een raster erg klein is en dan komt de palletgate daar overheen te liggen.
		// Als we alle rasters zouden checken waar de PG contact mee maakt dan is het raster onder dat raster actief en springt de PG op inactive.
		// Terwijl dit wel als een mogelijke optie behoord. (Zie issue 850 -> Configurator -> Git)
		// Ook altijd eigen raster checken die geselecteerd word.

		let active = true;
		let checkRaster = this.getCorrectRaster();

		// wanneer er geen rasters zijn return active false
		if (checkRaster === null) return false;

		switch (this.position) {
			case Mathematic.TOP:
				// Als huidige raster niet actief, of raster erboven actief is dan is active false.
				// Of als het huidige raster qua diepte kleiner is dan de diepte van de PG , dan ook checken of raster eronder actief is.
				if (
					etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y - 1)) === true ||
					etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y)) === false ||
					(Configuration.CURRENT.raster.getSizeY(checkRaster.y) - Configuration.CURRENT.raster.getSizeY(checkRaster.y - 1) < this.depth &&
						etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y + 1)) === false)
				) {
					active = false;
				}
				break;
			case Mathematic.BOTTOM:
				// Als huidige raster niet actief, of raster eronder actief is dan is active false.
				// Of als het huidige raster qua diepte kleiner is dan de diepte van de PG , dan ook checken of raster erboven actief is.
				if (
					etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y + 1)) === true ||
					etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y)) === false ||
					(Configuration.CURRENT.raster.getSizeY(checkRaster.y) - Configuration.CURRENT.raster.getSizeY(checkRaster.y - 1) < this.depth &&
						etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y - 1)) === false)
				) {
					active = false;
				}
				break;
			case Mathematic.LEFT:
				// Als huidige raster niet actief, of raster links actief is dan is active false.
				// Of als het huidige raster qua diepte kleiner is dan de diepte van de PG , dan ook checken of raster links rechts is.
				if (
					etage.isActiveRaster(new RemoveRaster(checkRaster.x - 1, checkRaster.y)) === true ||
					etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y)) === false ||
					(Configuration.CURRENT.raster.getSizeX(checkRaster.x) - Configuration.CURRENT.raster.getSizeX(checkRaster.x - 1) < this.depth &&
						etage.isActiveRaster(new RemoveRaster(checkRaster.x + 1, checkRaster.y)) === false)
				) {
					active = false;
				}
				break;
			case Mathematic.RIGHT:
				// Als huidige raster niet actief, of raster rechts actief is dan is active false.
				// Of als het huidige raster qua diepte kleiner is dan de diepte van de PG , dan ook checken of raster links actief is.
				if (
					etage.isActiveRaster(new RemoveRaster(checkRaster.x + 1, checkRaster.y)) === true ||
					etage.isActiveRaster(new RemoveRaster(checkRaster.x, checkRaster.y)) === false ||
					(Configuration.CURRENT.raster.getSizeX(checkRaster.x) - Configuration.CURRENT.raster.getSizeX(checkRaster.x - 1) < this.depth &&
						etage.isActiveRaster(new RemoveRaster(checkRaster.x - 1, checkRaster.y)) === false)
				) {
					active = false;
				}
				break;
		}
		return active;
	}
	onSizeHandleChangedHorizontal(evt, drawObject, newDimensions, changedDimensionIndex) {
		if (changedDimensionIndex === 0) {
			this.startX = newDimensions[changedDimensionIndex];
		} else {
			this.startX = Configuration.CURRENT.etages.activeEtage().floor.width - newDimensions[changedDimensionIndex] - this.drawWidth;
		}
		this.onChange();
	}
	onSizeHandleChangedVertical(evt, drawObject, newDimensions, changedDimensionIndex) {
		if (changedDimensionIndex === 0) {
			this.startY = newDimensions[changedDimensionIndex];
		} else {
			this.startY = Configuration.CURRENT.etages.activeEtage().floor.length - newDimensions[changedDimensionIndex] - this.drawWidth;
		}
		this.onChange();
	}
}
