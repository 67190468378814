<template>
	<div
		:translationsLoaded="translations.translationsLoaded"
		@click="
			() => {
				if (showProfilePopup) {
					closeProfilePopup();
				}
				if (showLanguageMenu) {
					showLanguageMenu = false;
				}
			}
		"
	>
		<div class="header">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="menu">
							<ul>
								<li v-for="(item, index) in pages" :key="index" :class="{ active: activePage === index }" v-if="pageAllowed(item)" @click="updateMenu(index, item.path)">{{ $translate(item.title)  }}</li>
							</ul>
						</div>
						<div class="userMenu">
							<div v-show="showProfilePopup" class="profilepopup">
								<div class="picture">
									<span class="material-icons picture"> person </span>

									<span class="name"> {{ user.fullName }}</span>
									<span class="function"> {{ user.function }}</span>
									<br />
								</div>
								<div class="userinfo" v-show="user.phone !== null && user.mobile !== null && user.email !== null">
									<span class="phone"> {{ user.phone }} </span> <span v-show="user.phone !== null && user.mobile !== null"> || </span> <span class="mobile"> {{ user.mobile }}</span>
									<br />
									<span class="email">{{ user.email }} </span>
								</div>

								<div class="useractions">
									<div
										@click="
											goEditPassword(false);
											closeProfilePopup();
										"
									>
										<span class="material-icons icon"> vpn_key </span> <span class="text">{{ $translate('newpassword.editpassword') }} </span>
									</div>

									<div @click="user.logout()">
										<span class="material-icons icon"> logout </span> <span class="text">{{ $translate('auth.logout') }} </span>
									</div>
								</div>
							</div>
							<div class="userName" @click.stop="toggleProfilePopup()">{{ user.showName }}</div>
							<img class="languageImage" :src="Data.server + languages.getCurrentLanguageItem().flag" @click.stop="showLanguageMenu = !showLanguageMenu" />
							<i class="material-icons" @click="user.logout()">exit_to_app</i>
						</div>

						<div v-if="showLanguageMenu === true" class="languageMenu">
							<div v-for="(language, index) in languages.list()" :key="index" class="item" @click="setLanguage(language.code)">
								<img :src="Data.server + language.flag" />
								{{ language.name }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<changePassword :user="user" :visible="passwordPopup.visible" :forced="false" @closePopup="closeChangePasswordPopup()"> </changePassword>
		</div>

		<div class="bg">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="content">
							<nuxt />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { User } from '~/data/user';
import { Languages } from '~/data/languages';
import { Data } from '../data/data';
import tabModal from '~/components/tab-modal.vue';
import changePassword from '~/components/changePassword';
import { Translations } from '../data/translations';

export default {
	components: {
		tabModal,
		changePassword,
	},
	computed: {
		Data() {
			return Data;
		},
	},
	provide() {
		return {
			inputFields: this.inputFields,
		};
	},
	data: function () {
		return {
			activePage: 0,
			user: new User(),
			languages: new Languages(),
			translations: Translations.CURRENT,
			showLanguageMenu: false,
			showProfilePopup: false,
			passwordPopup: {
				visible: false,
			},
			oldPassword: '',
			newPasswordOne: '',
			newPasswordTwo: '',
			errorText: '',
			activeTab: {},
			inputFields: {},
			tabs: {},
			pages: [],
			pagesCreated: false,
			pagesCount: 0,
		};
	},
	watch: {
		tabs: {
			handler() {
				const activeTabs = Object.values(this.tabs).filter((item) => item.active === true);
				if (activeTabs.length > 0) {
					this.activeTab = activeTabs[0];
				}
			},
			deep: true,
		},
		user: {
			handler(n, o) {			
				if (!this.pagesCreated) {
					this.createPages();
					
					this.pagesCount = this.pages.length;
				}
			

				// Wanneer het wachtwoord is verlopen gebruiker forceren uit te loggen en in te loggen
				if (n.passwordExpired) {
					this.user.logout();
				}
			},
			deep: true,
		},
	},
	transition: {
		name: 'page',
	},
	created() {
		if (this.pages.length <= this.pagesCount) {
			this.createPages();
		}
		localStorage.removeItem('activeConfigId');
	},
	methods: {
		clearPages() {
			this.pages = [];
		},
		pageAllowed(item){
			return this.user.portalPages.includes(item.path);
			

		},
		createPages() {		
			this.clearPages();
			this.pages.push(
				{
					id: 0,
					title: 'dashboard.dashboard.title',
					path: '/dashboard',
					
					
				},
				{
					id: 1,
					title: 'dashboard.configurations.title',
					path: '/configurations',
					
				},
				{
					id: 2,
					title: 'dashboard.quotations.title',
					path: '/quotations',
					
				},
				{
					id: 3,
					title: 'dashboard.projects.title',
					path: '/projects',
					
				},
				{
					id: 4,
					title: 'dashboard.vrviewer.title',
					path: '/vr-viewer',
					
				},
				{
					id: 5,
					title: 'dashboard.transport.title',
					path: '/transport',

				},
	
			);
			this.pagesCreated = true;
		},
		goto(loc) {
			this.$router.push({ path: loc });
		},
		setLanguage(language) {
			this.languages.updateLanguageByUser(language);
			this.showLanguageMenu = false;
		},
		updateMenu(page, path) {
			this.$router.push(path);
			this.activePage = page;
		},
		toggleProfilePopup() {
			this.showProfilePopup = !this.showProfilePopup;
		},
		closeProfilePopup() {
			this.showProfilePopup = false;
		},
		closeChangePasswordPopup() {
			this.passwordPopup.visible = false;
		},
		goEditPassword(forced) {
			this.passwordPopup.visible = true;
		},
	},
};
</script>

<style lang="less" scoped>
body {
	font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.fieldsPw {
	left: 20px;
	position: relative;
	color: #f00;
	font-size: 13px;
}

.profilepopup {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	z-index: 20;
	width: 250px;
	background: #fff;
	border: 1px solid #999;
	border-radius: 5px;
	cursor: default;
	position: relative;
	right: 25px;
	top: -20px;

	&::after {
		content: '';
		width: 30px;
		height: 30px;
		position: absolute;
		top: 18px;
		right: -16px;
		background: #fff;
		transform: rotate(45deg);
		border-right: 1px solid #999;
		border-top: 1px solid #999;
		border-radius: 2px;
	}

	.picture {
		span.picture {
			font-size: 70px;
			border: 1px solid #ccc;
			border-radius: 100%;
			color: #ccc;
			margin: 15px auto 15px auto;
			padding: 15px;
			display: table;
		}

		span.name {
			color: #000;
			font-weight: 600;
			position: relative;
			width: 100%;
			float: left;
		}

		span.function {
			position: relative;
			width: 100%;
			float: left;
			margin-bottom: 15px;
			color: #000;
		}

		margin: 0 auto;
		text-align: center;
	}

	.userinfo {
		color: #999;
		text-align: center;
		font-size: 13px;
		margin-bottom: 15px;
		padding: 0 10px;
		word-break: break-all;
		overflow: hidden;

		span.phone,
		span.mobile {
			font-style: 'italic';
		}
	}

	.useractions {
		border-top: 1px solid #999;

		:not(:first-child) {
			padding: 0px 5px 10px 5px;
		}

		div {
			color: #000;
			width: 100%;
			padding: 10px 5px;
			cursor: pointer;

			span.icon {
				color: #999;
				border: 1px solid #999;
				font-weight: 300;
				border-radius: 3px;
				padding: 5px;
			}

			span.text {
				position: relative;
				left: 25px;
				top: -5px;
			}
		}
	}
}

.userMenu {
	display: flex;
	position: relative;
	float: left;
	align-items: center;
	color: #fff;
	float: right;
	padding: 40px 0;
	align-items: flex-start;
}

.userMenu:hover {
	cursor: pointer;
}

.userName {
	position: relative;
	margin-right: 10px;
	display: inline-block;
}

.languageMenu {
	&::before {
		content: '';
		width: 30px;
		height: 30px;
		position: absolute;
		top: -15px;
		right: 38px;
		background: #fff;
		transform: rotate(45deg);

		border-left: 1px solid #999;
		border-top: 1px solid #999;

		border-radius: 2px;
	}

	position: absolute;
	right: 5px;
	top: 80px;
	background: #fff;
	border: 1px solid #999;
	border-radius: 3px;

	img {
		width: 10px;
		height: 10px;
		margin-right: 10px;
	}

	.item {
		padding: 10px 20px;
	}

	.item:hover {
		cursor: pointer;
	}

	z-index: 100;
}

.languageImage {
	height: 20px;
	width: 20px;
	margin-right: 10px;
	vertical-align: initial;
}

.header {
	position: relative;
	width: 100%;
	height: 200px;
	background-color: #fdc52a;

	.menu {
		position: relative;
		float: left;

		ul {
			position: relative;
			float: left;
			padding: 0;
			margin: 0;
			width: 100%;
			height: 100px;

			li {
				position: relative;
				float: left;
				list-style-type: none;
				padding: 25px 20px;

				height: 100px;
				text-align: center;
				display: block;
				color: #fff;
				cursor: pointer;
				line-height: 50px;
				font-weight: 600;
				font-family: 'Lato', Helvetica, Arial, sans-serif;
				font-size: 16px;

				&:hover {
					background-color: #fed45f;
				}

				&.active {
					&::after {
						display: block;
						opacity: 1;
						-webkit-animation: fadein 1s;
						/* Safari, Chrome and Opera > 12.1 */
						-moz-animation: fadein 1s;
						/* Firefox < 16 */
						-ms-animation: fadein 1s;
						/* Internet Explorer */
						-o-animation: fadein 1s;
						/* Opera < 12.1 */
						animation: fadein 1s;
					}
				}

				&::after {
					content: '';
					width: 15px;
					height: 20px;
					position: absolute;
					bottom: -13px;
					background-color: #fff;
					-webkit-transform: rotate(20deg);
					transform: rotate(45deg);
					left: calc(50% - 10px);
					opacity: 0;
				}
			}
		}
	}
}

.content {
	position: relative;
	top: -100px;
	width: 100%;
	float: left;

	background-color: #fff;
	padding: 20px;
	padding-bottom: 0;
	.blocks {
		position: relative;
		float: left;
		width: 100%;
		min-height: 500px;
	}
}

.col-md-12:has(> .content) {
	max-height: 0px;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
</style>
