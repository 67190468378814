import { Data } from './data';
import { Quotation } from './quotation';
import { Store } from './store';
import { Languages } from './languages';

export class Quotations extends Data {
	objectName = 'Quotations';

	url = 'Quotations';
	_activeId = -1;
	_activeQuotation = new Quotation();
	quotationSearchList = [];
	acceptedQuotations = [];
	_calculations = [];

	searchQuotations(text, amount, thenCallBack, designOnly = false) {
		// designOnly, als true dan alleen offertes opzoeken die nog open zijn en niet aangevraagd
		let lastRequest = this.getLastRequest('list'); // ophalen laatste request
		let request = JSON.parse(JSON.stringify(lastRequest)); // ombouwen naar een zelfstandig object
		if (typeof request.data === 'undefined' || request.data === null) {
			request = { data: {} };
		}
		if (text === '' || text === null || typeof text === 'undefined') {
			// Als zoektekst leeg is gewoon 20 ophalen
			request.data.top = amount;
			request.data.company = this.store.companies.selectedCompany.id;
		} else {
			// Anders laatste request parameters gebruiken en text als zoekveld toevoegen.
			request.data.searchText = text; // zoektekst toevoegen
		}

		super.list(
			request.data,
			true,
			-1,
			null,
			(response) => {
				this.quotationSearchList = response;
				if (typeof thenCallBack === 'function') {
					thenCallBack(this.quotationSearchList);
				}
			},
			(exception) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack();
				}
			},
		);
	}

	get activeId() {
		return this._activeId;
	}
	set activeId(value) {
		this._activeId = value;
		if (value === -1) {
			this.activeQuotation.fetch(); // leegmaken
		}
		this.activeQuotation = value;
		this.saveToLocalStorage();
	}
	get calculations() {
		return this._calculations;
	}

	set calculations(calculations) {
		this._calculations = calculations;
	}

	fetchListItem(item) {
		let returnItem = new Quotation();
		returnItem.setStore(this.store);
		returnItem.fetch(item);

		return returnItem;
	}
	get activeQuotation() {
		return this._activeQuotation;
	}
	set activeQuotation(quotationId) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let found = null;
			// doorloop de lijst om te kijken of er een configuratie is met betreffend id
			this._list.list.forEach((quotation) => {
				if (quotation.id === quotationId) {
					found = quotation; // bewaar deze
				}
			});
			if (found !== null) {
				// als config gevonden dan teruggeven
				this._activeQuotation = found;
			}
		}
		this.saveToLocalStorage();
		// anders default
		return { id: -1 };
	}
	setSelectedValue(id, thenCallBack) {
		// gegevens van company ophalen
		this.activeQuotation.setStore(this.store);
		this.activeQuotation.getById(id, true, (response) => {
			this._activeQuotation.id = response.id;
			this.activeQuotation.quotationNumber = response.quotationNumber;
			this.activeQuotation.company = response.company;
			this.activeQuotation.readOnly = response.readOnly;
			Store.CURRENT.companies.setSelectedValue(response.company, () => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(response.readOnly);
				}
			});
		});
		// objecten informeren dat company is gewijzigd
		this.saveToLocalStorage();
	}
	clearActiveQuotation() {
		this.activeQuotation = new Quotation();
		this.activeQuotation.setStore(this.store);
	}
	setStore(store) {
		super.setStore(store);

		// registreren dat als de company gewijzigd is ook de offertes opgehaald worden die erbij horen
		store.companies.addOnChangeSelectedCompany(() => {
			for (let key in this.lastRequest) {
				let lastRequest = JSON.parse(JSON.stringify(this.lastRequest[key]));
				lastRequest.data.company = this.store.companies.selectedCompany.id;

				this.queue.push({
					data: lastRequest.data,
					force: false,
					cacheId: key,
					urlExtension: lastRequest.urlExtension,
					thenCallBack: this.lastRequest[key].thenCallBack, // functies gaan niet mee met parse en stringify
					catchCallBack: this.lastRequest[key].catchCallBack, // functies gaan niet mee met parse en stringify
				});
			}
			this.executeQueue();
		});
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		// als nog geen company gekozen dan ook geen offertes laten zien
		if (this.store.companies.selectedCompany.id === -1) {
			return;
		}

		// company meegeven met request
		data.company = this.store.companies.selectedCompany.id;
		data.language = Languages.CURRENT.currentLanguage;

		let list = super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
		return list;
	}
	getAcceptedQuotations(top) {
		const url = Data.baseUrl + this.url + '/getAcceptedQuotations?top=' + top;
		this.get(
			url,
			false,
			(res) => {
				if (typeof res !== 'undefined' && res !== null) {
					this.acceptedQuotations = res;
				}
			},
			(err) => {
				console.log(err);
			},
			'-1',
		);
	}
}
