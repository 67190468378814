/* eslint-disable no-new */
import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { ObjectGroup } from '../draw/objectGroup';
import { Line } from '../draw/line';
import { Mathematic } from '../helpers/mathematic';

import { Canvas3D } from '../draw3d/Canvas3D';
import { Stair3D } from '../draw3d/stairs/Stair3D';
import { StairInFloorProfiles3D } from '../draw3d/stairs/profiles/StairInFloorProfiles3D';
import { Landing3D } from '../draw3d/stairs/landing/Landing3D';
import { StairInFloorHandRail3D } from '../draw3d/stairs/StairInFloorHandRail3D';
import { Stair } from './stair';
import { Configuration } from './configuration';
import { RemoveRaster } from './removeRaster';
import { Profiles } from './profiles';
import { Stairs } from './stairs';
import { Columns } from './columns';
import { IntermediateLandings } from './intermediateLandings';
import { SizeHandle } from '../draw/sizeHandle';
import { Canvas } from '../draw/canvas';
import { InsideHandRails } from './insideHandRails';

export class StairInFloor extends Stair {
	objectName = 'StairInFloor';
	place = Stair.PLACE_INFLOOR;
	isInFloor = true; // collision voor invloer tekenen van deckingfinish
	handRails = new InsideHandRails();

	constructor(newStairConfiguration, raster) {
		super(newStairConfiguration, raster);

		if (typeof newStairConfiguration !== 'undefined' && typeof newStairConfiguration.handRails !== 'undefined' && newStairConfiguration.handRails !== null) {
			this.handRails = newStairConfiguration.handRails;
		}
		if (typeof this.stairWell !== 'undefined' && this.stairWell !== null) {
			this.stairWell.active = true;
		}
		if (typeof raster !== 'undefined' && raster !== null) {
			this.startRaster = raster;

			// Na aanklikken van plek / raster moeten we berekenen:
			// Hoe groot het trapgat word.
			// Waar elk drawObject komt.

			const startX = Configuration.CURRENT.raster.getSizeX(raster.x - 1);
			const startY = Configuration.CURRENT.raster.getSizeY(raster.y - 1);
			const lengthRasterX = Configuration.CURRENT.raster.spansX.get(raster.x).value;
			const lengthRasterY = Configuration.CURRENT.raster.spansY.get(raster.y).value;
			const totalLengthRasterX = Configuration.CURRENT.raster.getSizeX();

			this.startY = startY + Math.floor((lengthRasterY - this.stairDrawHeight) / 2);
			this.stairWell.startY = this.startY;

			if (lengthRasterX <= this.stairDrawWidth) {
				if (totalLengthRasterX < this.startX + this.stairDrawWidth) {
					// als vanaf begin raster tot eind tekening smaller is dan plaatsen aan eind van raster
					this.startX = totalLengthRasterX - this.stairDrawWidth;
				} else {
					this.startX = startX;
				}
			} else {
				this.startX = startX + Math.floor((lengthRasterX - this.stairDrawWidth) / 2);
			}

			this.stairWell.startX = this.startX;
			this.findRaster();
		}
	}

	addUsedSurface() {
		this.removeUsedSurface();
		Configuration.CURRENT.etages.activeEtage().usedSurface.push({
			width: this.stairWell.width,
			depth: this.stairWell.depth,
			id: this.id,
		});
	}
	removeUsedSurface() {
		Configuration.CURRENT.etages.activeEtage().usedSurface.forEach((surf, index) => {
			if (surf.id === this.id) {
				Configuration.CURRENT.etages.activeEtage().usedSurface.splice(index, 1);
			}
		});
	}
	rastersActive() {
		// Standaard actief false
		let active = false;
		this.rasters.forEach((raster) => {
			// Als er 1 raster is waar de trap invalt die actief is dan actief op true zetten, omdat de trap gedeeltelijk buiten de vloer kan vallen.
			if (Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y)) === true) {
				active = true;
			}
		});
		return active;
	}
	addPossiblePositions(canvas, params, stairs) {
		let possiblePositionFound = false;
		Configuration.CURRENT.raster.spansX.getSpans().forEach((spanX, indexX) => {
			Configuration.CURRENT.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(indexX, indexY)) === true && stairs.containsStair({ x: indexX, y: indexY }) === false) {
					let raster = new Rectangle(
						new DrawValue(Configuration.CURRENT.raster.getSizeX(indexX - 1), 1),
						new DrawValue(Configuration.CURRENT.raster.getSizeY(indexY - 1), 1),
						new DrawValue(spanX.value, -2),
						new DrawValue(spanY.value, -2),
						Stairs.COLORS.possiblePosition,
						null,
						null,
						true,
						stairs,
						{ x: indexX, y: indexY },
					);
					raster.border = true;
					raster.opacity = 0.2;
					possiblePositionFound = true;

					Canvas.CURRENT.addDrawObject(raster);
				}
			});
		});

		// Wanneer geen raster gevonden om in te plaatsen.
		if (possiblePositionFound === false) {
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.editModus.stopEditingObject();
		}
	}
	select(parameters) {
		// Updaten van kleur hoeft hier niet omdat in addDrawobjects al de kleur word bepaald op basis van selected.
		if (this.id === parameters.id) {
			this.selected = !this.selected;
		} else {
			// Wanneer nieuwe object niet dit object is dan is het een deselect.
			this.selected = false;
		}
	}
	getMinMaxStairWellBoundaries() {
		// Alleen de boundaries ophalen dwaarmee het trapgat berekend moet worden.
		const boundariesToCalculateWith = this.boundaries.filter((boundary) => boundary.calculateWithStairWell === true);

		if (boundariesToCalculateWith.length === 0) {
			return null;
		}

		// Bij addDrawobjects na het tekenen stukjes trap het trapgat tekenen.
		// Bij tekenen landings en trapstukken slaan we min en max op
		// Hier halen we dan de kleinste x en y op, als ook grootste x en y.
		const minX = boundariesToCalculateWith.reduce((minX, currentObject) => {
			return Math.min(minX, currentObject.topLeft.x);
		}, Infinity);

		const maxX = boundariesToCalculateWith.reduce((maxX, currentObject) => {
			return Math.max(maxX, currentObject.topRight.x);
		}, -Infinity);

		const minY = boundariesToCalculateWith.reduce((minY, currentObject) => {
			return Math.min(minY, currentObject.topLeft.y);
		}, Infinity);

		const maxY = boundariesToCalculateWith.reduce((maxY, currentObject) => {
			return Math.max(maxY, currentObject.bottomLeft.y);
		}, -Infinity);

		return { minX: minX, maxX: maxX, minY: minY, maxY: maxY };
	}
	updateTrimming(addColumns) {
		if (this.rasters.length > 0) {
			this.stairWell.updateTrimming(this.etageId);
			if (addColumns === true) {
				this.stairWell.updateColumns(this.id, this.etageId);
			}
		}
	}
	getPositionsMouseDrag() {
		let minX = 0;
		let maxX = Configuration.CURRENT.raster.spansX.getSize();
		let minY = 0;
		let maxY = Configuration.CURRENT.raster.spansY.getSize();

		let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].topLeft.x, this.boundaries[0].topLeft.y);
		let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].topRight.x, this.boundaries[0].topRight.y);
		let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].bottomLeft.x, this.boundaries[0].bottomLeft.y);
		let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].bottomRight.x, this.boundaries[0].bottomRight.y);

		switch (this.upComing) {
			case Stair.UPCOMING_TOP:
				// minY bepalen.
				// Bij upComing top dan altijd tot aan vorige rasterpunt, bepalend dan lefttop en righttop.
				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y - 1)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y - 1)) === false
				) {
					minY = Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y - 1);
				}

				// maxY bepalen.
				// Bij top mag hij vanaf boven in een raster naar beneden worden gesleept.
				//

				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y + 1)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y + 1)) === false
				) {
					// Versleping naar onder, kijken of raster eronder actief is, als dat niet zo is dan stoppen aan eind actief raster.
					maxY = Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y);
				}
				break;
			case Stair.UPCOMING_BOTTOM:
				// minY bepalen
				// Stair naar onder mag altijd naar boven in een leeg raster vallen.
				// Daarom vanaf onderste punten bepalen wat de min is.
				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y - 1)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y - 1)) === false
				) {
					minY = Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y - 1);
				}

				// maxY bepalen.
				// Dan mag de stair altijd tot aan onderste punt stair, en niet verder in een leeg raster gesleept worden.
				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y + 1)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y + 1)) === false
				) {
					// Versleping naar onder, kijken of raster eronder actief is, als dat niet zo is dan stoppen aan eind actief raster.
					maxY = Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y);
				}
				break;

			case Stair.UPCOMING_LEFT:
				// Bij versleping naar links, minX bepalen.
				// Dan mag de trap nooit verder dan de eigen rand.
				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x - 1, topLeftRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x - 1, bottomLeftRaster.y)) === false
				) {
					minX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x - 1);
				}

				// Bij versleping naar rechts maxX bepalen.
				// Bij stair links dan mag hij tot aan volgende raster aan linkerpunt te zien. Zo kan hij gedeeltelijk in een leeg raster vallen.
				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x + 1, topLeftRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x + 1, bottomLeftRaster.y)) === false
				) {
					maxX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x);
				}
				break;

			case Stair.UPCOMING_RIGHT:
				// Bij versleping naar links, minX bepalen
				// Kan dan gedeeltelijk aan de linkerkant van een raster uitsteken.
				// Daarom hier checken op right - 1.
				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x - 1, topRightRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x - 1, bottomRightRaster.y)) === false
				) {
					minX = Configuration.CURRENT.raster.spansX.getSize(bottomRightRaster.x - 1);
				}

				// Bij versleping naar rechts maxX bepalen.
				// Kan niet uitsteken aan de rechterkant dus maxX vanaf linkerpunt bepalen.
				if (
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x + 1, topLeftRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x + 1, bottomLeftRaster.y)) === false
				) {
					maxX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x);
				}
				break;
		}
		// MaxX en MinX bepalen is voor top en bottom hetzelfde.
		if (this.upComing === Stair.UPCOMING_BOTTOM || this.upComing === Stair.UPCOMING_TOP) {
			// Als beide linker rasters niet actief zijn dan gewoon tot aan vorige raster (niet afhankelijk van het gecheckte raster).
			// Of bij stair naar boven, en raster links naast punt linksboven is niet actief, dan ook niet verder naar links.
			// Of bij stair naar onder, en raster links naast punt linksonder is niet actief, dan ook niet verder naar links.
			if (
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x - 1, topLeftRaster.y)) === false &&
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x - 1, bottomLeftRaster.y)) === false) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x - 1, topRightRaster.y)) === false && this.upComing === Stair.UPCOMING_TOP) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x - 1, bottomLeftRaster.y)) === false && this.upComing === Stair.UPCOMING_BOTTOM)
			) {
				minX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x - 1);
			}

			// Als beide recter rasters niet actief zijn dan gewoon tot aan volgende raster (niet afhankelijk van het gecheckte raster).
			// Of bij stair naar boven, en raster rechts naast punt rechtsboven is niet actief, dan ook niet verder naar rechts.
			// Of bij stair naar benende, en raster rechtsonder naast punt rechtsonder is niet acitef, dan ook niet verder naar rechts.
			if (
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x + 1, topRightRaster.y)) === false &&
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x + 1, bottomRightRaster.y)) === false) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x + 1, topRightRaster.y)) === false && this.upComing === Stair.UPCOMING_TOP) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x + 1, bottomRightRaster.y)) === false && this.upComing === Stair.UPCOMING_BOTTOM)
			) {
				maxX = Configuration.CURRENT.raster.spansX.getSize(topRightRaster.x);
			}
		}
		// MinY en MaxY bepalen is voor left en right hetzelde.
		else if (this.upComing === Stair.UPCOMING_LEFT || this.upComing === Stair.UPCOMING_RIGHT) {
			// Als beide onder rasters niet actief zijn dan gewoon tot aan volgende raster (niet afhankelijk van het gecheckte raster).
			// Of bij stair naar links, en raster onder linkspunt is niet actief, dan ook niet verder naar onder.
			// Of bij stair naar rechts, en raster onder rechterpunt is niet actief, dan ook niet verder naar onder.
			if (
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y + 1)) === false &&
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y + 1)) === false) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y + 1)) === false && this.upComing === Stair.UPCOMING_LEFT) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y + 1)) === false && this.upComing === Stair.UPCOMING_RIGHT)
			) {
				maxY = Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y);
			}
			// Als beide linker rasters niet actief zijn dan gewoon tot aan vorige raster (niet afhankelijk van het gecheckte raster).
			// Of wanneer bij stair naar links, en raster boven linkerpunt is niet actief, dan ook niet verder naar boven.
			// Of wanneer bij stair naar rechts, en raster boven rechterpunt is niet actief, dan ook niet verder naar boven.
			if (
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y - 1)) === false &&
					Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y - 1)) === false) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y - 1)) === false && this.upComing == Stair.UPCOMING_LEFT) ||
				(Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y - 1)) === false && this.upComing == Stair.UPCOMING_RIGHT)
			) {
				minY = Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y - 1);
			}
		}

		return { minX: minX, maxX: maxX, minY: minY, maxY: maxY };
	}
	onMouseDrag(evt, drawObject) {
		// Group ID opgeslagen, alleen selecteren op rectangle maar wel hele groep verslepen.
		let groupObject = Canvas.CURRENT.drawObjects.findByDrawId(this.drawGroupId);

		let moveX = evt.delta.x / Canvas.CURRENT.scaleFactor; // verplaatsing links-rechts (links - rechts +)
		let moveY = evt.delta.y / Canvas.CURRENT.scaleFactor; // verplaatsing onder-boven (boven - onder +)

		// Trap in de vloer kan gedeeltelijk buiten vloer vallen, maar dan wel minimaal deze marge aanhouden zodat er nog op de vloer gestapt kan worden.
		const spaceStepOnFloor = 500;
		// Bij tegenovergestlede maximale X, dit zorgt ervoor dat de berekening van de stairwell en rasters goed gaat.
		const minSpaceEdge = 100;
		// Zodat trap niet op de rand van raster ligt om errors te voorkomen.
		const minSpaceRaster = 75;

		let minMaxPositions = this.getPositionsMouseDrag();

		let minX = minMaxPositions.minX;
		let minY = minMaxPositions.minY;
		let maxY = minMaxPositions.maxY;
		let maxX = minMaxPositions.maxX;

		let minMaxCoordinatesXmove = null;
		let minMaxCoordinatesYmove = null;

		// x onbeperkt als > 0 en < totale lengte. Als hoofdbalken verticaal dan y onbeperkt
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			minMaxCoordinatesXmove = groupObject.minMaxCoordinates(this.objectsYMinMaxHorizontal);
			minMaxCoordinatesYmove = groupObject.minMaxCoordinates(this.objectsYMinMaxHorizontal);
		} else {
			minMaxCoordinatesXmove = groupObject.minMaxCoordinates(this.objectsYMinMaxVertical);
			minMaxCoordinatesYmove = groupObject.minMaxCoordinates(this.objectsYMinMaxVertical);
		}

		switch (this.upComing) {
			case Stair.UPCOMING_LEFT:
				// Trap verschuiving naar rechts niet groter dan maximale vloer.
				if (minMaxCoordinatesXmove.min.x + moveX > maxX - minSpaceEdge) {
					moveX = maxX - minMaxCoordinatesXmove.min.x - minSpaceEdge;
				}

				// Trap verschuiving naar links niet kleiner dan de stepMarge.
				if (minMaxCoordinatesXmove.min.x + moveX < minX + spaceStepOnFloor) {
					moveX = minX - minMaxCoordinatesXmove.min.x + spaceStepOnFloor;
				}
				break;
			case Stair.UPCOMING_RIGHT:
				// Trap verschuiving naar rechts niet groter dan maximale min de stepMarge.
				if (minMaxCoordinatesXmove.max.x + moveX > maxX - spaceStepOnFloor) {
					moveX = maxX - spaceStepOnFloor - minMaxCoordinatesXmove.max.x;
				}

				// Trap verschuiving naar links niet kleiner dan benodigde opstap.
				if (minMaxCoordinatesXmove.max.x + moveX < minX + minSpaceEdge) {
					moveX = minX - minMaxCoordinatesXmove.max.x + minSpaceEdge;
				}
				break;
			case Stair.UPCOMING_TOP:
				// Trap verschuiving naar boven niet kleiner dan de minY.
				// Gebeurd dat wel dan resetten naar het raster waar hij ligt plus de marge die nodig is om nog op de vloer te komen.
				if (minMaxCoordinatesYmove.min.y + moveY < minY + spaceStepOnFloor) {
					moveY = minY - minMaxCoordinatesYmove.min.y + spaceStepOnFloor;
				}

				// Trap verschuiving naar onder niet groter dan maximale vloer.
				if (minMaxCoordinatesYmove.min.y + moveY > maxY - minSpaceEdge) {
					moveY = maxY - minSpaceEdge - minMaxCoordinatesYmove.min.y;
				}

				break;
			case Stair.UPCOMING_BOTTOM:
				// Trap verschuiving naar boven niet kleiner dan minimale vloer.
				if (minMaxCoordinatesYmove.max.y + moveY < minY + minSpaceEdge) {
					moveY = minY - minMaxCoordinatesYmove.max.y + minSpaceEdge;
				}

				// Trap verschuiving naar onder niet groter dan maximale min de stepMarge.
				if (minMaxCoordinatesYmove.max.y + moveY > maxY - spaceStepOnFloor) {
					moveY = maxY - minMaxCoordinatesYmove.max.y - spaceStepOnFloor;
				}
				break;
		}

		// Wanneer trap boven of onder uitkomt dan minimale X = 0 en maximale X = maximale vloer.
		if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
			// Versleping naar links tot aan minX.
			if (minMaxCoordinatesXmove.min.x + moveX < minX + minSpaceRaster) {
				moveX = minX - minMaxCoordinatesXmove.min.x + minSpaceRaster;
			}
			// Versleping naar rechts tot aan maxX.
			if (minMaxCoordinatesXmove.max.x + moveX > maxX - minSpaceRaster) {
				moveX = maxX - minMaxCoordinatesXmove.max.x - minSpaceRaster;
			}
		}
		// Wanneer trap links of rechts uitkomt dan minimale Y = 0 en maximale Y = maximale vloer.
		if (this.upComing === Stair.UPCOMING_LEFT || this.upComing === Stair.UPCOMING_RIGHT) {
			// Onderkant groter dan vloer grootte dan resetten naar maximale vloer.
			if (minMaxCoordinatesYmove.max.y + moveY > maxY - minSpaceRaster) {
				moveY = maxY - minMaxCoordinatesYmove.max.y - minSpaceRaster;
			}

			// Bovenkant kleiner dan 0 punt dan resetten naar 0.
			if (minMaxCoordinatesYmove.min.y + moveY < minY + minSpaceRaster) {
				moveY = minY - minMaxCoordinatesYmove.min.y + minSpaceRaster;
			}
		}

		this.startX += moveX; // neem x van trapgat over naar de trap
		this.startY += moveY; // neem y van trapgat over naar de trap

		this.stairWell.startX += moveX;
		this.stairWell.startY += moveY;

		// Trimming updaten, en de andere die ook andere dingen update
		this.updateTrimming(false);

		// om redraw te voorkomen ook toepassen op het drawobject
		// omdat er veel variatie is met de trimming hier een nieuw drawobject toevoegen
		// eerst alles weggooien
		groupObject.clear();

		// Dan objecten opnieuw maken
		this.addDrawObjects(null, groupObject, true);
		return { stopPropagation: true };
	}

	onMouseUp(evt, drawObject) {
		this.calculate();
		// Updaten van rasters waar trap in valt na loslaten.
		this.findRaster();

		this.addUsedSurface();
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseMovePossiblePosition(evt, drawObject) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(drawObject.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(drawObject.objectParams.y);

		if (lengthX !== null && lengthY !== null && this.isPossible(lengthX.value, lengthY.value)) {
			drawObject.opacity = 0.5;
		}

		return { stopPropagation: true };
	}
	onMouseLeavePossiblePosition(evt, drawObject) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(drawObject.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(drawObject.objectParams.y);
		if (lengthX !== null && lengthY !== null && this.isPossible(lengthX.value, lengthY.value)) {
			drawObject.opacity = 0.2;
		}
		return { stopPropagation: true };
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params, actualSize) {
		const rasterX = raster.x;
		const rasterY = raster.y;
		const stairPositionX = drawObject.objectParams.x;
		const stairPositionY = drawObject.objectParams.y;

		if ((rasterY > -1 && rasterY === stairPositionY) || (rasterX > -1 && rasterX === stairPositionX)) {
			if (this.isPossible(actualSize.raster, actualSize.raster)) {
				drawObject.opacity = 0.2;
			} else {
				drawObject.opacity = 0;
			}
			drawObject.x.value += delta.x;
			drawObject.y.value += delta.y;
			drawObject.width.value -= delta.x;
			drawObject.height.value -= delta.y;
		}
		if ((rasterY > -1 && rasterY - 1 === stairPositionY) || (rasterX > -1 && rasterX - 1 === stairPositionX)) {
			if (this.isPossible(actualSize.rasterLinks, actualSize.rasterLinks)) {
				drawObject.opacity = 0.2;
			} else {
				drawObject.opacity = 0;
			}
			drawObject.width.value += delta.x;
			drawObject.height.value += delta.y;
		}
	}
	showPossiblePostion(object) {
		object.opacity = 0.5;
	}
	hidePossiblePostion(object) {
		object.opacity = 0;
	}
	moveProfilePossible(params) {
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			let lengthToAbove = Configuration.CURRENT.raster.spansY.getSize(params.y - 2); // nieuwe lengte van raster en raster erboven komen mee. Daar rastersDaarboven weer bij optellen om respectievelijk startY en startY + drawheight te controleren

			if (this.rasters.length > 0) {
				let y = this.rasters[0].y;

				if (params.y === y) {
					if (lengthToAbove + params.newLengthRasterAbove > this.startY) {
						return false;
					}
				}
				if (params.y - 1 === y) {
					if (lengthToAbove + params.newLengthRasterAbove < this.startY + this.stairDrawHeight) {
						return false;
					}
				}
			}
		} else {
			let lengthToLeft = Configuration.CURRENT.raster.spansX.getSize(params.x - 2); // nieuwe lengte van raster en raster links komen mee. Daar rastersDaarboven weer bij optellen om respectievelijk startY en startY + drawheight te controleren

			if (this.rasters.length > 0) {
				let x = this.rasters[0].x;

				if (params.x === x) {
					if (lengthToLeft + params.newLengthRasterLeft > this.startX) {
						return false;
					}
				}
				if (params.x - 1 === x) {
					if (lengthToLeft + params.newLengthRasterLeft < this.startX + this.stairDrawWidth) {
						return false;
					}
				}
			}
		}
		return true;
	}
	onClickPossiblePosition(evt, drawObject, stairSettings, stairs) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(drawObject.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(drawObject.objectParams.y);

		if (lengthX !== null && lengthY !== null) {
			stairSettings.hidePossiblePostion(drawObject);
			stairs.possiblePossitionsVisible = '';
			stairSettings.newStair = false;

			stairs.push(stairSettings, drawObject.objectParams);
			Configuration.CURRENT.editModus.stopEditingObject();

			Configuration.CURRENT.etages.activeEtage().stairs.clearStairSettings();
			stairs.onChange(true);
		}
	}
	isPossible(spanX, spanY) {
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			return spanY > this.stairDrawHeight; // nog controle op richting hoofdbalken dan ipv spanX spanY pakken. Voorlopig niet naar breedte kijken. Kan meerdere rasters beslaan
		} else {
			return spanX > this.stairDrawWidth;
		}
	}
	inRaster(raster) {
		let contains = false;
		this.rasters.forEach((rst) => {
			if (rst.x === raster.x && rst.y === raster.y) {
				contains = true;
			}
		});
		return contains;
	}

	getAbsoluteDepth() {
		if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
			return this.stairWell.depth;
		} else {
			return this.stairWell.width;
		}
	}

	onSizeHandleChangedHorizontal(evt, drawObject, newDimensions, changedDimensionIndex) {
		const etageWidth = Configuration.CURRENT.etages.get(Configuration.CURRENT.etages.activeEtage().etageIndex).floor.width;
		if (changedDimensionIndex === 0) {
			if (newDimensions[changedDimensionIndex] < 0) {
				this.startX = 0;
				this.onChange();
				return;
			}

			if (newDimensions[changedDimensionIndex] > etageWidth || newDimensions[changedDimensionIndex] + this.getAbsoluteDepth() > etageWidth) {
				this.startX = etageWidth - this.getAbsoluteDepth();
				this.onChange();
				return;
			}

			this.startX = newDimensions[changedDimensionIndex];
		} else if (changedDimensionIndex === 2) {
			if (newDimensions[changedDimensionIndex] + this.getAbsoluteDepth() > etageWidth) {
				this.startX = 0;
				this.onChange();
				return;
			}
			this.startX += drawObject.width.value - newDimensions[changedDimensionIndex];
		} else {
			this.startX += drawObject.width.value - newDimensions[changedDimensionIndex];
		}

		this.onChange();
	}
	onSizeHandleChangedVertical(evt, drawObject, newDimensions, changedDimensionIndex) {
		const etageDepth = Configuration.CURRENT.etages.get(Configuration.CURRENT.etages.activeEtage().etageIndex).floor.length;
		if (changedDimensionIndex === 0) {
			if (newDimensions[changedDimensionIndex] < 0) {
				this.startY = 0;
				this.onChange();
				return;
			}
			if (newDimensions[changedDimensionIndex] > etageDepth || newDimensions[changedDimensionIndex] + this.getAbsoluteDepth() > etageDepth) {
				this.startY = etageDepth - this.getAbsoluteDepth();
				this.onChange();
				return;
			}

			this.startY = newDimensions[changedDimensionIndex];
		} else if (changedDimensionIndex === 2) {
			if (newDimensions[changedDimensionIndex] + this.getAbsoluteDepth() > etageDepth) {
				this.startY = 0;
				this.onChange();
				return;
			}
			this.startY += drawObject.width.value - newDimensions[changedDimensionIndex];
		} else {
			this.startY += drawObject.width.value - newDimensions[changedDimensionIndex];
		}
		this.onChange();
	}

	addDrawObjects(canvas, stairGroup, drag = false) {
		if (this.selected === true) {
			if (drag) {
				Configuration.CURRENT.dimensioning.updateDrawObjects(this.stairWell.startX, this.stairWell.startY, this.stairDrawWidth, this.stairDrawHeight, SizeHandle.TYPE_OBJECT);
			} else {
				Configuration.CURRENT.dimensioning.setSizeHandleObject(this, SizeHandle.TYPE_OBJECT, true, this.stairWell.startX, this.stairWell.startY, this.stairDrawWidth, this.stairDrawHeight);
			}
		}

		if (typeof stairGroup === 'undefined' || stairGroup === null) {
			stairGroup = new ObjectGroup(Profiles.COLOR.mainBeam, null, null, false, this, {});
			this.drawGroupId = stairGroup.id;
		}

		// Diepte van de stair berekenen, hier nu nog uitgaande van geen landings.
		// stairdepth gebruiken voor de linkse en rechtse lijn. Niet voor de tail. Die blijft altijd over de hele diepte van detrap
		let stairDepth = Mathematic.calculateDepth(this.verticalDistance(), this.angle);

		// Als er wel intermediate landings zijn gevonden dan de diepte tot aan die landing ophalen.
		if (this.intermediateLandings.get().length > 0) {
			stairDepth = this.intermediateLandings.getDepth(0, this, this.angle);
		}

		this.boundaries = [];

		let boundary = {
			topLeft: { x: this.startX, y: this.startY },
			topRight: { x: this.startX + this.stairDrawWidth, y: this.startY },
			bottomLeft: { x: this.startX, y: this.startY + this.stairDrawHeight },
			bottomRight: { x: this.startX + this.stairDrawWidth, y: this.startY + this.stairDrawHeight },
		};
		let nextLanding = this.intermediateLandings.get(0);
		let prevLanding = null;

		// Stukje trap tot de eerste landing. (vanaf bovenkant stair gezien)
		boundary = this.drawObjectsStair(stairGroup, this.upComing, boundary, stairDepth, true, prevLanding, null, nextLanding, false);

		let params = {
			stairObject: this,
		};

		let lastUpcoming = this.upComing;
		this.intermediateLandings.intermediateLandings.forEach((landing, index) => {
			params.boundary = boundary;
			params.upComing = lastUpcoming;
			params.stepWidth = this.stepWidth;
			params.hasErrors = this.hasErrors;
			params.selected = this.selected;
			nextLanding = this.intermediateLandings.get(index + 1);
			prevLanding = this.intermediateLandings.get(index - 1);

			let drawObjects = landing.addDrawObjects(params);
			boundary = params.boundary;

			// Hier wel boundary pushen omdat Intermediate landing voor zowel outside als infloor hetzelfde moet werken.
			this.boundaries.push(boundary);
			// Toevoegen aan drawGroup.
			drawObjects.forEach((drawObject) => {
				stairGroup.push(drawObject);
			});
			// Diepte vanaf huidige landing tot aan volgende landing ophalen.
			stairDepth = this.intermediateLandings.getDepth(index + 1, this, this.angle);

			// Bij behorende stair na de landing tekenen.
			boundary = this.drawObjectsStair(
				stairGroup,
				Stair.toOppositeUpComing(landing.upComing),
				boundary,
				stairDepth,
				false,
				prevLanding,
				landing,
				nextLanding,
				landing.landingType === IntermediateLandings.oneeightyDegrees,
			);

			lastUpcoming = Stair.toOppositeUpComing(landing.upComing);
		});

		// Nadat alle stairs toegeveogd zijn het trapgat tekenen.
		stairGroup.push(this.stairWell.addDrawObjects(params, this), true);

		this.drawObject = stairGroup.id;
		return { regenerate: false, stair: stairGroup };
	}
	isOwnRasterActive() {
		let raster = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX, this.startY);
		return Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y));
	}
	addDrawObjects3d(canvas3d, etage, raster, posY) {
		// if (this.isOwnRasterActive()) {
		//! Stairstart word al gekeken vanuit de stairwell, daar word ook de stair al in het midden gezet, dat hoeft dan in 3D niet meer.
		// TODO: Dit veranderen en kijken wat hij doet bij 3D.
		let boundary = {
			topLeft: { x: this.startX, y: this.startY },
			topRight: { x: this.startX + this.stairDrawWidth, y: this.startY },
			bottomLeft: { x: this.startX, y: this.startY + this.stairDrawHeight },
			bottomRight: { x: this.startX + this.stairDrawWidth, y: this.startY + this.stairDrawHeight },
		};
		this.stairBoundries = boundary;

		// Eerste trap tot eerste landing of onderkant vloer.
		let yPos = this.startHeight; // Standaard tot op de grond.

		// Of wanneer er een landing is dan de eerste trap tot de eerste landing tekenen.
		let firstLanding = this.intermediateLandings.get(0);
		if (firstLanding !== null) {
			yPos = firstLanding.height;
		}

		let startX = this.stairBoundries.topLeft.x;
		let startY = this.stairBoundries.topLeft.y;

		canvas3d.addDrawObject(
			new Stair3D(startX, startY, {
				stairData: this,
				yPos: yPos,
				yPosEnd: this.endHeight, // Eindpunt, dus eigen landing hoogte oftewel vanaf boven naarbeneden begin punt in hoogte.
				landingData: null, // Geven hier null mee omdat we de stair niet hoeven te centereren rondom een endlanding. (Stairinfloor kan geen endlanding hebben)
				overhang: Configuration.CURRENT.overhang.size,
				upComing: Stair.toOppositeUpComing(this.upComing),
				width: this.stairWell.width,
				depth: this.stairWell.depth,
				firstStair: true,
				stepsAmount: this.intermediateLandings.length !== 0 ? Math.ceil((this.endHeight - firstLanding.height) / this._fallingProtectionStandardRules.getMaxRise(this.angle)) + 1 : this.amountSteps,
			}),
			Canvas3D.TYPE_STAIR,
		);

		this.intermediateLandings.intermediateLandings.forEach((landing, index) => {
			let prevLanding = this.intermediateLandings.get(index - 1);
			let upComingPrev;
			if (prevLanding !== null && typeof prevLanding !== 'undefined') {
				upComingPrev = Stairs.getOppositeUpComing(prevLanding.upComing);
			} else {
				upComingPrev = this.upComing;
			}

			// Toevoegen van de landing
			new Landing3D(landing.boundary.topLeft.x, landing.boundary.topLeft.y, {
				drawHeight: landing.height,
				upComing: landing.upComing,
				upComingPrev: upComingPrev,
				width: this.getLandingWidth3D(landing, false),
				depth: this.getLandingDepth3D(landing, false),
				landingData: landing,
				raster: raster,
				stairData: this,
				endLanding: false,
			});

			// Toevoegen van de stair na de huidige landing
			// Tot aan de volgende landing hoogte, is die er niet, dan is het tot de startHoogte van de trap.
			let nextLanding = this.intermediateLandings.get(index + 1);
			if (nextLanding === null || typeof nextLanding === 'undefined') {
				nextLanding = {};
				nextLanding.height = this.startHeight;
			}

			// Na elke landing een stuk trap naar de volgende landing.
			canvas3d.addDrawObject(
				new Stair3D(landing.boundary.topLeft.x, landing.boundary.topLeft.y, {
					stairData: this,
					yPos: nextLanding.height, // Plek tot aan de volgende landing of tot aan de vloer.
					yPosEnd: landing.height, // Plek ter hoogte van huidige landing.
					landingData: landing,
					overhang: Configuration.CURRENT.overhang.size,
					upComing: landing.upComing,
					width: this.getLandingWidth3D(landing, false),
					depth: this.getLandingDepth3D(landing, false),
					stairId: this.id,
					firstStair: false,
					endLandingActive: this.endLanding.active,
					stepsAmount: landing.amountSteps,
				}),
				Canvas3D.TYPE_STAIR,
			);
		});

		// Niet meer nodig met inivisible holes.
		// new StairDeckingFinish3D(this.startX, this.startY, this, raster, etage, posY);

		new StairInFloorHandRail3D(this);

		// eslint-disable-next-line no-new
		new StairInFloorProfiles3D(this, raster, etage);
	}
	// Doorverwijzen naar intermediatelandings
	recalculateLandingHeights() {
		if (this.crossStairWell === true) {
			this.intermediateLandings.recalculateLandingHeights();
		}
	}
	setStartAndEndHeight() {
		// Bij wijziging van de etageHoogte komen we hierin.
		// Dan ophalen nieuwe etageHoogte, aan de hand van index ophalen.
		this.startHeight = Configuration.CURRENT.etages.getTotalHeight(this.etageIndex - 1, true);
		this.endHeight = Configuration.CURRENT.etages.getTotalHeight(this.etageIndex, true);
		this.validate();
	}
	createMinimalIntermediateLandings() {
		// Stair in floor hoogte is altijd verschil tussen endHeight en startHeight
		let minLanding;
		if (this.verticalDistance() < this._fallingProtectionStandardRules.standardData.maxHeightLanding) {
			minLanding = 0;
		} else {
			minLanding = Math.floor(this.verticalDistance() / this._fallingProtectionStandardRules.getMaxHeightLanding(this.verticalDistance(), this.angle));
		}

		let landingCount = this.intermediateLandings.getAll().length;
		if (landingCount > 0) {
			// check te laag
			let maxHeight = this._fallingProtectionStandardRules.getMaxHeightLanding(this.endHeight, this.angle);
			let height = this.verticalDistance();

			// check te veel
			this.intermediateLandings.removeUnnecessaryLandings(minLanding);
			this.intermediateLandings.getAll().forEach((il) => {
				if (height - il.height > maxHeight) {
					il.height = height - maxHeight;
				}
				if (il.height > this.endHeight) {
					il.height = this.endHeight / 2;
				}
				height = il.height;
			});
			this.intermediateLandings.sort(); // zorgen dat ze op de juiste volgorde komen te staan
		}

		if (landingCount < minLanding) {
			for (let i = landingCount; i < minLanding; i++) {
				this.addIntermediateLanding(true);
			}
		}

		this.intermediateLandings.updateAllLandings(this);
	}
	getEdgePosition(handRailPosition) {
		let startX = this.stairWell.startX;
		let startY = this.stairWell.startY;
		let endX = this.stairWell.startX + this.stairWell.width;
		let endY = this.stairWell.startY + this.stairWell.depth;

		if (startX - Configuration.CURRENT.overhang.size === handRailPosition.startX.value) {
			startX -= Configuration.CURRENT.overhang.size; // links tegen de kant
		}
		if (endX + Configuration.CURRENT.overhang.size === handRailPosition.startX.value) {
			endX += Configuration.CURRENT.overhang.size; // rechts tegen de kant
		}
		if (startY - Configuration.CURRENT.overhang.size === handRailPosition.startY.value) {
			startY -= Configuration.CURRENT.overhang.size; // boven tegen de kant
		}
		if (endY + Configuration.CURRENT.overhang.size === handRailPosition.startY.value) {
			endY += Configuration.CURRENT.overhang.size; // onder tegen de kant
		}

		return { startX: startX, startY: startY, endX: endX, endY: endY };
	}
	getHolePosition() {
		const etage = Configuration.CURRENT.etages.getById(this.etageId);
		const columnWidth = Configuration.CURRENT.columns.column.width;

		// Wanneer trap of in 1 raster ligt.
		// Of meerdere rasters en ze zijn beide actief.
		if (
			this.rasters.length === 1 ||
			(this.rasters.length > 1 &&
				etage.isActiveRaster(new RemoveRaster(this.rasters[0].x, this.rasters[0].y)) === true &&
				etage.isActiveRaster(new RemoveRaster(this.rasters[1].x, this.rasters[1].y)) === true)
		) {
			// Gewoon gehele stairWell pakken als gat voor in de deckingFinish.
			return this.getPositionProfiles();
		}
		let startX = this.stairWell.trimmings.trimmings.map((o) => o.startX);
		let endX = this.stairWell.trimmings.trimmings.map((o) => o.endX);
		let startY = this.stairWell.trimmings.trimmings.map((o) => o.startY);
		let endY = this.stairWell.trimmings.trimmings.map((o) => o.endY);

		startX = startX.reduce(function (prev, curr) {
			return prev < curr ? prev : curr;
		});

		endX = endX.reduce(function (prev, curr) {
			return prev > curr ? prev : curr;
		});

		startY = startY.reduce(function (prev, curr) {
			return prev < curr ? prev : curr;
		});

		endY = endY.reduce(function (prev, curr) {
			return prev > curr ? prev : curr;
		});

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
			switch (this.upComing) {
				case Stair.UPCOMING_TOP:
					startX = this.stairWell.startX;
					endX = this.stairWell.startX + this.stairWell.width;
					endY += columnWidth / 2;
					break;
				case Stair.UPCOMING_BOTTOM:
					startY -= columnWidth / 2;
					startX = this.stairWell.startX;
					endX = this.stairWell.startX + this.stairWell.width;
					break;
				case Stair.UPCOMING_LEFT:
					startX = this.stairWell.startX;
					startY = this.stairWell.startY;
					endX += columnWidth / 2;
					break;
				case Stair.UPCOMING_RIGHT:
					endX = this.stairWell.startX + this.stairWell.width;
					endY = this.stairWell.startY + this.stairWell.depth;
					startX -= columnWidth / 2;
					break;
			}
		} else if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			switch (this.upComing) {
				case Stair.UPCOMING_TOP:
					startY = this.stairWell.startY;
					endY += columnWidth / 2;
					break;
				case Stair.UPCOMING_BOTTOM:
					startY -= columnWidth / 2;
					endY = this.stairWell.startY + this.stairWell.depth;
					break;
				case Stair.UPCOMING_LEFT:
					startY = this.stairWell.startY;
					endY = this.stairWell.startY + this.stairWell.depth;
					endX += columnWidth / 2;
					break;
				case Stair.UPCOMING_RIGHT:
					startY = this.stairWell.startY;
					endY = this.stairWell.startY + this.stairWell.depth;
					startX -= columnWidth / 2;
					break;
			}
		}
		return { startX: startX, startY: startY, endX: endX, endY: endY };
	}
	getPositionProfiles() {
		let startX = this.stairWell.startX;
		let startY = this.stairWell.startY;

		let endX = this.stairWell.startX + this.stairWell.width;
		let endY = this.stairWell.startY + this.stairWell.depth;

		return { startX: startX, startY: startY, endX: endX, endY: endY };
	}

	onHandrailPosition(handRailPosition) {
		if (handRailPosition === null || typeof handRailPosition === 'undefined') {
			return;
		}

		let edgePosition = this.getEdgePosition(handRailPosition);

		// Horizontale handrail:
		// Als hij na Begin X van de handrailstart ligt
		// En hij ligt voor het eindpunt van handrail eind X
		// En hij ligt kleste Y minder dan de handrail Y
		// En hij ligt grootste Y groter dan handrail Y.
		if (
			edgePosition.startX >= handRailPosition.startX.value &&
			edgePosition.endX <= handRailPosition.endX.value &&
			edgePosition.startY <= handRailPosition.startY.value &&
			edgePosition.endY >= handRailPosition.endY.value
		) {
			return true;
		}

		// Verticale handrail:
		// Als hij na begin Y van de handrailstart ligt
		// En hij ligt voor het eindpunt van de handrail eind.
		// En hij ligt kleinste x minder dan handrail X
		// En hij ligt grootste x groter dan handrail X
		if (
			edgePosition.startY >= handRailPosition.startY.value &&
			edgePosition.endY <= handRailPosition.endY.value &&
			edgePosition.startX <= handRailPosition.startX.value &&
			edgePosition.endX >= handRailPosition.endX.value
		) {
			return true;
		}
	}

	onProfilePosition(profilePosition) {
		let stairWidth = this.stairWell.width;
		let stairDepth = this.stairWell.depth;

		return (
			(this.stairWell.startX >= profilePosition.startX &&
				this.stairWell.startX <= profilePosition.endX &&
				this.stairWell.startX + stairWidth >= profilePosition.startX &&
				this.stairWell.startX + stairWidth <= profilePosition.endX &&
				profilePosition.startY >= this.stairWell.startY &&
				profilePosition.endY <= this.startY + stairDepth) ||
			(this.stairWell.startY >= profilePosition.startY &&
				this.stairWell.startY <= profilePosition.endY &&
				this.stairWell.startY + stairDepth >= profilePosition.startY &&
				this.stairWell.startY + stairDepth <= profilePosition.endY &&
				profilePosition.startX >= this.stairWell.startX &&
				profilePosition.endX <= this.stairWell.startX + stairWidth) ||
			(this.stairWell.startX <= profilePosition.startX &&
				this.stairWell.startX + stairWidth >= profilePosition.startX &&
				this.stairWell.startY <= profilePosition.startY &&
				this.stairWell.startY + stairDepth >= profilePosition.startY) ||
			(this.stairWell.startX <= profilePosition.endX &&
				this.stairWell.startX + stairWidth >= profilePosition.endX &&
				this.stairWell.startY <= profilePosition.endY &&
				this.stairWell.startY + stairDepth >= profilePosition.endY)
		);
	}

	calculate() {
		if (Configuration.CURRENT.raster.spansX.getSize() === 0 || Configuration.CURRENT.raster.spansY.getSize() === 0) {
			return;
		}

		super.calculate();
		this.stairWell.calculate(this);
		this.addUsedSurface();
		this.updateTrimming(true);
	}
	calculateAmount(params) {
		params.stairId = this.id;
		this.stairWell.calculateAmount(params);
	}

	drawObjectsStair(stairGroup, upComingInFloor, boundary, stairDepth, inFloor, prevLanding, landing, nextLanding, oneeightyDegreesLanding) {
		if (typeof inFloor === 'undefined' || inFloor === null) {
			inFloor = false;
		}
		let stairRight = null;
		let stairLeft = null;
		let stairTail = null;
		let startX = 0;
		let startY = 0;
		let totalHeight = this.verticalDistance();
		let stairHeight = this.verticalDistance();
		if (landing !== null) {
			stairHeight = landing.height;
		}
		if (typeof nextLanding !== 'undefined' && nextLanding !== null) {
			totalHeight -= nextLanding.height;
			stairHeight -= nextLanding.height;
		}

		switch (upComingInFloor) {
			case Stair.UPCOMING_TOP:
				if (inFloor === true) {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
				} else {
					startX = boundary.bottomLeft.x;
					startY = boundary.bottomLeft.y;
				}
				// Als de huidige 180 graden is lijnen we het stukje trap LINKS uit
				if (oneeightyDegreesLanding) {
					//als 180 graden dan aan begin van bordes
					startX = boundary.topRight.x - this.width;
				} else if (prevLanding !== null) {
					// In het midden uitlijnen van het bordes
					startX = boundary.topRight.x + (boundary.topLeft.x - boundary.topRight.x - this.width) / 2;
					if (nextLanding !== null && typeof nextLanding !== 'undefined') {
						if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
							// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
							startX = boundary.topRight.x - this.width;
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0),
					new DrawValue(startX),
					new DrawValue(startY + stairDepth), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);

				stairLeft = new Line(
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0),
					new DrawValue(startX + this.width),
					new DrawValue(startY + stairDepth), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);
				let correctionBeam = false;
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);
					let well = { startX: startX, startY: startY, width: this.width, depth: stairDepth };

					// als trap onder trapgat uit komt
					if (well.depth > wellDepth) {
						well.startY = well.startY + well.depth - wellDepth;
						well.depth = wellDepth;
						correctionBeam = true;
					}
					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				boundary = {
					topLeft: { x: startX, y: startY },
					topRight: { x: startX + this.width, y: startY },
					bottomLeft: { x: startX, y: startY + stairDepth },
					bottomRight: { x: startX + this.width, y: startY + stairDepth },
				};

				break;
			case Stair.UPCOMING_BOTTOM:
				if (this.intermediateLandings.length === 0) {
					startX = boundary.bottomLeft.x;
					startY = boundary.bottomLeft.y;
				} else {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
					// Als de huidige 180 graden is lijnen we het stukje trap LINKS uit
					if (oneeightyDegreesLanding) {
						//als 180 graden dan aan begin van bordes
						startX = boundary.topLeft.x;
					} else if (prevLanding !== null) {
						// In het midden uitlijnen van het bordes
						startX = boundary.topLeft.x + (boundary.topRight.x - boundary.topLeft.x - this.width) / 2;
						if (nextLanding !== null && typeof nextLanding !== 'undefined') {
							if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
								// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
								startX = boundary.topLeft.x;
							}
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX),
					new DrawValue(startY - stairDepth),
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairLeft = new Line(
					new DrawValue(startX + this.width),
					new DrawValue(startY - stairDepth),
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{ type: 'stairLeft', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);

				correctionBeam = false;
				boundary = {
					topLeft: { x: startX, y: startY - stairDepth },
					topRight: { x: startX + this.width, y: startY - stairDepth },
					bottomLeft: { x: startX, y: startY },
					bottomRight: { x: startX + this.width, y: startY },
				};
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);
					let well = { startX: startX, startY: startY - stairDepth, width: this.width, depth: stairDepth };

					// als trap onder trapgat uit komt
					if (well.depth > wellDepth) {
						well.depth = wellDepth;
						correctionBeam = true;
					}
					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				break;
			case Stair.UPCOMING_RIGHT:
				if (this.intermediateLandings.length === 0) {
					startX = boundary.topRight.x;
					startY = boundary.topRight.y;
				} else {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
					if (oneeightyDegreesLanding) {
						//als 180 graden dan aan begin van bordes
						startY = boundary.bottomLeft.y - this.width;
					} else if (prevLanding !== null) {
						startY = boundary.topLeft.y + (boundary.bottomLeft.y - boundary.topLeft.y - this.width) / 2;
						if (nextLanding !== null && typeof nextLanding !== 'undefined') {
							if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
								// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
								startY = boundary.bottomLeft.y - this.width;
							}
						}
					}
				}

				stairLeft = new Line(
					new DrawValue(startX - stairDepth),
					new DrawValue(startY, Columns.COLUMN_SIZE / 2),
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairLeft', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairRight = new Line(
					new DrawValue(startX - stairDepth),
					new DrawValue(startY + this.width, -Columns.COLUMN_SIZE / 2),
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairRight',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);

				correctionBeam = false;
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);

					let well = { startX: startX - stairDepth, startY: startY, width: stairDepth, depth: this.width };

					// als trap onder trapgat uit komt
					if (well.width > wellDepth) {
						well.width = wellDepth;
						correctionBeam = true;
					}
					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				boundary = {
					topLeft: { x: startX - stairDepth, y: startY },
					topRight: { x: startX, y: startY },
					bottomLeft: { x: startX - stairDepth, y: startY + this.width },
					bottomRight: { x: startX, y: startY + this.width },
				};

				break;
			case Stair.UPCOMING_LEFT:
				if (inFloor === true) {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
				} else {
					startX = boundary.topRight.x;

					if (oneeightyDegreesLanding) {
						//als 180 graden dan aan begin van bordes
						startY = boundary.topLeft.y;
					} else if (prevLanding !== null) {
						startY = boundary.topLeft.y + (boundary.bottomLeft.y - boundary.topLeft.y - this.width) / 2;
						if (nextLanding !== null && typeof nextLanding !== 'undefined') {
							if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
								// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
								startY = boundary.topLeft.y;
							}
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2),
					new DrawValue(startX + stairDepth),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairLeft = new Line(
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2),
					new DrawValue(startX + stairDepth),
					new DrawValue(startY + this.width, inFloor === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);
				correctionBeam = false;
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);
					let well = { startX: startX - stairDepth, startY: startY, width: stairDepth, depth: this.width };

					// als trap onder trapgat uit komt
					if (well.width > wellDepth) {
						well.startX = startX + stairDepth - wellDepth;
						well.width = wellDepth;
						correctionBeam = true;
					}

					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				boundary = {
					topLeft: { x: startX, y: startY },
					topRight: { x: startX + stairDepth, y: startY },
					bottomLeft: { x: startX, y: startY + this.width },
					bottomRight: { x: startX + stairDepth, y: startY + this.width },
				};
				break;
		}
		if (stairRight !== null) {
			stairRight.opacity = 0.5;
			stairRight.colorFromParent = false;
			stairGroup.push(stairRight);
		}
		if (stairLeft !== null) {
			stairLeft.opacity = 0.5;
			stairLeft.colorFromParent = false;
			stairGroup.push(stairLeft);
		}
		if (stairTail !== null) {
			stairTail.opacity = 0.2;
			stairTail.colorFromParent = false;
			stairGroup.push(stairTail);
		}

		// Wanneer eigen landing null is dan is het het eerste stuk trap.
		// Kan dan wel nextLanding hebben.
		if (landing === null) {
			boundary.calculateWithStairWell = true;
		}
		// Wanneer er wel een volgende landing is, dan de check uitvoeren of het stuk trap meegenomen moet worden in de trapgat berekening.
		else {
			// Gebruik maken van het laagste punt van de trap. Dus tot aan de volgende landing, is die er niet, dan tot aan eindpunt van de trap / vorige etage.
			const useHeight = nextLanding !== null ? nextLanding.height : this.startHeight;
			// Wanneer hoogte minder is dan de passage hoogte dan altijd meenemen in trapgat berekening.
			if (this.endHeight - useHeight < Stairs.PASSAGE_HEIGHT) {
				boundary.calculateWithStairWell = true;
			} else {
				boundary.calculateWithStairWell = false;
			}
		}

		this.boundaries.push(boundary);
		return boundary;
	}

	updateStairPositionOnRasterChanged(params) {
		let activeEtageId = params.etages.activeEtageIndex;
		let floor = params.etages.etages[activeEtageId].floor;
		// Alleen als huidige raster of rasters ervoor veranders is van grootte
		if (params.changedParams.beamDirection === 'x' && params.changedParams.rasterIndex <= this.rasters[0].x) {
			if (this.startX + this.stairDrawWidth > floor.width) {
				this.startX = this.startX - this.stairDrawWidth;
			}
		}
		if (params.changedParams.beamDirection === 'y' && params.changedParams.rasterIndex <= this.rasters[0].y) {
			if (this.startY + this.stairDrawHeight > floor.length) {
				this.startY = this.startY - this.stairDrawHeight;
			}
		}
	}
	// TODO: Deze functie geheel weghalen als 180 graden is gefixt is.
	checkUpComing() {
		this.disabledUpcomings = [];
		if (this.rasters.length > 0) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				if (this.stairDrawWidth > Configuration.CURRENT.raster.spansY.get(this.rasters[0].y).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					}
				}
				if (this.stairDrawHeight > Configuration.CURRENT.raster.spansY.get(this.rasters[0].y).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					}
				}
			} else {
				if (this.stairDrawWidth > Configuration.CURRENT.raster.spansX.get(this.rasters[0].x).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					}
				}
				if (this.stairDrawHeight > Configuration.CURRENT.raster.spansX.get(this.rasters[0].x).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					}
				}
			}
		}
		if (this.disabledUpcomings.includes(this.upComing)) {
			this.upComing++;
			if (this.upComing > 4) {
				this.upComing -= 4;
			}
		}
	}
}
