import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { Stairs } from './stairs';
import { Stair } from './stair';
import { StairTrimmings } from './trimmings/stairTrimmings';

import { Configuration } from './configuration';
import { Columns } from './columns';
import { Profiles } from './profiles';
import { RemoveRaster } from './removeRaster';

export class StairWell {
	objectName = 'StairWell';
	depth = 0;
	width = 0;
	startX = 0;
	startY = 0;
	stairShift = { x: 0, y: 0 };
	active = true;
	trimmings = new StairTrimmings();
	boundaries = [];
	onRasterChanged(params) {}
	updateTrimming(etageId) {
		this.trimmings.removeTrimmings();
		this.trimmings.setTrimming(this.startX, this.startY, this.width, this.depth, etageId);
	}
	updateColumns(stairId, etageId) {
		let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX, this.startY);
		let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX, this.startY + this.depth);
		let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX + this.width, this.startY);
		let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX + this.width, this.startY + this.depth);

		let columnSize = Columns.COLUMN_SIZE / Configuration.CURRENT.canvas.scaleFactor;
		let columns = Configuration.CURRENT.columns;
		// Kolommen uit eerdere configuraties weghalen, die hadden nog geen positiebenaming.
		columns.removeByName(stairId);

		// Kolommen weghalen omdat we opnieuw tekenen / berekenen.
		columns.removeByName(stairId + '_' + Columns.POSITION_TOP);
		columns.removeByName(stairId + '_' + Columns.POSITION_BOTTOM);
		columns.removeByName(stairId + '_' + Columns.POSITION_LEFT);
		columns.removeByName(stairId + '_' + Columns.POSITION_RIGHT);

		let etage = Configuration.CURRENT.etages.getById(etageId);
		if (etage !== null && typeof etage !== 'undefined') {
			let columnY;
			let columnX;

			let leftColumn = null;
			let rightColumn = null;
			let topColumn = null;
			let bottomColumn = null;

			// Kijken per etage of startHoogte + eigen hoogte groter is dan tot aan de huidig etage in de loop.
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				// Wanneer linksboven en linksonder niet in hetzelfde raster liggen dan is er een doorkruising van de hoofdbalk.
				// NAME: LEFT COLUMN, Aan de linkerkant van de gebouwkolom
				if (
					topLeftRaster.y !== bottomLeftRaster.y &&
					(etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)))
				) {
					// Zoek dan het begin van het volgende raster(Y) op, op basis van topLeftRaster
					columnY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y);
					leftColumn = { x: topLeftRaster.y, name: stairId + '_' + Columns.POSITION_LEFT, type: Columns.STAIR_TRIMMING_COLUMN, position: { x: this.startX - columnSize / 2, y: columnY } };
				}

				// Wanneer rechtsboven en rechtsonder niet in hetzelfde raster liggen dan is er een doorkruising van de hoofdbalk.
				// NAME: RIGHT COLUMN, Aan de rechterkant van de gebouwkolom.
				if (
					topRightRaster.y !== bottomRightRaster.y &&
					(etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y)))
				) {
					// Zoek dan het begin van het volgende raster(Y) op, op basis van topRightRaster
					columnY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y);
					rightColumn = {
						x: topRightRaster.y,
						name: stairId + '_' + Columns.POSITION_RIGHT,
						type: Columns.STAIR_TRIMMING_COLUMN,
						position: { x: this.startX + this.width + columnSize / 2, y: columnY },
					};
				}

				// Wanneer linksboven en rechtsboven niet in hetzelfde raster liggen en 1 van de 2 is niet actief dan is een extra kolom nodig om trimming te bevestigen
				// TOP COLUMN, aan de bovenkant van de gebouwkolom.
				if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) !== etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y))) {
					columnX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x);
					topColumn = { x: topRightRaster.x, name: stairId + '_' + Columns.POSITION_TOP, type: Columns.STAIR_TRIMMING_COLUMN, position: { x: columnX, y: this.startY - columnSize / 2 } };
				}

				// Wanneer linksonder en rechtsonder niet in hetzelfde raster liggen en 1 van de 2 is niet actief is een extra kolom nodig om trimming te bevestigen.
				// BOTTOM COLUMN, Aan de onderkant van de gebouwkolom.
				if (etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) !== etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) {
					columnX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x);
					bottomColumn = {
						x: bottomRightRaster.x,
						name: stairId + '_' + Columns.POSITION_BOTTOM,
						type: Columns.STAIR_TRIMMING_COLUMN,
						position: { x: columnX, y: this.startY + this.depth + columnSize / 2 },
					};
				}
			} else if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
				// Wanneer linksboven en rechtsboven niet gelijk zijn dan is er een doorkruising van de hoofdbalk, of wanneer de ene wel actief ander niet actief.
				// TOP COLUMM, aan de bovenkant van de gebouwkolom.
				if (
					topLeftRaster.x !== topRightRaster.x &&
					(etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)))
				) {
					// Zoek dan het begin van het volgende raster(Y) op, op basis van topLeftRaster
					columnX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x);
					topColumn = { x: topLeftRaster.x, name: stairId + '_' + Columns.POSITION_TOP, type: Columns.STAIR_TRIMMING_COLUMN, position: { x: columnX, y: this.startY - columnSize / 2 } };
				}

				// Wanneer linksonder en rechtsonder niet gelijk zijn dan is er een doorkruising van de hoofdbalk, of wanneer het ene raster wel en het ander niet actief.
				// BOTTOM COLUMM, aan de onderkant van de gebouwkolom.
				if (
					bottomLeftRaster.x !== bottomRightRaster.x &&
					(etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y)))
				) {
					// Zoek dan het begin van het volgende raster(X) op, op basis van bottomLeftRaster
					columnX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x);
					bottomColumn = {
						x: bottomRightRaster.x,
						name: stairId + '_' + Columns.POSITION_BOTTOM,
						type: Columns.STAIR_TRIMMING_COLUMN,
						position: { x: columnX, y: this.startY + this.depth + columnSize / 2 },
					};
				}

				// Wanneer linksboven en linksonder niet in hetzelfde raster liggen en 1 van de 2 is niet actief dan is een extra kolom nodig om trimming te bevestigen
				// LINKER COLUMN, Aan de linkerkant van de gebouwkolom.
				if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) !== etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y))) {
					columnY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y);
					leftColumn = { x: topRightRaster.x, name: stairId + '_' + Columns.POSITION_LEFT, type: Columns.STAIR_TRIMMING_COLUMN, position: { x: this.startX - columnSize / 2, y: columnY } };
				}

				// Wanneer rechtsboven en rechtsonder niet in hetzelfde raster liggen en 1 van de 2 is niet actief is een extra kolom nodig om trimming te bevestigen
				if (etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) !== etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) {
					columnY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y);
					rightColumn = {
						x: bottomRightRaster.y,
						name: stairId + '_' + Columns.POSITION_RIGHT,
						type: Columns.STAIR_TRIMMING_COLUMN,
						position: { x: this.startX + this.width + columnSize / 2, y: columnY },
					};
				}
			}

			if (leftColumn !== null) {
				columns.push(-1, leftColumn.x, leftColumn.name, leftColumn.type, leftColumn.position);
			}
			if (rightColumn !== null) {
				columns.push(-1, rightColumn.x, rightColumn.name, rightColumn.type, rightColumn.position);
			}
			if (topColumn !== null) {
				columns.push(-1, topColumn.x, topColumn.name, topColumn.type, topColumn.position);
			}
			if (bottomColumn !== null) {
				columns.push(-1, bottomColumn.x, bottomColumn.name, bottomColumn.type, bottomColumn.position);
			}
		}
	}

	setMinMax(min, max, rectangle) {
		if (min.x > rectangle.leftTop.x) {
			min.x = rectangle.leftTop.x;
		}
		if (min.y > rectangle.leftTop.y) {
			min.y = rectangle.leftTop.y;
		}
		if (max.x < rectangle.rightBottom.x) {
			max.x = rectangle.rightBottom.x;
		}
		if (max.y < rectangle.rightBottom.y) {
			max.y = rectangle.rightBottom.y;
		}
	}
	calculateAmount(params) {
		this.trimmings.calculateAmount(params);
	}

	calculate(parentObject) {
		// Ophalen van alle drawobjecten die meegerekend moeten worden (Hoogte van drawobject is te klein dus trapgat moet er op aangepast worden).
		// Kleinste X Y bepalen, op basis van upComing dan marges toepassen.
		// Daarna boundaries bepalen.
		const minMaxBoundariesStairWell = parentObject.getMinMaxStairWellBoundaries();

		if (minMaxBoundariesStairWell !== null) {
			this.startX = minMaxBoundariesStairWell.minX;
			this.startY = minMaxBoundariesStairWell.minY;
			this.width = minMaxBoundariesStairWell.maxX - minMaxBoundariesStairWell.minX;
			this.depth = minMaxBoundariesStairWell.maxY - minMaxBoundariesStairWell.minY;

			switch (parentObject.upComing) {
				case Stair.UPCOMING_TOP:
					this.startX -= Stairs.PASSAGE_WIDTH / 2;
					this.width += Stairs.PASSAGE_WIDTH;
					this.depth += Stairs.PASSAGE_WIDTH / 2;
					break;
				case Stair.UPCOMING_BOTTOM:
					this.startX -= Stairs.PASSAGE_WIDTH / 2;
					this.width += Stairs.PASSAGE_WIDTH;
					this.startY -= Stairs.PASSAGE_WIDTH / 2;
					this.depth += Stairs.PASSAGE_WIDTH / 2;
					break;
				case Stair.UPCOMING_RIGHT:
					this.startX -= Stairs.PASSAGE_WIDTH / 2;
					this.width += Stairs.PASSAGE_WIDTH / 2;
					this.startY -= Stairs.PASSAGE_WIDTH / 2;
					this.depth += Stairs.PASSAGE_WIDTH;
					break;
				case Stair.UPCOMING_LEFT:
					this.width += Stairs.PASSAGE_WIDTH / 2;
					this.startY -= Stairs.PASSAGE_WIDTH / 2;
					this.depth += Stairs.PASSAGE_WIDTH;
					break;
			}

			this.boundaries = [
				{
					topLeft: { x: this.startX, y: this.startY },
					topRight: { x: this.startX + this.width, y: this.startY },
					bottomLeft: { x: this.startX, y: this.startY + this.depth },
					bottomRight: { x: this.startX + this.width, y: this.startY + this.depth },
				},
			];
		}
	}

	addDrawObjects(params, parentObject) {
		if (this.active === false) {
			return [];
		}

		let stairWell;
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			stairWell = new Rectangle(
				new DrawValue(this.startX),
				new DrawValue(this.startY),
				new DrawValue(this.width),
				new DrawValue(this.depth),
				this.hasErrors === true ? Stairs.COLORS.stairCollisions : Stairs.COLORS.stairWell,
				Stairs.COLORS.stairWell,
				null,
				true,
				params.stairObject,
				{ type: 'stairWell', color: Stairs.COLORS.stairWell },
			);
		} else {
			stairWell = new Rectangle(
				new DrawValue(this.startX),
				new DrawValue(this.startY),
				new DrawValue(this.width),
				new DrawValue(this.depth),
				this.hasErrors === true ? Stairs.COLORS.stairCollisions : Stairs.COLORS.stairWell,
				Stairs.COLORS.stairWell,
				null,
				true,
				params.stairObject,
				{ type: 'stairWell', color: Stairs.COLORS.stairWell },
			);
		}
		stairWell.colorFromParent = false;
		// Pas pushen na aanmaken drawObjecten, anders fout.
		let stairWellGroup = [];
		stairWellGroup.push(stairWell);

		this.trimmings.addDrawObjects(parentObject).forEach((drawObject) => {
			stairWellGroup.push(drawObject);
		});

		return stairWellGroup;
	}
}
