import { Data } from './data';
import { Translations } from './translations';

export class Configuration extends Data {
	objectName = 'Configuration';
	id = -1;
	reference = '';
	url = 'Configurations';
	title = '';
	state = '';
	date = '';
	quotationNumber = '';
	system = 1;
	readOnly = false;
	revisionPossible = false;
	duplicateToSameQuotationInConfigurator = false;
	duplicateToNewQuotationInConfigurator = true;
	image2DAvailable = false;
	image3DAvailable = false;
	json2DAvailable = false;
	json3DAvailable = false;

	get stateText() {
		if (typeof this.state === 'undefined' || this.state === null) {
			return '';
		}

		if (typeof this.store !== 'undefined' && this.store !== null) {
			return Translations.CURRENT.translate('configuration.' + this.state.name.replace(/ /g, ''));
		} else {
			return this.state.name;
		}
	}
}
