import { Functions } from '../helpers/functions';
import { ColumnProtectors } from './columnProtectors';
import { Companies } from './companies';
import { Quotations } from './quotations';
import { User } from './user';
import { DataAccess } from './dataAccess';
import { Projects } from './projects';
import { Invoices } from './invoices';
import { Configurations } from './configurations';
import { CenterToCenterDistances } from './centerToCenterDistances';
import { Countries } from './countries';
import { FallingProtectionStandards } from './fallingProtectionStandards';
import { FloorDeflections } from './floorDeflections';
import { DeckingFinishes } from './deckingFinishes';
import { Finishes } from './finishes';
import { PalletGates } from './palletGates';
import { RalColors } from './ralColors';
import { Stairs } from './stairs';
import { HandRails } from './handRails';
import { LedOptions } from './ledoptions';
import { FloorVariableLoads } from './floorVariableLoads';
import { Languages } from './languages';
import { StairSteps } from './stairSteps';
import { Calculate } from './calculate';
import { Services } from './services';
import { FinishDefaults } from './finishDefaults';
import { ConsequenceClasses } from './consequenceClasses';
import { Models } from './models/models';
import { MockupData } from './mockupData';
import { Leaflets } from './leaflets';

import { MiscellaneousArticles } from './miscellaneousArticles';
import { DeckingFinishesModels } from './models/deckingFinishesModels';
import { ColumnProtectorsModels } from './models/columnProtectorsModels';
import { ProfileModels } from './models/profileModels';
import { RalMaterials } from './ralMaterials';
import { FloorApplications } from './floorApplications';
import { Building } from './building';
import { ContactPersons } from './contactPersons';
import { StairSettings } from './stairSettings';
import { CageLadderMaterials } from './cageLadderMaterials';
import { CrossBracings } from './crossBracings';
import { TorsionalSupportArticle } from './torsionalSupportArticle';
import { IntroImages } from './introImages';
import { CageLadders } from './cageLadders';
import { TransportDeliveries } from './logistics/transportDeliveries';

export class Store {
	static LOADING = 0;
	static ERROR = 5;
	static LOADED = 10;

	static _CURRENT = null;
	static get CURRENT() {
		if (Store._CURRENT === null || typeof Store._CURRENT === 'undefined') {
			Store._CURRENT = new Store();
		}
		return Store._CURRENT;
	}
	static set CURRENT(value) {
		// Store._CURRENT = value;
	}

	statusLoad = {};
	companies = new Companies(true);
	quotations = new Quotations();
	projects = new Projects();
	invoices = new Invoices();
	configurations = new Configurations();
	leaflets = new Leaflets();
	user = new User();
	introImages = new IntroImages();

	centerToCenterDistances = new CenterToCenterDistances();
	countries = new Countries(true);
	consequenceClasses = new ConsequenceClasses();
	fallingProtectionStandards = new FallingProtectionStandards();
	miscellaneousArticles = new MiscellaneousArticles();
	floorDeflections = new FloorDeflections();
	deckingFinishes = new DeckingFinishes();
	finishes = new Finishes();
	palletGates = new PalletGates();
	ralColors = new RalColors();
	finishDefaults = new FinishDefaults();
	stairs = new Stairs();
	stairSettings = new StairSettings();
	cageLadders = new CageLadders();
	handRails = new HandRails();
	stairSteps = new StairSteps();
	floorVariableLoads = new FloorVariableLoads();
	languages = new Languages();
	columnProtectors = new ColumnProtectors();
	mockupData = new MockupData();

	services = new Services();
	transportDeliveries = new TransportDeliveries();

	dataAccess = new DataAccess();
	calculate = new Calculate();
	torsionalSupportArticle = new TorsionalSupportArticle();
	models = new Models();
	deckingFinishesModels = new DeckingFinishesModels();
	columnProtectorsModels = new ColumnProtectorsModels();
	profileModels = new ProfileModels();
	ralMaterials = new RalMaterials();
	floorApplications = new FloorApplications();
	building = new Building();
	contactPersons = new ContactPersons();
	crossBracings = new CrossBracings();
	ledOptions = new LedOptions();
	cageLadderMaterials = new CageLadderMaterials();

	checkLogin = true;
	constructor(force, checkLogin) {
		// voorlopig checkLogin. Met afgeleide store geen succes.
		// op termijn meerdere stores mogelijk. Dan aan constructor meegeven naam van store en selecteren. CURRENT wordt dan een array
		// default waarden zetten
		if (typeof force === 'undefined' || force === null) {
			force = false;
		}
		if (typeof checkLogin === 'undefined' || checkLogin === null) {
			checkLogin = true;
		}
		// als er nog geen store is of als er geforceerd een nieuwe store gemaakt moet worden
		if (Store._CURRENT === null || force === true) {
			this.checkLogin = checkLogin;
			// constructor verder afwerken. Object doorgeven
			Store._CURRENT = this;
			// de verschillende variabelen langs lopen
			Object.keys(this).forEach((object, index) => {
				// de verschillende variabelen langs lopen
				if (typeof this[object].setStore === 'function') {
					this[object].setStore(this); // hierdoor geen json mogelijk van dataobjecten. Bij problemen in data/dataAccess kijken naar CURRENT en op termijn naam van store meegeven
				}

				// als de functie restoreFromLocalStorage aanwezig is kijken of er wat opgeslagen is en dit ophalen uit de localstorage
				if (typeof this[object].restoreFromLocalStorage === 'function') {
					this[object].restoreFromLocalStorage();
				}
			});
			if (this.checkLogin === true) {
				if (this.dataAccess.tokenBasedAuthentication === true && (this.user.token.value === '' || (this.user.token.expires !== '' && new Date(this.user.token.expires) < Date.now()))) {
					// als token niet meer aanwezig is of niet geldig is dan url opslaan en doorsturen naar login
					this.user.callBackUrl = window.location.href;
					// window.location.href = '/login';
					// op ander moment springt hij ook al door
				}
				if (this.user.validated === false) {
					// als nog niet gevalideerd en het verzoek nog niet loopt
					if (this.user.validateRequest === null) {
						// verstuur verzoek en wacht op antwoord
						this.user.validate();
					}
				}
			}
		}

		return Store._CURRENT;
	}
	load(objects, cacheId, successCallBack, catchCallBack) {
		// id bijhouden om uniekheid te bewaren
		let id = Functions.uuidv4();
		this.statusLoad[id] = {};
		// doorloop meegegeven objecten
		objects.forEach((objectName) => {
			if (this[objectName].isLoaded(cacheId) === true) {
				// check of die al geladen is dan overslaan
				return;
			}

			// ** Als object al eens is geladen zal .list een object zijn, anders undefined.
			// ** We checken hierop zodat hij in de store.load niet ziet dat lastrequest niet null is en gewoon returned zonder enige informatie terug te geven. We laden dus dit gehele obect niet in, omdat dit al gedaan is.
			if (typeof this[objectName]._list.list !== 'object') {
				// sla object op als loading
				this.statusLoad[id][objectName] = Store.LOADING;

				this[objectName].list(
					null,
					null,
					cacheId,
					null,
					() => {
						// Bij succes dit opslaan
						this.statusLoad[id][objectName] = Store.LOADED;

						// ga na of alle objecten al geladen zijn.
						let allLoaded = true;
						Object.keys(this.statusLoad[id]).forEach((key) => {
							if (this.statusLoad[id][key] !== Store.LOADED && this[key].isLoaded(cacheId) === false) {
								// Bij een fout of loading weten we dat niet alles geladen is
								allLoaded = false;
							}
						});

						if (allLoaded === true && typeof successCallBack === 'function') {
							// als alles geladen is en er is een functie die we dan af moeten trappen dan nu doen
							successCallBack();
						}
					},
					() => {
						// bij fout dit opslaan als status eventueel kan bij een andere functie hierop ingespeeld worden (wel gedaan niet alles geladen)
						this.statusLoad[id][objectName] = Store.ERROR;
						if (typeof catchCallBack === 'function') {
							catchCallBack();
						}
					},
				);
			}
		});

		if (Object.keys(this.statusLoad[id]).length === 0) {
			if (typeof successCallBack === 'function') {
				// als alles al geladen is en er is een functie die we dan af moeten trappen dan nu doen
				successCallBack();
			}
		}
	}
	executePreLoad() {
		Object.keys(this).forEach((object, index) => {
			// de verschillende variabelen langs lopen
			if (typeof this[object].executePreLoad === 'function') {
				this[object].executePreLoad();
			}
		});
	}

	removeFromLocalStorage() {
		Object.keys(this).forEach((object, index) => {
			// de verschillende variabelen langs lopen
			if (typeof this[object].removeFromLocalStorage === 'function') {
				this[object].removeFromLocalStorage();
			}
		});
	}
}
