import { Data } from '../data';
import { Store } from '../store';
import { TransportDelivery } from './transportDelivery';

export class TransportDeliveries extends Data {
	objectName = 'TransportDeliveries';

	url = 'TransportDeliveries';
	publicUrl = 'TransportDeliveriesPublic';

	getTransportDeliveryPricesToDo(reload, thenCallBack) {
		return super.list({}, reload, 'todo', '/Todo', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesCancelled(reload, thenCallBack) {
		return super.list({}, reload, 'cancelled', '/Cancelled', thenCallBack, thenCallBack);
	}

	
	getTransportDeliveryPricesSubscribed(reload, thenCallBack) {
		return super.list({}, reload, 'subscribed', '/Subscribed', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesDeclined(reload, thenCallBack) {
		return super.list({}, reload, 'declined', '/Declined', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesAssigned(reload, thenCallBack) {
		return super.list({}, reload, 'asssigned', '/Assigned', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesDone(reload, thenCallBack) {
		return super.list({}, reload, 'done', '/Done', thenCallBack, thenCallBack);
	}

	getDeliveryInformation(guid, force, thenCallback, catchCallBack) {
		return super.get(this.fullPublicUrl + "/deliveryInformation?accessCode=" + guid, force, r => thenCallback(this.fetchListItem(r)), catchCallBack, guid, false);
	}
	get fullPublicUrl() {
		return Data.baseUrl + this.publicUrl;
	}
	completeDelivery(guid, thenCallBack, catchCallBack) {
		return this.store.dataAccess.post(this.fullPublicUrl + "/completeDelivery?accessCode=" + guid, false, false, null, null, false)
			.then(r => thenCallBack(this.fetchListItem(r)))
			.catch(catchCallBack);
	}
	fetchListItem(item) {
		// standaard teruggeven. In andere objecten kan hier een subobject gemaakt worden
		let returnItem = new TransportDelivery();
		returnItem.setStore(this.store);
		returnItem.fetch(item);

		return returnItem;
	}
}
