export class Statics {
	// White for holes, or advanced drawing for objects (like stair in floor or cageLadder / hole in the floor.
	static COLOR_WHITE = '#FFFFFF';

	// The default color for drawing objects:
	static COLOR_GREY = 'grey';

	// Collision for when object collildes with another object.
	// And for other type of errors (height, with, depth, outside floor etc).
	static COLOR_COLLISION = 'red';
	static COLOR_ERROR = 'red';

	// Or when hovering over a position which is made by calling the AddPossible position for the chosen object.
	static COLOR_POSITION_POSSIBLE = '#6BA3FF';

	// When hovering over an item / object make the outline / color green.
	static COLOR_MOUSE_OVER = 'green';

	// For when hovering over an possible position, we make it blue.
	// And for when we select
	static COLOR_POSITION_POSSIBLE_SELECTED = 'blue';
	static COLOR_SELECTED = 'blue';

	// Color for drawing mainbeams and trimming for objects (stair, cageladder, trimming).
	static COLOR_MAINBEAM = '#FCC43E';
}
