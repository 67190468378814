import { Configuration } from '../configurator/configuration';
import { Dimensions } from './dimensions';
import { DrawValue } from './drawValue';
import { ObjectGroup } from './objectGroup';
import { DrawText } from './drawText';
import { SizeHandle } from './sizeHandle';
import { Line } from './line';
import { Store } from '../data/store';
import { Mathematic } from '../helpers/mathematic';
import { Canvas } from './canvas';
import { Profiles } from '../configurator/profiles';

export class DimensionsVertical extends Dimensions {
	objectName = 'DimensionsVertical';
	startPosition = 0;

	onMouseDrag(evt, drawObject) {
		let changingDimensionIndex = drawObject.objectParams.position;
		const moveY = evt.delta.y / Canvas.CURRENT.scaleFactor;

		// Wanneer we slepen verslepen we een handle tussen 2 dimensions in.
		// Next dimension dan rechts van de hendel, Prev dimension dan links van de hendel.
		let newLengthNextDimension = 0;
		let newLengthPrevDimension = 0;

		if (changingDimensionIndex < this.dimensions.length) {
			newLengthPrevDimension = Math.round(this.dimensions[changingDimensionIndex] + moveY);
		}
		if (changingDimensionIndex + 1 < this.dimensions.length) {
			newLengthNextDimension = Math.round(this.dimensions[changingDimensionIndex + 1] - moveY);
		}
		if (
			this.movePossible({
				x: -1,
				y: changingDimensionIndex + 1,
				newLengthPrevDimension: newLengthPrevDimension,
				newLengthNextDimension: newLengthNextDimension,
			}) === false
		) {
			return;
		}

		if (changingDimensionIndex < this.dimensions.length) {
			this.dimensions[changingDimensionIndex] = newLengthPrevDimension;
		}
		if (changingDimensionIndex + 1 < this.dimensions.length) {
			this.dimensions[changingDimensionIndex + 1] = newLengthNextDimension;
		}

		let params = {};
		params.move = moveY;
		params.raster = changingDimensionIndex + 1;

		this.onChange(evt, drawObject, params);
		return { stopPropagation: true };
	}
	addDrawObjects(params) {
		// maattekst
		let posX = params.sizeHandleSpace;

		let posY = 0;
		if (typeof params.startPosition !== 'undefined' && params.startPosition !== null) {
			posY = params.startPosition;
		}
		if (typeof this.startPosition !== 'undefined') {
			posY = this.startPosition;
		}

		const sizeHandleGroup = new ObjectGroup(SizeHandle.COLOR, null, null, false, this, { type: 'sizeHandle' });

		this.dimensions.forEach((dimension, index) => {
			dimension = Math.abs(dimension);
			// Vaststellen of we de sizehandle kunnen resizen.Mag alleen het tussenstukje (index niet eerste en laatste).
			// Tussenstuk is altijd het object wat we willen verslepen.
			const sizeHandleResizable = index === 0 || index === this.dimensions.length - 1;
			const drawGroup = new ObjectGroup(SizeHandle.COLOR, null, null, false, this, { direction: 'vertical', pos: index, type: 'sizeGroup' }); // direction is richting van waarin de sizehandle beweegt.
			drawGroup.push(
				new DrawText(
					new DrawValue(Canvas.CURRENT.min.x, -posX),
					new DrawValue(posY),
					Store.CURRENT.companies.selectedCompany.measurementSystem.imperial === false ? dimension : Mathematic.ImperialToInputString(dimension),
					new DrawValue(dimension),
					null,
					null,
					null,
					null,
					'center',
					true,
					// Drawtext alleen klikbaar maken als resizeAble word meegegeven.
					// Bij type Object is dit het geval, en ook bij type raster.
					sizeHandleResizable || params.resizable,
					this,
					{
						type: 'text',
						position: index,
					},
				),
			);

			drawGroup.push(
				new Line(
					new DrawValue(Canvas.CURRENT.min.x, -posX + 5),
					new DrawValue(posY),
					new DrawValue(Canvas.CURRENT.min.x, -posX + 5),
					new DrawValue(posY + dimension),
					null,
					null,
					null,
					null,
					null,
					false,
					this,
					{
						type: 'size',
					},
				),
			);
			drawGroup.push(
				new Line(
					new DrawValue(Canvas.CURRENT.min.x, -posX),
					new DrawValue(posY),
					new DrawValue(Canvas.CURRENT.min.x, -posX + 10),
					new DrawValue(posY),
					null,
					null,
					null,
					null,
					null,
					params.resizable,
					this,
					{
						type: 'sizeHandleStart',
						position: index,
					},
				),
			);
			drawGroup.push(
				new Line(
					new DrawValue(Canvas.CURRENT.min.x, -posX),
					new DrawValue(posY + dimension),
					new DrawValue(Canvas.CURRENT.min.x, -posX + 10),
					new DrawValue(posY + dimension),
					null,
					null,
					null,
					null,
					null,
					params.resizable,
					this,
					{
						type: 'sizeHandleEnd',
						position: index,
					},
				),
			);

			sizeHandleGroup.push(drawGroup);
			posY += dimension;
		});
		Canvas.CURRENT.addDrawObject(sizeHandleGroup);

		return sizeHandleGroup;
	}
	onClick(evt, drawObject) {
		const betweenMouseArea = evt.layerX < drawObject.mouseArea.maxX && evt.layerX > drawObject.mouseArea.minX;
		if (drawObject.objectName === 'DrawText' && betweenMouseArea) {
			Configuration.CURRENT.dimensioning.onClickedDimensionItem(drawObject.objectParams.position, Profiles.MB_VERTICAL, this.dimensions, (dimensions, changedDimensionIndex) => {
				this.onChanged(evt, drawObject, dimensions, changedDimensionIndex);
			});
		}
		return { stopPropagation: true };
	}
	updateDrawObjects(groupObjectVertical, dimensions) {
		dimensions = dimensions.map(Math.round);
		groupObjectVertical.drawObjects.forEach((drawGroup, index) => {
			let drawText = drawGroup.drawObjects.find((drawobject) => drawobject instanceof DrawText);
			let size = drawGroup.drawObjects.find((drawobject) => drawobject.objectParams.type === 'size');
			let beginPart = drawGroup.drawObjects.find((drawobject) => drawobject.objectParams.type === 'sizeHandleStart');
			let endPart = drawGroup.drawObjects.find((drawobject) => drawobject.objectParams.type === 'sizeHandleEnd');

			// !! Aanpassen verticale lijntje einde 1e dimentie.
			if (index === 0) {
				// When dimsension 0 smaller than 0, item is party outside of floor.
				if (dimensions[0] < 0) {
					if (beginPart !== null && typeof beginPart !== 'undefined') {
						beginPart.y = new DrawValue(dimensions[0]);
						beginPart.eindY = new DrawValue(dimensions[0]);
					}
					if (size !== null && typeof size !== 'undefined') {
						size.y = new DrawValue(dimensions[0]);
						size.eindY = new DrawValue(0);
					}
					if (endPart !== null && typeof endPart !== 'undefined') {
						endPart.y = new DrawValue(0);
						endPart.eindY = new DrawValue(0);
					}
				} else {
					if (beginPart !== null && typeof beginPart !== 'undefined') {
						beginPart.y = new DrawValue(0);
						beginPart.eindY = new DrawValue(0);
					}
					if (size !== null && typeof size !== 'undefined') {
						size.y = new DrawValue(0);
						size.eindY = new DrawValue(dimensions[0]);
					}
					if (endPart !== null && typeof endPart !== 'undefined') {
						endPart.y = new DrawValue(dimensions[0]);
						endPart.eindY = new DrawValue(dimensions[0]);
					}
				}

				if (drawText !== null && typeof drawText !== 'undefined') {
					drawText.y = new DrawValue(0);
					drawText.width = new DrawValue(dimensions[0]);
					drawText.text = Store.CURRENT.companies.selectedCompany.measurementSystem.imperial ? Mathematic.ImperialToInputString(dimensions[0]) : Math.abs(dimensions[0]);
				}
			}

			// !! Aanpassen verticale lijntjes tussendimenties, dus bij slepen de breedte van het gesleepte element.
			if (index === 1) {
				if (dimensions[0] < 0) {
					if (beginPart !== null && typeof beginPart !== 'undefined') {
						beginPart.y = new DrawValue(0);
						beginPart.eindY = new DrawValue(0);
					}
					if (size !== null && typeof size !== 'undefined') {
						size.y = new DrawValue(0);
						size.eindY = new DrawValue(dimensions[1]);
					}
					if (endPart !== null && typeof endPart !== 'undefined') {
						endPart.y = new DrawValue(dimensions[1]);
						endPart.eindY = new DrawValue(dimensions[1]);
					}
					if (drawText !== null && typeof drawText !== 'undefined') {
						drawText.y = new DrawValue(0);
						drawText.width = new DrawValue(dimensions[1]);
						drawText.text = Store.CURRENT.companies.selectedCompany.measurementSystem.imperial ? Mathematic.ImperialToInputString(dimensions[1]) : dimensions[1];
					}
				} else {
					if (beginPart !== null && typeof beginPart !== 'undefined') {
						beginPart.y = new DrawValue(dimensions[0]);
						beginPart.eindY = new DrawValue(dimensions[0]);
					}
					if (size !== null && typeof size !== 'undefined') {
						size.y = new DrawValue(dimensions[0]);
						size.eindY = new DrawValue(dimensions[0] + dimensions[1]);
					}
					if (endPart !== null && typeof endPart !== 'undefined') {
						endPart.y = new DrawValue(dimensions[0] + dimensions[1]);
						endPart.eindY = new DrawValue(dimensions[0] + dimensions[1]);
					}
					if (drawText !== null && typeof drawText !== 'undefined') {
						drawText.y = new DrawValue(dimensions[0]);
						drawText.width = new DrawValue(dimensions[1]);
						drawText.text = Store.CURRENT.companies.selectedCompany.measurementSystem.imperial ? Mathematic.ImperialToInputString(dimensions[1]) : dimensions[1];
					}
				}
			}
			// !! Aanpassen verticale lijntje laatste dimentie.
			if (index === 2) {
				if (dimensions[0] < 0) {
					if (beginPart !== null && typeof beginPart !== 'undefined') {
						beginPart.y = new DrawValue(dimensions[1]);
						beginPart.eindY = new DrawValue(dimensions[1]);
					}
					if (size !== null && typeof size !== 'undefined') {
						size.y = new DrawValue(dimensions[1]);
						size.eindY = new DrawValue(dimensions[1] + dimensions[2]);
					}
					if (endPart !== null && typeof endPart !== 'undefined') {
						endPart.y = new DrawValue(dimensions[1] + dimensions[2]);
						endPart.eindY = new DrawValue(dimensions[1] + dimensions[2]);
					}
					if (drawText !== null && typeof drawText !== 'undefined') {
						drawText.y = new DrawValue(dimensions[1]);
						drawText.width = new DrawValue(dimensions[2]);
						drawText.text = Store.CURRENT.companies.selectedCompany.measurementSystem.imperial ? Mathematic.ImperialToInputString(dimensions[2]) : dimensions[2];
					}
				} else {
					if (beginPart !== null && typeof beginPart !== 'undefined') {
						beginPart.y = new DrawValue(dimensions[0] + dimensions[1]);
						beginPart.eindY = new DrawValue(dimensions[0] + dimensions[1]);
					}
					if (size !== null && typeof size !== 'undefined') {
						size.y = new DrawValue(dimensions[0] + dimensions[1]);
						size.eindY = new DrawValue(dimensions[0] + dimensions[1] + dimensions[2]);
					}
					if (endPart !== null && typeof endPart !== 'undefined') {
						endPart.y = new DrawValue(dimensions[0] + dimensions[1] + dimensions[2]);
						endPart.eindY = new DrawValue(dimensions[0] + dimensions[1] + dimensions[2]);
					}
					if (drawText !== null && typeof drawText !== 'undefined') {
						drawText.y = new DrawValue(dimensions[0] + dimensions[1]);
						drawText.width = new DrawValue(dimensions[2]);
						drawText.text = Store.CURRENT.companies.selectedCompany.measurementSystem.imperial ? Mathematic.ImperialToInputString(dimensions[2]) : dimensions[2];
					}
				}
			}
		});
	}
}
