import { ObjectGroup } from '../draw/objectGroup';
import { Store } from '../data/store';

import { Functions } from '../helpers/functions';
import { Configuration } from './configuration';
import { RemoveRaster } from './removeRaster';
import { Column } from './column';
import { Canvas } from '../draw/canvas';
import { BuildingColumn } from './buildingColumn';
import { Profiles } from './profiles';
import { Hole } from './hole';
export class Columns {
	objectName = 'Columns';
	objectName3d = 'column';

	static COLUMN_SIZE = 6;
	static COLOR = { column: '#000000', pressure: '#000', onMove: 'green', selected: 'blue' };
	static GENERATED = 0;
	static USERADDED = 1;
	static BUILDING_COLUMN = 2;
	static STAIR_TRIMMING_COLUMN = 3;
	static HOLE_COLUMN = 4;

	static TYPE_MIDDLECOLUMN = 0;
	static TYPE_BORDER = 1;
	static TYPE_MULTILEVELCOLUMN = 4;

	static POSITION_TOP = 0;
	static POSITION_BOTTOM = 1;
	static POSITION_LEFT = 2;
	static POSITION_RIGHT = 3;

	defaultBasePlate = { name: '', height: 10 };
	defaultBracket = { name: '', height: 10 };
	defaultGussetPlate = { name: '', height: 220 };
	debugInfo = {
		variableLoad: '',
		safetyFactorPermanentLoad: '',
		safetyFactorVariableLoad: '',
		deflection: '',
		weightPm2: '',
		columnsToCalculate: {},
		heightColumn: '',
		permanentLoad: '',
		materialFactorHf1: '',
		profilesDouble: '',
	};
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];
		this.columns.forEach((column) => {
			let columnResult = column.collisions(boundaries, self);
			if (columnResult.result === true) {
				collisionsDetect = true;
				columnResult.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult, objectName: this.objectName };
	}

	maxPressure = 0;
	amountColumnProtectors = [];
	amount = {};
	column = { name: '', kilogramPerMeter: 0, basePlate: { name: '', height: 10 }, bracket: { name: '', height: 10 }, width: 0 };
	static PRESSUREOFFSET = { x: 0, y: 18 };
	static TYPEOFFSET = { x: 0, y: 4 };
	length = 0; // kolomlengte over alle verdiepingen heen
	columns = [];
	columnProtectors = [];

	_showColumnPressure = true;
	get basePlate() {
		// om onverklaarbare wijze wordt baseplate null
		if (typeof this.column === 'undefined' || this.column === null || typeof this.column.basePlate === 'undefined' || this.column.basePlate === null) {
			return this.defaultBasePlate;
		}
		return this.column.basePlate;
	}
	select(parameters) {
		this.columns.forEach((column) => {
			column.select(parameters);
		});
	}
	get bracket() {
		// om onverklaarbare wijze wordt baseplate null
		if (typeof this.column === 'undefined' || this.column === null || typeof this.column.bracket === 'undefined' || this.column.bracket === null) {
			return this.defaultBracket;
		}
		return this.column.bracket;
	}
	get gussetPlate() {
		return this.column.gussetPlate;
	}
	get showColumnPressure() {
		return this._showColumnPressure;
	}
	set showColumnPressure(value) {
		if (value !== this._showColumnPressure) {
			this._showColumnPressure = value;
			this.onChange();
		}
	}

	_onChange = null;
	constructor(onChange) {
		this._onChange = onChange;
		setTimeout(() => {
			this.init();
		});
	}
	init() {
		if (typeof this.onMainBeamDirectionChange === 'function') {
			Configuration.CURRENT.profiles.onMainBeamDirectionChangeEvents.push((oldValue, newValue) => {
				this.onMainBeamDirectionChange(oldValue, newValue);
			});
		}
	}
	onMainBeamDirectionChange(oldValue, newValue) {
		this.columns.forEach((column) => {
			column.positionOffset = { x: 0, y: 0 };
		});
	}
	setReferences(params) {
		this._onChange = params.onChange;

		this.columns.forEach((column) => {
			column.setReferences({
				onChange: this.onChange.bind(this),
			});
		});
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	groupBy(list) {
		const map = new Map();
		list.forEach((item) => {
			const key = item.numberOfLevels + '-' + item.type + '-' + item.height;
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}
	getAmount() {
		let amount = { Columns: [], ColumnProtectors: [] };

		let columns = this.getGroupedColumns();
		columns.forEach((value, key) => {
			amount.Columns.push({
				id: this.column.salesProductId,
				name: this.column.name,
				amount: value.length,
				length: value[0].column.height,
				columnType: value[0].column.columnType,
				numberOfFloors: value[0].column.numberOfLevels,
			});
		});

		this.amountColumnProtectors.forEach((cp) => {
			// Voor nu gewoon 1 uit de lijst ophalen omdat dit waarschijnlijk salesproducts zijn.
			// Sales afdeling PRN houd nu geen rekening met afmetingen
			// later moment op basis van id (vanuit selectcolumn -> cp) de juiste ophalen en meesturen naar ERP.
			// Dan is dat ook de goeie qua afmetingen hicad/viwer etc.
			amount.ColumnProtectors.push({ id: Store.CURRENT.columnProtectors.getColumnProtector(cp.position.length > 1 ? 'quarter' : 'half').id, amount: cp.amount, isCorner: cp.isCorner });
		});
		return amount;
	}
	getGroupedColumns() {
		let columns = [];

		this.columns
			.filter((c) => c.columnActive === true)
			.forEach((column) => {
				columns.push({
					type: column.columnType,
					numberOfLevels: column.numberOfLevels,
					height: column.height,
					column: column,
				});
			});

		return this.groupBy(columns);
	}

	get amountColumns() {
		return this.columns.filter((c) => c.columnActive === true).length;
	}
	getHeight(etage, heightOnTop) {
		if (typeof heightOnTop === 'undefined' || heightOnTop === null) {
			heightOnTop = true;
		}
		const etages = Configuration.CURRENT.etages.getEtages();
		if (typeof etage === 'undefined' || etage === null) {
			let height = 0;
			etages.forEach((etage, index) => {
				if (heightOnTop === false) {
					// als niet hoogte bovenkant vloer maar onderkant vloer dan bij alle tussenverdiepingen wel de vloer meetellen. Alleen bij bovenste niet
					height += etage.getHeight(index !== etages.length - 1);
				} else {
					height += etage.getHeight(heightOnTop);
				}
			});
			return height;
		} else if (etage < etages.length) return etages[etage].getHeight(heightOnTop);
	}
	getPositionX(x, y) {
		let columnX = Configuration.CURRENT.raster.getSizeX(x - 1);
		let column = this.find(x, y);

		if (column !== null) {
			columnX += column.positionOffset.x;
		}

		return columnX;
	}
	getPositionY(x, y) {
		let columnY = Configuration.CURRENT.raster.getSizeY(y - 1);
		let column = this.find(x, y);

		if (column !== null) {
			columnY += column.positionOffset.y;
		}
		return columnY;
	}
	getPosition(x, y) {
		return { x: this.getPositionX(x, y), y: this.getPositionY(x, y) };
	}
	getRowPositions(x, y) {
		let foundColumn = this.find(x, y);
		return foundColumn.rowPositions;
	}
	getPlacement(x, y) {
		let foundColumn = this.find(x, y);
		return foundColumn.placement;
	}
	find(x, y) {
		let gevonden = null;
		this.columns.forEach((column, index) => {
			if (column.equals(x, y)) {
				gevonden = column;
			}
		});
		return gevonden;
	}
	contains(x, y) {
		let gevonden = null;
		this.columns.forEach((column, index) => {
			if (column.equals(x, y)) {
				gevonden = column;
			}
		});
		return gevonden != null;
	}
	findIndex(x, y) {
		let gevonden = -1;
		this.columns.forEach((column, index) => {
			if (column.equals(x, y)) {
				gevonden = index;
			}
		});
		return gevonden;
	}
	getMaxColumnPressure() {
		let maxPressure = 0;
		this.columns.forEach((column, index) => {
			if (column.pressure > maxPressure) {
				maxPressure = column.pressure;
			}
		});
		return maxPressure;
	}
	getMaxColumnPressureWithoutSafetyFactor() {
		let pressureWithoutSafetyFactor = 0;
		this.columns.forEach((column, index) => {
			if (column.pressureWithoutSafetyFactor > pressureWithoutSafetyFactor) {
				pressureWithoutSafetyFactor = column.pressureWithoutSafetyFactor;
			}
		});
		return pressureWithoutSafetyFactor;
	}
	getByName(name) {
		let found = null;
		this.columns.forEach((column, index) => {
			if (column.name === name) {
				found = column;
				return column;
			}
		});
		// Kolom niet gevonden.
		return found;
	}
	getById(id) {
		// door gebouwkolom kan getbyname meerdere resultaten opleveren
		let found = null;
		this.columns.forEach((column, index) => {
			if (column.id === id) {
				found = column;
				return column;
			}
		});
		return found;
	}
	push(x, y, name, type, position, configurationObjectId) {
		if (typeof name === 'undefined' || name === null) {
			name = 'x' + x + 'y' + y;
		}
		let column = this.columns.push(new Column(x, y, name, type, position, configurationObjectId));
		this.columns[column - 1].setReferences({
			onChange: this.onChange.bind(this),
		});

		return this.columns[column - 1];
	}
	pushUnique(x, y, name, type) {
		let column = this.find(x, y);
		// als niet gevonden
		if (column === null) {
			column = this.push(x, y, name, type);
		}
		return column;
	}
	markAllNotFound() {
		this.columns.forEach((column, index) => {
			column.found = false;
		});
	}
	removeNotFound() {
		const toSlice = [];
		this.columns.forEach((column, index) => {
			if (column.found === false && column.type === Columns.GENERATED) {
				toSlice.push(index);
			}
		});

		toSlice.forEach((item, index) => {
			this.columns.splice(item - index, 1);
		});
	}
	removeByName(name) {
		this.columns = this.columns.filter((column) => column.name !== name);
	}
	AssignBeamsToColumn(raster, mainBeamDirection) {
		this.columns.forEach((column) => {
			let position = column.getPosition();
			let rasterPosition = raster.getRasterByCoordinate(position.startX.value, position.startY.value);

			let spanX = raster.spansX.getSpans()[rasterPosition.x];
			let spanY = raster.spansY.getSpans()[rasterPosition.y];

			if (typeof spanX === 'undefined' || typeof spanY === 'undefined') {
				spanX = raster.spansX.getSpans()[rasterPosition.x > -1 ? rasterPosition.x : raster.spansX.getSpans().length - 1];
				spanY = raster.spansY.getSpans()[rasterPosition.y > -1 ? rasterPosition.y : raster.spansY.getSpans().length - 1];
			}

			if (mainBeamDirection === 'x') {
				column.childBeamId = spanY.beam.oid;
				column.mainBeamId = spanX.beam.oid;
				column.mainbeamHeight = spanX.beam.height;
			} else {
				column.childBeamId = spanX.beam.oid;
				column.mainBeamId = spanY.beam.oid;
				column.mainbeamHeight = spanY.beam.height;
			}
		});
	}

	calculate(raster, etages, mainBeamDirection) {
		// verzamel eerst alle kolommen
		this.markAllNotFound();
		// per raster een kolom toevoegen. Kolom links boven
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				const column = this.pushUnique(indexX, indexY);

				column.found = true;
				column.setActive(etages);
				column.setTypeAndPosition();
			});
		});

		// Voor totaal raster per horizontale rij een extra kolom toevoegen. X-kolom is gelijk aan lengte array raster.spansX (array is van 0 - length - 1)
		raster.spansY.getSpans().forEach((spanY, indexY) => {
			const column = this.pushUnique(raster.spansX.length, indexY);
			column.found = true;
			column.offset = { x: -Columns.COLUMN_SIZE / 2, y: 0 };
			column.setActive(etages);
			column.setTypeAndPosition();
		});
		// Voor totaal raster per verticale rij een extra kolom toevoegen. y-kolom is gelijk aan lengte array raster.spansY (array is van 0 - length - 1)
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			const column = this.pushUnique(indexX, raster.spansY.length);
			column.found = true;

			column.setActive(etages);
			column.setTypeAndPosition();
		});

		// laatste hoekkolom
		const column = this.pushUnique(raster.spansX.length, raster.spansY.length);
		column.found = true;
		column.offset = { x: -Columns.COLUMN_SIZE / 2, y: 0 };

		column.setActive(etages);
		column.setTypeAndPosition();
		this.removeNotFound();
		this.length = this.getHeight(null, true);
		let requestID = Functions.uuidv4();
		Configuration.CURRENT.startCalculationRequest(requestID);
		this.AssignBeamsToColumn(raster, mainBeamDirection);

		this.AssignBeamsToColumn(raster, mainBeamDirection);

		// let mainBeamOid = Configuration.CURRENT.profiles.mainBeam.oid;
		// let childBeamOid = Configuration.CURRENT.profiles.childBeam.oid;

		if (typeof mainBeamOid === 'undefined' && typeof childBeamOid === 'undefined') {
			// mainBeamOid = Configuration.CURRENT.profiles.amounts.mb[0].oid;
			// childBeamOid = Configuration.CURRENT.profiles.amounts.sb[0].oid;
		}

		// this.AssignBeamsToColumn(raster, mainBeamDirection);

		let data = {
			countryCode: Configuration.CURRENT.countryCode,
			maxHeight: Configuration.CURRENT.profiles.maxHeight,
			step: Configuration.CURRENT.step,
			deflection: Configuration.CURRENT.profiles.deflection,
			variableLoad: Configuration.CURRENT.variableLoad,
			centerToCenterDistance: Configuration.CURRENT.profiles.centerToCenter,
			useHotFormed: Configuration.CURRENT.profiles.hotFormedPossible,
			useDoubleDouble: Configuration.CURRENT.profiles.doubleDoublePossible,
			profileSameHeight: Configuration.CURRENT.profiles.sameHeight,
			etages: [],
			raster: { removeRasters: etages.getRemoveRasters(), rasterX: Configuration.CURRENT.raster.spansX.getSpans(), rasterY: Configuration.CURRENT.raster.spansY.getSpans() },
			columns: this.columns,
			profilesDouble: Configuration.CURRENT.profiles.doubleDouble,
			heightColumn: this.length,
			debugMode: Configuration.CURRENT.debugMode,
			calculateWithNotInContract: Configuration.CURRENT.calculateWithNotInContract,
			companyId: Store.CURRENT.companies.selectedCompany.id,
			consequenceClass: Configuration.CURRENT.consequenceClass,
			mainBeamDirection: Configuration.CURRENT.profiles.mainBeamDirection,
			deckingFinishId: Configuration.CURRENT.finish.selected.id,
			floorApplicationsOid: Configuration.CURRENT.floorApplication,
			// mainBeamId: mainBeamOid,
			// childBeamId: childBeamOid,
		};
		etages.getEtages().forEach((etage) => {
			data.etages.push(etage.getSpecifications());
		});
		Store.CURRENT.calculate.selectColumn(
			data,
			(response) => {
				if ((response.column === null || response.column.column === null) && Configuration.CURRENT.newConfiguration === false) {
					this.$alert({ isHtml: true, title: window.Vue.$translate('alert.title.noColumnFound'), content: window.Vue.$translate('alert.content.noColumnFound') });
				} else {
					this.maxPressure = response.maxPressure;

					this.column = response.column.column;
					this.column.width = response.column.column._h_col;

					this.column.salesProductId = response.column.salesProductId;

					if (typeof this.column.basePlate === 'undefined' || this.column.basePlate === null) {
						this.column.basePlate = this.defaultBasePlate;
					}
					if (typeof this.column.bracket === 'undefined' || this.column.bracket === null) {
						this.column.bracket = this.defaultBracket;
					}
					if (typeof this.column.gussetPlate === 'undefined' || this.column.gussetPlate === null) {
						this.column.gussetPlate = this.defaultGussetPlate;
					}
					if (Configuration.CURRENT.debugMode === true) {
						this.debugInfo = {
							variableLoad: response.variableLoad,
							safetyFactorPermanentLoad: response.safetyFactorPermanentLoad,
							safetyFactorVariableLoad: response.safetyFactorVariableLoad,
							weightDeckingFinish: response.weightDeckingFinish,
							aSecondary: response.aSecondary,
							aMainBeam: response.aMainBeam,
							centerTocenter: response.centerTocenter,
							mSteel: response.mSteel,
							heightColumn: response.heightColumn,
							mainBeamHeight: response.mainBeamHeight,
							moduleOfElasiticity: response.moduleOfElasiticity,
							materialFactorHF: response.materialFactorHF,
							materialFactorHf1: response.materialFactorHf1,
							mainBeamDirection: response.mainBeamDirection,
							columnsToCalculate: response.columnsToCalculate,
						};
					} else {
						this.debugInfo = {
							variableLoad: '',
							safetyFactorPermanentLoad: '',
							safetyFactorVariableLoad: '',
							deflection: '',
							weightPm2: '',
							columnsToCalculate: {},
							heightColumn: '',
							permanentLoad: '',
							materialFactorHf1: '',
							materialFactorCf: '',
							materialFactorHf: '',
							profilesDouble: '',
						};
					}

					Store.CURRENT.columnProtectorsModels.firstList(this.column.oid, false);
				}
				let pressureChange = false;
				response.columns.forEach((columnsResponse, indexResponse) => {
					this.columns.forEach((columns, index) => {
						if (columns.name === columnsResponse.name) {
							if (columns.pressure !== columnsResponse.pressure) {
								pressureChange = true;
							}
							columns.pressureType = columnsResponse.pressureType;
							columns.pressure = columnsResponse.pressure;
							columns.pressureWithoutSafetyFactor = columnsResponse.pressureWithoutSafetyFactor;
						}
					});
				});

				if (pressureChange === true) {
					// eigenlijk niet de juiste oplossing. Kijken naar iets generieks
					Configuration.CURRENT.redraw();
				}
				Configuration.CURRENT.endCalculationRequest(requestID);
			},
			(error) => {
				console.log(error);
				Configuration.CURRENT.endCalculationRequest(requestID);
			},
		);
		this.amount = {};
		let withoutLength = 0;
		// bereken lengte kolommen
		this.columns
			.filter((c) => c.columnActive === true)
			.forEach((column) => {
				let height = column.height;
				if (height !== 0) {
					if (height === -1) {
						// als er ook extra geplaatste kolommmen zijn die hebben (nog) geen active raster dus apart optellen
						withoutLength++;
						return;
					}
					// 0 geeft aan geen verdiepingen actief
					if (typeof this.amount[height] === 'undefined') {
						this.amount[height] = 1;
					} else {
						this.amount[height]++;
					}
				}
			});

		if (withoutLength > 0) {
			// als er ook extra geplaatste kolommmen zijn die hebben (nog) geen active raster dus optellen bij de langste

			let heighest = 0;
			Object.keys(this.amount).forEach((key) => {
				if (key > heighest) {
					heighest = key;
				}
			});
			this.amount[heighest] += withoutLength;
		}
		// bereken kolombeschermers
		this.amountColumnProtectors = [];
		this.columns.forEach((column, index) => {
			column.columnProtectors.get().forEach((columnProtector, index) => {
				let gevonden = false;
				this.amountColumnProtectors.forEach((amount, index) => {
					if (amount.type === columnProtector.position) {
						amount.amount++;
						gevonden = true;
					}
				});
				if (gevonden === false) {
					this.amountColumnProtectors.push({ position: columnProtector.position, amount: 1, isCorner: columnProtector.isCorner });
				}
			});
		});
	}
	getTotalWeight() {
		if (this.column !== null) {
			return this.length * this.columns.length * (this.column.KilogramPerMeter / 1000);
		}
	}
	onRasterChanged(params) {}
	stoppedEditingObject() {
		this.columns.forEach((column, index) => {
			column.columnProtectors.calculateColumnProtectors(column);
		});
	}
	calculateAmount(params) {
		this.calculate(params.raster, params.etages, params.profiles.mainBeamDirection);
	}
	addDrawObjects() {
		this.columns.forEach((column, index) => {
			const drawGroup = new ObjectGroup(column.selected === true ? Columns.COLOR.selected : Columns.COLOR.column, null, null, true, column, null);
			const columnDraw = column.addDrawObjects();

			if (typeof columnDraw !== 'undefined') {
				drawGroup.push(columnDraw);
			}

			if (
				this.showColumnPressure === true &&
				Configuration.CURRENT.etages.activeEtageIndex === 0 &&
				Configuration.CURRENT.editModus.editingObjectName !== 'columnProtectors' &&
				column.type === Columns.GENERATED &&
				column.columnActive
			) {
				const columnPressureDraw = column.addPressure();
				if (typeof columnPressureDraw !== 'undefined') {
					drawGroup.push(columnPressureDraw);
				}
			}

			if (Configuration.CURRENT.editModus.editingObjectName === 'columnProtectors') {
				if (typeof column.columnProtectors !== 'undefined' && typeof column.columnProtectors.addPossiblePositions === 'function') {
					column.columnProtectors.addPossiblePositions(column);
				}
			}

			Canvas.CURRENT.addDrawObject(drawGroup);
		});
	}
	addDrawObjects3d() {
		this.columns.forEach((column, index) => {
			column.addDrawObjects3d(this.column, column.height);
		});
	}
	create3DAssets(canvas3d) {
		this.columns.forEach((column, index) => {
			column.create3DAssets(canvas3d, this.column);
		});
	}

	/**
	 * Update de columns met een nieuw column/gat
	 *
	 * @param {BuildingColumn|Hole} object
	 */
	static updateColumns(object) {
		if (!(object instanceof BuildingColumn) && !(object instanceof Hole)) {
			throw new Error('Object is geen geldige column voor deze functie');
		}

		let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(object.x, object.y);
		let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(object.x, object.y + object.depth);
		let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(object.x + object.width, object.y);
		let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(object.x + object.width, object.y + object.depth);

		let columnSize = Columns.COLUMN_SIZE / Configuration.CURRENT.canvas.scaleFactor;

		// Deze allemaal later weghalen, voor oude configuraties die de kolommen nog niet met configurationObjectId werken.
		Configuration.CURRENT.columns.removeByName(object.id);
		Configuration.CURRENT.columns.removeByName(object.id + '_' + Columns.POSITION_TOP);
		Configuration.CURRENT.columns.removeByName(object.id + '_' + Columns.POSITION_BOTTOM);
		Configuration.CURRENT.columns.removeByName(object.id + '_' + Columns.POSITION_LEFT);
		Configuration.CURRENT.columns.removeByName(object.id + '_' + Columns.POSITION_RIGHT);

		object.columnHeights = [];

		const columnsToRemove = Configuration.CURRENT.columns.columns.filter((column) => column.configurationObjectId === object.id);
		columnsToRemove.forEach((column) => {
			Configuration.CURRENT.columns.removeByName(column.name);
		});

		// Voor nieuw aangemaakte kolommen.
		let columns = [];

		let packetHeight = 0;
		let totalEtagesHeight = 0;
		let totalHeight = 0;

		Configuration.CURRENT.etages.etages.forEach((etage, index) => {
			totalEtagesHeight += etage.getHeight(true);

			// Kijken per etage of startHoogte + eigen hoogte groter is dan tot aan de huidig etage in de loop.
			if (object.startHeight + object.height < totalEtagesHeight && object.columnType === Columns.BUILDING_COLUMN) {
				return;
			}

			packetHeight = etage.getPacketHeight();
			totalHeight += etage.getHeight(true);

			// TOP COLUMM, aan de bovenkant van de gebouwkolom.
			if (topLeftRaster.x !== topRightRaster.x) {
				// Kan buiten vloer liggen, daarom anders op laatste rasterX zetten.
				const checkTopRightRasterX = topRightRaster.x === -1 ? Configuration.CURRENT.raster.spansX.length - 1 : topRightRaster.x;
				for (let i = topLeftRaster.x; i <= checkTopRightRasterX; i++) {
					const endPointRasterX = Configuration.CURRENT.raster.getSizeX(i);
					const currentRasterActive = etage.isActiveRaster(new RemoveRaster(i, topLeftRaster.y));
					const nextRasterActive = etage.isActiveRaster(new RemoveRaster(i + 1, topRightRaster.y));

					if (
						// Als eindpunt te checken raster na X gebouwkolom ligt.
						// Maar ook of dat voor het einde van het eindpunt ligt.
						endPointRasterX > object.x &&
						endPointRasterX < object.x + object.width &&
						// Als één van de twee rasters niet actief is, extra kolom nodig.
						(currentRasterActive !== nextRasterActive ||
							// Of als linkerboven en rechterboven raster allebei actief zijn maar hoofbalken liggen verticaal. Dan is er doorkruising van de hoofdbalk.
							(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL))
					) {
						columns.push({
							x: endPointRasterX,
							y: object.y - columnSize / 2,
							name: object.id + '_' + Columns.POSITION_TOP + '_' + i,
						});
					}
				}
			}

			// BOTTOM COLUMM, aan de onderkant van de gebouwkolom.
			if (bottomLeftRaster.x !== bottomRightRaster.x) {
				const checkBottomRightRasterX = bottomRightRaster.x === -1 ? Configuration.CURRENT.raster.spansX.length - 1 : bottomRightRaster.x;
				for (let i = bottomLeftRaster.x; i <= checkBottomRightRasterX; i++) {
					const endPointRasterX = Configuration.CURRENT.raster.getSizeX(i);
					const currentRasterActive = etage.isActiveRaster(new RemoveRaster(i, bottomLeftRaster.y));
					const nextRasterActive = etage.isActiveRaster(new RemoveRaster(i + 1, bottomRightRaster.y));

					if (
						// Als eindpunt te checken raster na X gebouwkolom ligt.
						// Maar ook of dat voor het einde van het eindpunt ligt.
						endPointRasterX > object.x &&
						endPointRasterX < object.x + object.width &&
						// Als één van de twee rasters niet actief is, extra kolom nodig.
						(currentRasterActive !== nextRasterActive ||
							// Of als linksonder en rechtsonder raster allebei actief zijn maar hoofbalken liggen verticaal. Dan is er doorkruising van de hoofdbalk.
							(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL))
					) {
						columns.push({
							x: endPointRasterX,
							y: object.y + object.depth + columnSize / 2,
							name: object.id + '_' + Columns.POSITION_BOTTOM + '_' + i,
						});
					}
				}
			}

			// LINKER COLUMN, Aan de linkerkant van de gebouwkolom.
			if (topLeftRaster.y !== bottomLeftRaster.y) {
				// Kan buiten vloer liggen, daarom anders op laatste rasterY zetten.
				const checkBottomLeftRasterY = bottomLeftRaster.y === -1 ? Configuration.CURRENT.raster.spansY.length - 1 : bottomLeftRaster.y;
				for (let i = topLeftRaster.y; i <= checkBottomLeftRasterY; i++) {
					const endPointRasterY = Configuration.CURRENT.raster.getSizeY(i);
					const currentRasterActive = etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, i));
					const nextRasterActive = etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, i + 1));

					if (
						// Als eindpunt te checken raster na Y gebouwkolom ligt.
						// Maar ook of dat voor het einde van het eindpunt ligt.
						endPointRasterY > object.y &&
						endPointRasterY < object.y + object.depth &&
						// Als één van de twee rasters niet actief is, extra kolom nodig.
						(currentRasterActive !== nextRasterActive ||
							// Of als beide rasters actief maar hoofdbalken liggen horizontaal. Dan is er doorkruising van de hoofdbalk.
							(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL))
					) {
						columns.push({
							x: object.x - columnSize / 2,
							y: endPointRasterY,
							name: object.id + '_' + Columns.POSITION_LEFT + '_' + i,
						});
					}
				}
			}

			// RECHTER COLUMN, Aan de rechterkant van de gebouwkolom.
			if (topRightRaster.y !== bottomRightRaster.y) {
				const checkBottomRightRasterY = bottomRightRaster.y === -1 ? Configuration.CURRENT.raster.spansY.length - 1 : bottomRightRaster.y;
				for (let i = topRightRaster.y; i <= checkBottomRightRasterY; i++) {
					const endPointRasterY = Configuration.CURRENT.raster.getSizeY(i);
					const currentRasterActive = etage.isActiveRaster(new RemoveRaster(topRightRaster.x, i));
					const nextRasterActive = etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, i + 1));

					if (
						// Als eindpunt te checken raster na Y gebouwkolom ligt.
						// Maar ook of dat voor het einde van het eindpunt ligt.
						endPointRasterY > object.y &&
						endPointRasterY < object.y + object.depth &&
						// Als één van de twee rasters niet actief is, extra kolom nodig.
						(currentRasterActive !== nextRasterActive ||
							// Of als beide rasters actief maar de hoofdbalken liggen horizontaal. Dan is er doorkruising van de hoofdbalk.
							(currentRasterActive && nextRasterActive && Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL))
					) {
						columns.push({
							x: object.x + object.width + columnSize / 2,
							y: endPointRasterY,
							name: object.id + '_' + Columns.POSITION_RIGHT + '_' + i,
						});
					}
				}
			}
		});

		// Check is nodig om te kijken of de berekende hoogte wel boven een etage komt, anders hoeven we ook geen kolommen te pushen
		if (totalHeight - packetHeight > 0) {
			object.columnsHeight = totalHeight - packetHeight;
			columns.forEach((column) => {
				Configuration.CURRENT.columns.push(column.x, column.y, column.name, object.columnType, { x: column.x, y: column.y }, object.id);
			});
		}
	}
}
