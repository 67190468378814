<template>
	<div class="background" :translationsLoaded="translations.translationsLoaded">
		<nuxt />
	</div>
</template>

<script>
import { Translations } from '../data/translations';

export default {
	transition: {},
	data: () => {
		return {
			translations: Translations.CURRENT,
		};
	},
	methods: {
		isActive(path) {
			return this.$route.path === path;
		},
	},
};
</script>
<style lang="less">
.error {
	color: red;
}
.background {
	position: absolute;
	z-index: 100;
	width: 100%;
	height: 100vh;
	background-image: url('../static/images/entresolvloer.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100vh;
		z-index: 101;
		background-color: rgba(39, 39, 39, 0.4);
	}
}
.content {
	.field {
		margin: 10px 0;
		input {
			display: block;
			width: 100%;
			border: 1px solid #bdc3c7;
			color: #34495e;
			font-family: Lato, Helvetica, Arial, sans-serif;
			font-size: 15px;
			line-height: 1.467;
			padding: 8px 12px;
			height: 42px;
			box-shadow: none;
			background-color: #fff;
			background-image: none;
		}
	}
}
</style>
